import axios from "axios";
const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

const instance2 = axios.create({
  baseURL:REACT_APP_API_HOST,
  withCredentials:true,
  headers: {
    "Content-Type": "application/json",
  },
});




export default instance2;

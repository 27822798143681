import React from 'react';
import ReactECharts from 'echarts-for-react'; 
import moment from 'moment';

const HalfDoughnetChart = ({ data = {}, startDate, endDate, style = { height: 400, width: '100%' } }) => {
  
  const trialAgencies = data.trialAgenciesCount?.Users || [];
  
  const filteredData = trialAgencies.filter((user) => {
    const createdDate = moment(user.created_on);
    return createdDate.isSameOrAfter(startDate) && createdDate.isSameOrBefore(endDate);
  });

  const totalUsers = filteredData.length;

  const chartData = [
    { value: totalUsers, name: 'Total Users' },
    { value: 100 - totalUsers, name: 'Remaining' }, 
  ];

  const chartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)',
    },
    color: ['#2563EB', '#e2e8f0'], 
    series: [
      {
        name: 'User Count',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        startAngle: 180,
        endAngle: 360,
        data: chartData,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'bold',
          },
        },
      },
    ],
  };

  return (
    <ReactECharts 
      option={chartOptions} 
      style={style} 
      notMerge={true} 
      lazyUpdate={true} 
    />
  );
};

export default HalfDoughnetChart;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: null,
  filterHeader: false,
  loadingSearch:false,
  filterBar: false,
  alert: {
    type: "",
    message: null,
  },
  editState: 0
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setAlert(state, action) {
      state.alert = {
        ...action.payload
      };
    },
    setActiveFilterHeader(state, action) {
      state.filterHeader = action.payload !== undefined ? action.payload : !state.filterHeader;
    },
    setDeactivateFilterHeader(state,action){
      state.filterHeader = false
      state.filterBar = false
    },
    setOpenFilter(state, action) {
      state.filterBar = action.payload !== undefined ? action.payload : !state.filterBar;
    },
    setEditState(state, action) {
      state.editState = action.payload;
    },
    loadingSearch(state, action) {
      state.loadingSearch = !state.loadingSearch
    },
  },
});

export const { setLoading, setAlert, setActiveFilterHeader, setOpenFilter, setEditState,loadingSearch ,setDeactivateFilterHeader} = uiSlice.actions;

export default uiSlice.reducer;

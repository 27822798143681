import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Grid,Divider } from "@mui/material";
import { CloseIcon } from '../../assets/svg';

const Popup = ({ open, handleClose, title, actions, children }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth  style={{borderRadius:"10px"}}>
      <DialogTitle style={{ padding: 0 }}>
        <Grid container >
        <Grid item xs={12} className="header" style={{display:"flex",alignItems:'center',justifyContent:"space-between",padding: "15px"}}>
              <Typography variant="body1" className="fw-700 uppercase">{title}</Typography>
              <IconButton className="closeBtn" onClick={handleClose}><CloseIcon /></IconButton>
             
          </Grid>
        </Grid>
      <Divider style={{ margin: 0 }} />
      </DialogTitle>
      <DialogContent style={{ paddingTop: 15 }} >
        {children}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;

import React, { useState } from "react";
import { TextField, MenuItem, Grid, FormControl, Select, Box } from "@mui/material";

const DateFilterWithConditions = ({ label, onChange, options }) => {
    const [condition, setCondition] = useState(options[0].value);  
    const [value, setValue] = useState(''); 
    const [isvalue, setIsvalue] = useState('');         
    const [unit, setUnit] = useState('days');   
    const [isLast, setIsLast] = useState(true);    
    const [isBetween, setIsBetween] = useState(false);

    const handleConditionChange = (event) => {
        const selectedCondition = event.target.value;
        setCondition(selectedCondition);
        setIsLast(selectedCondition === 'last');
        setIsBetween(selectedCondition === 'between');
        
        notifyParent(selectedCondition, value, isvalue, unit);
    };

    const handleValueChange = (event) => {
        const inputValue = event.target.value;
        setValue(inputValue);
        notifyParent(condition, inputValue, isvalue, unit);
    };

    const handleIsvalueChange = (event) => {
        const inputValue = event.target.value;
        setIsvalue(inputValue);
        notifyParent(condition, value, inputValue, unit);
    };

    const handleUnitChange = (event) => {
        const selectedUnit = event.target.value;
        setUnit(selectedUnit);
        notifyParent(condition, value, isvalue, selectedUnit);
    };

    // Update notifyParent to also send isvalue
    const notifyParent = (selectedCondition, inputValue, betweenValue, selectedUnit) => {
        onChange({ 
            condition: selectedCondition, 
            value: inputValue, 
            isvalue: betweenValue,  // Include isvalue here
            unit: selectedUnit 
        });
    };

    return (
        <Box 
            sx={{ 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                padding: '16px', 
                fontSize: '12px', 
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                fontFamily: 'Arial, sans-serif' 
            }}
        >

            <FormControl fullWidth>
                <Select value={condition} onChange={handleConditionChange} size="small" sx={{ borderRadius: '4px' }}>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Grid container spacing={1}> 
                <Grid item xs={6}>
                    <TextField
                        label={isLast ? "Enter Value" : ""}
                        type={isLast ? "number" : "date"}
                        value={value}
                        onChange={handleValueChange}
                        fullWidth
                        size="small"
                        sx={{ borderRadius: '4px' }} 
                    />
                </Grid>

                {isBetween && (
                    <Grid item xs={6}>
                        <TextField
                            type="date"
                            value={isvalue}
                            onChange={handleIsvalueChange}  // Call handleIsvalueChange here
                            fullWidth
                            size="small"
                            sx={{ borderRadius: '4px' }} 
                        />
                    </Grid>
                )}

                {isLast && (
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select 
                                value={unit} 
                                onChange={handleUnitChange} 
                                size="small"  
                                sx={{ borderRadius: '4px' }} 
                            >
                                <MenuItem value="days">Days</MenuItem>
                                <MenuItem value="months">Months</MenuItem>
                                <MenuItem value="years">Years</MenuItem>
                                <MenuItem value="hours">Hours</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default DateFilterWithConditions;

import {createAuthUserGroup as createAuthuserGroupService , createAuthUserGroupDetail as createAuthUserGroupDetailService,getAllUserGroup as getAllUserGroupService , getAllUserGroupById as getAllUserGroupByIdService ,updateAuthUserGroupDetails as updateAuthUserGroupDetailsService , updateAuthUserGroupUser as updateAuthUserGroupUserService, updateAuthuserGroupService} from "../services/authUserGroup"
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
export const createAuthUserGroup = createAsyncThunk(
    "authUserGroup/create",
    async ({ data, callback }, { dispatch, rejectWithValue }) => {
      try {
        console.log("daat in action",data)
        dispatch(setLoading("Creating User Group"));
        const response = await createAuthuserGroupService(data);
        callback(response?.data?.data)
        dispatch(setLoading(null));
        // navigate(`/userManagement/${response?.data?.response?.id}/edit`)
        if (callback && typeof callback === "function") {
          callback();  
        }
  
        return response;  
      } catch (err) {
        dispatch(setLoading(null));
          dispatch(setLoading(null));
          parseErrorResponses(err?.response || err, dispatch);
          return rejectWithValue(err);  // Handle rejection
      }
    }
  ); 

  export const createAuthUserGroupDetail = createAsyncThunk(
    "authUserGroup/userCreate",
    async ({ data, callback }, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoading("Creating User Group"));
        const response = await createAuthUserGroupDetailService(data);
        callback(response?.data?.data)
        dispatch(setLoading(null));
        // navigate(`/userManagement/${response?.data?.response?.id}/edit`)
        if (callback && typeof callback === "function") {
          callback();  
        }
  
        return response;  
      } catch (err) {
        dispatch(setLoading(null));
  
        // const message =
        //   (err.response && err.response.data && err.response.data.message) ||
        //   err.message ||
        //   err.toString();
          dispatch(setLoading(null));
          parseErrorResponses(err?.response || err, dispatch);
          return rejectWithValue(err);  // Handle rejection
      }
    }
  );

  export const getAllUserGroup = createAsyncThunk(
    "authUserGroup/getAllUser",
    async ({ callback, ...data }, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoading("getting User Group"));
        const response = await getAllUserGroupService(data);
        // callback(response?.data?.data)
        dispatch(setLoading(null));
        // navigate(`/userManagement/${response?.data?.response?.id}/edit`)
        if (callback && typeof callback === "function") {
          callback();  
        }
  
        return response?.data?.data;  
      } catch (err) {
        dispatch(setLoading(null));
  
        // const message =
        //   (err.response && err.response.data && err.response.data.message) ||
        //   err.message ||
        //   err.toString();
          dispatch(setLoading(null));
          parseErrorResponses(err?.response || err, dispatch);
          return rejectWithValue(err);  // Handle rejection
      }
    }
  );
  
  export const getAllUserGroupById = createAsyncThunk(
    "authUserGroup/getAllUserById",
    async ({ data, callback }, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoading("getting User Group"));
        const response = await getAllUserGroupByIdService(data?.id);
        // callback(response?.data?.data)
        dispatch(setLoading(null));
        // navigate(`/userManagement/${response?.data?.response?.id}/edit`)
        if (callback && typeof callback === "function") {
          callback();  
        }
  
        return response?.data?.data;  
      } catch (err) {
        dispatch(setLoading(null));
  
        // const message =
        //   (err.response && err.response.data && err.response.data.message) ||
        //   err.message ||
        //   err.toString();
          dispatch(setLoading(null));
          parseErrorResponses(err?.response || err, dispatch);
          return rejectWithValue(err);  // Handle rejection
      }
    }
  );
  export const updateAuthUserGroupDetails = createAsyncThunk(
    "authUserGroup/updateAuthUserGroupDetails",
    async ({ data, callback }, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoading("getting User Group"));
        const response = await updateAuthUserGroupDetailsService(data);
        // callback(response?.data?.data)
        dispatch(setLoading(null));
        // navigate(`/userManagement/${response?.data?.response?.id}/edit`)
        if (callback && typeof callback === "function") {
          callback();  
        }
  
        return response?.data?.data;  
      } catch (err) {
        dispatch(setLoading(null));
  
        // const message =
        //   (err.response && err.response.data && err.response.data.message) ||
        //   err.message ||
        //   err.toString();
          dispatch(setLoading(null));
          parseErrorResponses(err?.response || err, dispatch);
          return rejectWithValue(err);  // Handle rejection
      }
    }
  );

  export const updateAuthUserGroupUser = createAsyncThunk(
    "authUserGroup/updateAuthUserGroupUserService",
    async ({ data, callback }, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoading("getting User Group"));
        const response = await updateAuthUserGroupUserService(data);
        // callback(response?.data?.data)
        dispatch(setLoading(null));
        // navigate(`/userManagement/${response?.data?.response?.id}/edit`)
        if (callback && typeof callback === "function") {
          callback();  
        }
  
        return response?.data?.data;  
      } catch (err) {
        dispatch(setLoading(null));
  
        // const message =
        //   (err.response && err.response.data && err.response.data.message) ||
        //   err.message ||
        //   err.toString();
          dispatch(setLoading(null));
          parseErrorResponses(err?.response || err, dispatch);
          return rejectWithValue(err);  // Handle rejection
      }
    }
  );

export const updateAuthuserGroup = createAsyncThunk(
  "authUserGroup/update",
  async({data,callback},{dispatch,rejectWithValue})=> {
    try {
      dispatch(setLoading("Updating user group"));
      const response = await updateAuthuserGroupService(data);
      callback(response?.data?.data)
      dispatch(setLoading(null))
      return response
    }
    catch (err){
        dispatch(setLoading(null))
        parseErrorResponses(err?.response || err,dispatch)
        return rejectWithValue(err)
    }
  }
)

import instance1 from "../../utils/api1"

async function cancelSubscriptions(data){
    return instance1.post(`subscription/cancel`,data)
}
async function refundSubscription(data) {
    return instance1.post(`subscription/refund`, data);
}
export const subscriptionService = {
    cancelSubscriptions,
    refundSubscription
}
import React, { createContext, useContext, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useDispatch} from "react-redux";
import { setLogoutFunction } from '../helper/logouthelper';  // Helper for setting the logout function
import {logout as logoutDispatch} from "../store/reducers/authReducer"
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", true); // Local storage to persist user data
    const navigate = useNavigate();
    const dispatch = useDispatch();
    

    const login = useCallback(async (data) => {
        // Assuming you handle login and receive access token
        setUser(data); // Store user data in local storage
        navigate("/home");  // Redirect to home after login
    }, [navigate, setUser]);

    const logout = useCallback(async () => {
        dispatch(logoutDispatch()); // Call Redux logout action
        navigate("/auth/login"); // Navigate to login page after logging out
    }, [dispatch, navigate]);

    useMemo(() => {
        setLogoutFunction(logout);  // Store the logout function in the helper for access in Axios interceptors
    }, [logout]);

    // Memoized value for AuthContext
    const value = useMemo(() => ({
        user,
        login,
        logout,
        setUser,
    }), [user, login, logout, setUser]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);  // Hook to access AuthContext in any component
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import { getLicense, deleteLicenseById, createLicence, updateLicenceById, getLicenseById } from "../services/licenseService"


export const getLicenseAsync = createAsyncThunk("license/get", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching License..."))
        const response = await getLicense(data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})
export const deleteLicenseByIdAsync = createAsyncThunk("license/delete", async (param, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Deleting License..."))
        const response = await deleteLicenseById(param);
        dispatch(setLoading(null))
        dispatch(getLicenseAsync())
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})



export const createlicenseAsync = createAsyncThunk("license/create", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("License creating..."))
        const response = await createLicence(data);
        dispatch(setLoading(null))
        
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})



export const updateLicenseAsync = createAsyncThunk("license/updateById", async ({ id, data }, { dispatch, rejectWithValue }) => {

    try {
        dispatch(setLoading("Updating License..."));
        const response = await updateLicenceById(id, data);
        dispatch(setLoading(null));
        dispatch(getLicenseByIdAsync(id));
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
});


export const getLicenseByIdAsync = createAsyncThunk("license/getById", async (param, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching License..."));
        const response = await getLicenseById(param);

        dispatch(setLoading(null));
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
});




import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { List, ListItem, ListItemText, Checkbox, Popover, Box, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
// Debounce hook to limit API calls
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

export default function MultiSelectInput({
  callback,
  inputOptions,
  onChange,
  defaultValue ,
  disabled = false
}) {
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); 
  const debouncedInput = useDebounce(inputValue, 500);
  const {id} = useParams()
  // Update selectedOptions whenever defaultValue changes
  useEffect(() => {
    if(defaultValue){
      setSelectedOptions(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (debouncedInput && callback) {
      setLoading(true);
      callback(debouncedInput)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [debouncedInput, callback]);

  const handleToggle = (user) => {
    const isSelected = selectedOptions.some((option) => option.id === user.id);

    const updatedSelection = isSelected
      ? selectedOptions.filter((option) => option.id !== user.id) // Remove user
      : [...selectedOptions, user]; // Add user

    setSelectedOptions(updatedSelection);
    onChange(updatedSelection);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setAnchorEl(event.target.value ? event.currentTarget : null); 
  };

  const handleClose = () => setAnchorEl(null); 

  const open = Boolean(anchorEl);

  return (
    <div>
      <TextField
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
        onClick={(e) => setAnchorEl(e.currentTarget)} 
      />
      {loading && <CircularProgress size={20} />}
      
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ maxHeight: 300, overflowY: 'auto', width: 300 }}>
          <List>
            { inputOptions?.res?.length > 0 && inputOptions?.res?.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
              ?.map((user) => (
                <ListItem key={user.id} >
                  <Checkbox
                    edge="start"
                    checked={selectedOptions.find(selected => selected.id === user.id)}
                    tabIndex={-1}
                    disabled={user?.group_id !== null &&user?.group_id !== parseInt(id)}
                    onChange={() => handleToggle(user)}
                  />
                  <Tooltip title={user?.group_id ? "Already a member" : ""}>
                  <ListItemText primary={user.name}  />
                  </Tooltip>
                </ListItem>
              ))}
          </List>
        </Box>
      </Popover>
    </div>
  );
}

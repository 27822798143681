import React, { useEffect, useState, useRef } from 'react';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import { Button, Typography, Grid, styled } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getAddonsAsync } from "../../store/actions/agencyAddonsAction";
import { getAllAgencyDetailsAsync } from "../../store/actions/agenciesAction";
import { createAgencyAddonsAsync } from "../../store/actions/agencyAddonsAction";
import { setPageSize, setPage, setSearch } from "../../store/reducers/agencyAddonsReducer";
import { ActionCell } from "../../components/MoreAction/MoreAction";
import moment from 'moment';
import AsyncAutocomplete from "../../components/AutoCompleteText/index";
import UpdateAgencyAddons from "./components/updateAgencyAddons/updateAgencyAddons";
import DeleteAgencyAddons from './components/deleteAgency/deleteAgencyAddons';
import Style from './style';

const AgencyAddons = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.agencyAddons);
  const { list: agencyList } = useSelector((state) => state.agencies);
  const [featureSearch, setFeatureSearch] = useState("");
  const { pagination, search} = list;
  const [open, setOpen] = useState(false);
  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const agencyName = (item) => {
    return <span>{item.rowData.agency_name}</span>;
  };

  const StatusCell = (props) => {
    const { rowData } = props;
    const color = rowData?.is_enabled === false ? "#FFDEDE" : "#D4F6D2";
    const textColor = rowData?.is_enabled === false ? "#FF3B3B" : "#0E8E2A";
    const width = "100px"; // Set a specific width for consistency
    const renderText = rowData?.is_enabled === false ? "Disabled" : "Enabled";
    return (
      <span
        style={{
          display: "inline-block",
          width: width,
          textAlign: "center",
          color: textColor,
          backgroundColor: color,
          borderRadius: "6px",
          padding: "8px 10px",
        }}
      >
        {renderText}
      </span>
    );
  };

  const CreatedOnCell = ({ item }) => {
    const formatDate = (isoDate) => {
      return moment(isoDate).format('DD MMMM YYYY');
    };
    return <span>{formatDate(item)}</span>;
  };

  const cellMap = {
    agency_name: agencyName,
    is_enabled: StatusCell,
    created_on: CreatedOnCell,
    action: ActionCell,
    edit: UpdateAgencyAddons,
    delete: DeleteAgencyAddons,
  };

  const popUpHandler = () => {
    setOpen(!open);
  };

  // useRef to prevent multiple API calls on initial render

  const hasFetchedAddons = useRef(false);

  useEffect(() => {
   
 
    dispatch(getAllAgencyDetailsAsync());
 
  }, [dispatch]);
  const [agencySearch, setAgencySearch] = useState('');
  useEffect(() => {
    if (!hasFetchedAddons.current) {
      dispatch(getAddonsAsync({ ...pagination, search }));
      hasFetchedAddons.current = true;
    }
  }, [dispatch, pagination, search,agencySearch]);

  
  
  const agencyOnChange = (selectedOption) => {
    setAgencySearch(selectedOption.value); 
    dispatch(setSearch(selectedOption.value));
  };

  const featureOnChange = (selectedOption) => {
    setFeatureSearch(selectedOption.label);
  };

  const agencyOptions = agencyList?.agencyDetails?.agencyList?.map((data) => ({
    label: data.agency_name,
    value: data.id,
  }));

  const featureOptions = agencyList?.agencyDetails?.featureList?.map((data) => ({
    label: data.feature_name,
    value: data.id,
  }));

  const createAgencyAddons = () => {
    dispatch(createAgencyAddonsAsync({ flag_name: featureSearch, agency_id: Number(agencySearch) }));
  };

  return (
    <Grid className={className}>
      <Table
        title={"Feature Flags"}
        headers={list.headers}
        data={list?.results || []}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        cellMap={cellMap}
        popUp={"Add Agency Flag"}
        popUpHandler={popUpHandler}
        isSearch={true}
      />
      <Modal
        open={open}
        handleClose={popUpHandler}
        title="Agency Feature Mapping"
        style={{ width: "100%" }}
        actions={
          <>
            <Button onClick={popUpHandler} color="secondary">
              Cancel
            </Button>
            <Button onClick={createAgencyAddons} color="primary">
              Submit
            </Button>
          </>
        }
      >
        <Grid style={{ display: "flex", gap: "10px", width: "100%" }}>
          <div>
            <Typography variant="h5">Agency's Name</Typography>
            <AsyncAutocomplete inputOptions={agencyOptions} onChange={agencyOnChange} />
          </div>
          <div>
            <Typography variant="h5">Feature Name</Typography>
            <AsyncAutocomplete inputOptions={featureOptions} onChange={featureOnChange} />
          </div>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default styled(AgencyAddons)(Style);

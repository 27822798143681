import React, { lazy } from "react";
import ForgotPassword from "../containers/Authentication/ForgotPassword";
import Otp from "../containers/Authentication/Otp";
import ResetPassword from "../containers/Authentication/ResetPassword";
import { PublicRoute } from "../hoc/PublicRoute";
import PasswordSetting from "../containers/PasswordSetting";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import UserProfile from "../containers/UserProfile";

const PasswordSettingRoutes = [
  {
    path: "/setting",
    element: <ProtectedRoute/>,
    children: [
      {
        element: <PasswordSetting /> ,
        path: "password",
      },
      {
        element: <UserProfile /> ,
        path: "profile",
      },
      
    ],
  },
];

export default PasswordSettingRoutes;

import React, { useEffect, useState } from 'react'
import { Grid, Typography, styled, } from '@mui/material';
import moduleTableStyles from "./moduleStyle"
import SwitchComponent from '../../../../../components/Switch';
import { useSelector ,useDispatch} from 'react-redux';
import {setAuthUserPermissions,setRestSpecialPermissions} from "../../../../../store/reducers/authUserGroup"
// import { useParams } from 'react-router-dom';
const ModuleTable = (props) => {

    const { className } = props
    const [modules, setModules] = useState([])
    const [structureModule,setStructureModule] = useState(modules)
    const {mainModules} = useSelector((s)=>s.mainModule)
    const {specialPermissions} = useSelector((s)=>s.authUserGroup)
    const accessLevel = [ "All", "View", "Write", "Delete" ]
    const dispatch = useDispatch()
    const processData = (modules) => {
        const processedData = [];

        modules?.forEach((module) => {
            processedData.push({
                ...module,
                id: module?.id,
                main_module_id: module?.id,
                module_name: module.module_name,
                description: "Main Module",
                hasSub: module.subModules.length > 0,
                sub_module_id:null,
                // all: module?.all,
                // read: module.read,
                // write: module.write,
                // delete: module.delete,
                isLastValue: false 

            });

            if (module?.subModules && module?.subModules?.length > 0) {
                let isFirstSubmodule = true;

                module?.subModules?.forEach((subModule,index) => {
                    const isLastSubmodule = index === module.subModules.length - 1;
                    processedData?.push({
                        ...subModule,
                        id: subModule.id,
                        main_module_id:module?.id,
                        sub_module_id:subModule?.id,
                        module_name: subModule.module_name,
                        description: "Submodule",
                        hasSub: false,
                        nextToMain: isFirstSubmodule,
                        isLastValue: isLastSubmodule,
                        isChecked: false,
                        // all: subModule?.all,
                        // read: subModule.read,
                        // write: subModule.write,
                        // delete: subModule
                    });

                    isFirstSubmodule = false;
                });
            }
        });

        return processedData;
    };


    const handleToggle = (main_module_id, sub_module_id, field) => {
        const updatedModules = modules.map((module) => {
            if (module?.main_module_id === main_module_id && module?.sub_module_id === sub_module_id && module?.description === "Submodule" && (field === "read" || field === "write" || field === "delete")  && field !== "all") {
                if(module?.all){
                    return {
                        ...module,
                        all:false,
                        [field]: !module[field],
                    };
                }
                return {
                    ...module,
                    [field]: !module[field]
                };
            }else if(module?.main_module_id === main_module_id && module?.sub_module_id === sub_module_id && module?.description === "Submodule" && field === "all"){
                if(module?.all){
                    return {
                        ...module,
                        all:false,
                        read:false,
                        write:false,delete:false
                    };
                }
                return {
                    ...module,
                    all:true,
                    read:true,
                    write:true,delete:true
                };
            }
            return module;
        });

        setModules(updatedModules);
    };
    const handleMainModuleChange = (main_module_id, field) => {
        const updatedModules = modules.map((module) => {
            if (module?.main_module_id === main_module_id && module?.hasSub === true && (field === "read" || field === "write" || field === "delete")  && field !== "all") {
                // Toggle for both main module and all submodules
                if(module?.all){
                    return {
                        ...module,
                        all:false,
                        [field]: !module[field],
                    };
                }
                return { ...module, [field]: !module[field] };
            }else if(module?.main_module_id === main_module_id && module?.hasSub === true  && field === "all"){
                if(module?.all){
                    return {
                        ...module,
                        all:false,
                        read:false,
                        write:false,
                        delete:false
                    };
                }
                return {
                    ...module,
                    all:true,
                    read:true,
                    write:true,
                    delete:true
                };
            }
            return module;
        });

        setModules(updatedModules);
    };
    const createFinalOutput = (updatedModules) => {
       
        const finalOutput = [];
    
        updatedModules?.forEach((module) => {
           
            let mainModule = finalOutput.find(m => m.id === module.main_module_id);
            

            if (!mainModule) {
                mainModule = {
                    ...mainModule,
                    id: module.main_module_id,
                    module_name: module.module_name,  
                    created_by: 1,                   
                    created_at: new Date().toISOString(),  
                    is_deleted: 0,                    
                    subModules: [], 
                    read: module.read   ,    
                    write: module.write ,
                    delete: module.delete ,
                    all: module.all  ,            
                };
                finalOutput.push(mainModule);
            }
    
            if(module?.sub_module_id){
                const subModule = {
                    id: module.sub_module_id,            
                    module_name: module.module_name, 
                    main_module_id: module.main_module_id,
                    read: module.read ,          
                    write: module.write ,
                    delete: module.delete,
                    all: module.all,
                };
        
                mainModule.subModules.push(subModule);  
            }
            
        });

        return finalOutput;
    };
    useEffect(()=>{
        setStructureModule(()=>createFinalOutput(modules))

    },[modules])
    useEffect(()=>{
        dispatch(setAuthUserPermissions(structureModule))
    },[structureModule])

    useEffect(() => {
        if(specialPermissions?.length>0){
            setModules(() => processData(specialPermissions))
        }else{
            setModules(() => processData(mainModules))
        }
       
    }, [mainModules,specialPermissions])

useEffect(()=>{
    return ()=>{
        dispatch(setRestSpecialPermissions())
    }
   
},[])
    return (
        <Grid className={`${className}`}>
            <Grid className='main_con'>
                <Grid className='left_main'>
                    <Typography variant='h5'>Menu</Typography>
                    <Typography className='text'>Lorem ipsum dolor sit amet</Typography>
                </Grid>
                <Grid className='right_main'>
                    <Grid className='menu_name'>
                        <Typography variant='h5'>User Access</Typography>
                    </Grid>
                    <Grid className='access_level'>
                        {accessLevel?.map((data, id) => {
                            return (
                                <div key={id}>
                                    {data}
                                </div>
                            )
                        })}

                    </Grid>
                </Grid>
            </Grid>
            <div className='border_main'>
            </div>
            {
                modules?.map((data, id) => {
                    return (
                        <>
                            <Grid className={`access_types ${data?.description === 'Submodule' ? 'submodule' : ''}`} key={id}>
                                <Grid className={'access_name'}>
                                    <div style={{paddingLeft:`${!data?.hasSub ? "25px" :"5px"}`}}>{data?.module_name}</div>
                                </Grid>
                                <Grid className='access_level_con'>

                                <SwitchComponent
                                checked={data?.all}
                                onChange={() =>
                                    data.sub_module_id === null
                                        ? handleMainModuleChange(data?.main_module_id, 'all')
                                        : handleToggle(data?.main_module_id, data?.sub_module_id, 'all')
                                }
                            />
                            <SwitchComponent
                                checked={data?.read}
                                onChange={() =>
                                    data.sub_module_id === null
                                        ? handleMainModuleChange(data?.main_module_id, 'read')
                                        : handleToggle(data?.main_module_id, data?.sub_module_id, 'read')
                                }
                            />
                            <SwitchComponent
                                checked={data?.write}
                                onChange={() =>
                                    data.sub_module_id === null
                                        ? handleMainModuleChange(data?.main_module_id, 'write')
                                        : handleToggle(data?.main_module_id, data?.sub_module_id, 'write')
                                }
                            />
                            <SwitchComponent
                                checked={data?.delete}
                                onChange={() =>
                                    data.sub_module_id === null
                                        ? handleMainModuleChange(data?.main_module_id, 'delete')
                                        : handleToggle(data?.main_module_id, data?.sub_module_id, 'delete')
                                }
                            />
                                </Grid>
                            </Grid>
                            {
                                data?.isLastValue ? 
                                <div className='border_main'></div> : null
                            }
                           
                        </>
                    )
                })
            }
           
        </Grid>
    )
}

export default styled(ModuleTable)(moduleTableStyles)
const userTilesStyles = (theme) => ({
    display: "flex",
    gap: "10px",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px",
    padding: "8px",
    width:"250px",
    position:"relative",
    ".username": {
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "14px",
    },
    ".role": {
        color: "#778599",
        fontSize: "12px",
        fontWeight: "400",

    },
    ".user_image":{
        width:"40px",
        height:"40px",
        borderRadius:"50%",
        border:"1px solid black",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",fontSize:"18px"
    },
    ".user_details":{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    ".access_details":{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    ".close_icon":{
        position:"absolute",
        top:0,right:5
    }
})
export default userTilesStyles
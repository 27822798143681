import React from "react";
import PropTypes from 'prop-types';
import {Button,Grid,Select,Typography,styled,} from "@mui/material";
import Style from "./style";
import GroupCard from "./GroupCard";

const Users = (props) => {
  const { className } = props;

  //
  const cardData = [
    {
      title: 'Editor Group',
      description: 'These users will have the full access of this application, can add and delete user',
      userCount: 10,
      access: ["admin", "user"]
    }
  ]

  return (
    <Grid
      container
      className={className}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="mb-2">
        <Typography variant="h4">User Group</Typography>
        <Button variant="contained">Create New Role</Button>
      </Grid>
      <Grid container className="userGroupList">
        <Grid container justifyContent={"space-between"}>
          <Typography variant="h6" className="fw-600">Group List</Typography>
          <Select size="small" />
        </Grid>
        <Grid item xs={12}>
          <GroupCard
          data={cardData}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

// default props
Users.defaultProps = {
  classes: {}
};

// prop types
Users.propTypes = {
  classes: PropTypes.object
};


export default styled(Users)(Style);

const Style = (theme) => ({
    "headerStyle": {
        backgroundColor: '#D6E6FF',
        color: '#2563EB',
        borderRadius: '5px',
        height: '24px',
        width: 'fit-content',
        padding: '0 8px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
  "resultsTextStyle": {
    display: 'flex',
    alignItems: 'center',
    color: '#94A3B8',
    fontSize: '16px',
  },
  "searchTextStyle": {
    color: '#0F172A',
    fontWeight: 'bold',
  },
  "tableContainer": {
    marginBottom: '20px',
  },
  "viewAllButton": {
    width: '89px', 
    height: '28px !important', 
    padding: '0 8px !important', 
    fontSize: '14px !important', 
    display:"flex !important",
    gap:"4px"

  },

  "tablehead":{
    color:'#64748B !important'
  },
  "invoiceIcon": {
    marginRight: "2px", 
  },
  "crossArrowIcon": {
    marginLeft: '8px', 
  },
});

export default Style;

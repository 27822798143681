import React from 'react';
import ReactECharts from 'echarts-for-react'; 

const DoughnetChart = ({ data = {}, startDate, endDate, style = { height: 400, width: '100%' } }) => {
 
  const plansData = data?.trialAgenciesCount?.plans || [];

  const start = new Date(startDate);
  const end = new Date(endDate);

  const chartData = plansData.map(plan => {
    const agencyCount = plan.agencies?.filter(agency => {
      const createdOnDate = new Date(agency.created_on);
      return createdOnDate >= start && createdOnDate <= end;
    }).length;

    return {
      value: agencyCount || 0, 
      name: plan.planName
    };
  }).filter(plan => plan.value > 0); 

  const chartOptions = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      show: false, 
    },
    series: [
      {
        name: 'Agencies Count',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 15,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: chartData 
      }
    ]
  };

  return (
    <ReactECharts 
      option={chartOptions} 
      style={style} 
      notMerge={true} 
      lazyUpdate={true} 
    />
  );
};

export default DoughnetChart;

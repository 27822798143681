const Style = (theme) => ({
    height: '80px',
    padding: '10px 20px',
    background: theme.palette.containers.sidebar,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)',
    zIndex: 1,
    '.global-search': {
        height: '40px', 
        width: '1150px', 
        paddingLeft: '150px',
    },
});

export default Style;

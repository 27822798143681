
import React, { useEffect ,useState} from 'react';
import { Button, Grid, Typography, styled, } from '@mui/material';
import userFormStyles from './userManagementFormStyles';
import { getAllModules } from "../../../store/actions/mainModuleAction"
import { useDispatch } from 'react-redux';
import FormHeader from "./components/formHeader/formheader"
import { useForm, Controller } from 'react-hook-form';
import CustomInput from '../../../components/TextField';
import UserTiles from "./components/userTiles/userTiles";
import ModuleTable from "./components/moduleTable/moduleTable"
import {createAuthUserGroup , getAllUserGroupById, updateAuthuserGroup} from "../../../store/actions/authUserGroupActions"
import {getAllAuthUsers} from "../../../store/actions/authAction"
import {useNavigate } from 'react-router-dom';
import MultiSelectInput from "./components/searchUserList"
import { useParams } from 'react-router-dom';
import {useSelector} from "react-redux"
import {updateAuthUserGroupDetails,updateAuthUserGroupUser} from "../../../store/actions/authUserGroupActions"
const UserManagementForm = (props) => {
  const { className, } = props;
  // const { mainModules } = useSelector((s) => s.mainModule)
  const { userId } = useSelector((s) => s.auth)
  const { authUsers , authUserGroupDetails,authUserPermissions} = useSelector((s) => s.authUserGroup)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const params = useParams()
  const { id } = useParams();
  const isEditMode = !!id;
  const [removedUserIds, setRemovedUserIds] = useState([]);

  const {
    // register,
    handleSubmit: handleSubmitFirstFormField,
    setValue: setValueFirstFormField,

    control: controlFirstFormField
  } = useForm({
    defaultValues: {
      group_name: authUserGroupDetails[0]?.group_name || '',
      tags: authUserGroupDetails[0]?.tags?.role || '',
      description: authUserGroupDetails[0]?.description || '',
    }
  });
  
  // Second form's useForm
  // const { register: registerSecondFormField,
  //    handleSubmit: handleSubmitSecondFormField,
  //     setValue: setValueSecondFormField,
  //      formState: { errors: secondFormErrors ,control} } = useForm();


  const onSubmit1 = (data)=>{
    const tagsObject = {
      "role":data?.group_name,
      "permissions": [
        "read",
        "write",
        "delete"
      ],
      "features_enabled": true
    }
    console.log("data in action 1",data)
    dispatch(createAuthUserGroup({data:{...data,tags:tagsObject, created_by:userId},callback:(data)=>{
      if(data?.id){
        navigate(`/userManagement/${data?.id}/edit`)
      }
    }}))
  }
  const onSubmit2 = (data)=>{
    const tagsObject = {
      "role":data?.group_name,
      "permissions": [
        "read",
        "write",
        "delete"
      ],
      "features_enabled": true
    }
    dispatch(updateAuthuserGroup({data:{...data,tags:tagsObject, id:id},callback:()=>{
      dispatch(getAllUserGroupById({ data: { id: params?.id } }));
    }}))
  }
  const handleResetForm = () => {
    setValueFirstFormField("group_name", authUserGroupDetails[0]?.group_name || '');
    setValueFirstFormField("tags", authUserGroupDetails[0]?.tags?.role || '');
    setValueFirstFormField("description", authUserGroupDetails[0]?.description || '');
    setSelectedUsers(authUserGroupDetails[0]?.users || []);
  };
  

  const handleSubmitForm = isEditMode ? onSubmit2 : onSubmit1;
  useEffect(()=>{
   
    dispatch(getAllModules())
  },[])
  useEffect(() => {
    dispatch(getAllAuthUsers()); // Ensure this is being called
  }, []);
  const [selectedUsers, setSelectedUsers] = useState([]); // State to hold selected users
  const handleUserChange = (newSelectedUsers) => {
    setSelectedUsers(newSelectedUsers); // Update state with new selection
  };
const [userIds,setUserIds] = useState(selectedUsers)
  useEffect(()=>{
    setUserIds(()=>selectedUsers?.map((data)=>data?.id))
  },[selectedUsers])
  // const createAuthUserGroupUsers = ()=>{
  //   dispatch(createAuthUserGroupDetail({data:
  //     {user_group_id:parseInt(params?.id) ,users:{users:userIds},permissions:{},created_by:userId},
  //     callback:()=>dispatch(getAllUserGroupById({ data: { id: params?.id } }))
    
  //   })) 
  // }
  useEffect(() => {
    if(params?.id){
      dispatch(getAllUserGroupById({ data: { id: params?.id } }));
    }
 
  }, [params?.id]);
  
useEffect(()=>{
  if (authUserGroupDetails[0]) {
    setSelectedUsers(authUserGroupDetails[0]?.users);
    setValueFirstFormField("group_name", authUserGroupDetails[0].group_name);
    setValueFirstFormField("tags", authUserGroupDetails[0]?.tags?.role || '');
    setValueFirstFormField("description", authUserGroupDetails[0].description || '');
  }
},[authUserGroupDetails])


const createUserPermissions = ()=>{
  dispatch(updateAuthUserGroupDetails({data:{authUserPermissions:{"modules":authUserPermissions},user_group_id:parseInt(authUserGroupDetails[0]?.userGroupId?.[0]?.id)}}))
}

const updateUserGroupUser = ()=>{
  dispatch(updateAuthUserGroupUser({data:
    {id:parseInt(authUserGroupDetails[0]?.userGroupId[0]
      ?.id) ,users:{users:userIds},permissions:{},created_by:userId},
    callback:()=>dispatch(getAllUserGroupById({ data: { id: params?.id } }))
  
  }))
}
const deleteUser = (id) => {
  
  const filteredUsers = selectedUsers.filter((user) => user.id !== id);
  setSelectedUsers(filteredUsers);
  setRemovedUserIds((prev) => [...prev, id]);
  setUserIds(filteredUsers.map(user => user.id));
};
const dispatchRemovedUserIds = () => {
  dispatch(updateAuthUserGroupUser({data:
    {id:parseInt(authUserGroupDetails[0]?.userGroupId[0]
      ?.id) ,users:{users:userIds},permissions:{},created_by:userId},
    callback:()=>dispatch(getAllUserGroupById({ data: { id: params?.id } }))
  
  }))
};
  return (
    <Grid className={`${className}`}>
      <FormHeader />
      <Grid className='form_container'>
        <Grid className='container_1'>
          <form onSubmit={handleSubmitFirstFormField(handleSubmitForm)} className='container_1'>
          <Grid className='container_1_description'>
            <Typography variant='h5'>User Role Group Name</Typography>
            <Typography className='text'>Create a group of users to access the dashboard with which limits role of these users.</Typography>
          </Grid>
          <Grid className='container_1_form_main'>
            <Grid className='container_1_form'>
              <div className='form_div'>
                <Typography variant='h5'>Group Name</Typography>
                <Controller
                  name="group_name"
                  control={controlFirstFormField}
                  render={({ field }) => <CustomInput
                    {...field}
                  defaultValue={authUserGroupDetails[0]?.group_name}

                  />}
                />
              </div>
              <div className='form_div'>
                <Typography variant='h5'>Tags</Typography>
                <Controller
                  name="tags"
                  control={controlFirstFormField}
                  render={({ field }) => <CustomInput
                    {...field}

                  defaultValue={authUserGroupDetails[0]?.tags?.role} 
                  />}
                />
              </div>
            </Grid>
            <Grid className='container_1_form_sub'>
              <div className='form_div'>
                <Typography variant='h5' className='text'>Description</Typography>
                <Controller
                  name="description"
                  control={controlFirstFormField}
                  render={({ field }) => <CustomInput
                    {...field}
                    defaultValue={authUserGroupDetails[0]?.description} 
                  />}
                />
              </div>
              
            </Grid>
           
          </Grid>
          <div className='form_btn'>
                <Button variant='outlined' onClick={handleResetForm}>Reset</Button>
                <Button variant='contained' type='submit'>
                {isEditMode ? 'Update' : 'Save'}
                </Button>
              </div>
        </form>
        </Grid>
        <div className='border_bottom'></div>
        <Grid className='container_2'>
            {/* <form onSubmit={handleSubmitSecondFormField(onSubmit2)} className='container_2'> */}
          <Grid className='description_con_2'>
            <Typography variant='h5'>User Access</Typography>
            <Typography className='text'>Add or delete users to this group from the users added to this application.</Typography>
          </Grid>
          <Grid className='access_details'>

   
            <div className='username'>
            <Typography variant='h5'>User Access</Typography>
            <MultiSelectInput
            callback={getAllAuthUsers}  // Action to fetch data
            inputOptions={authUsers}  // Options from state
            onChange={handleUserChange} // Update selected users in state
            defaultValue={authUserGroupDetails[0]?.users} // Initial selected users
            disabled={false} 
             />
            </div>
            <Grid className='tiles_con' style={{height:`${selectedUsers?.length > 4 ? "130px" :" "}`}}>
                      {
                  selectedUsers?.map((user) => (
                      <UserTiles
                          key={user.id}
                          id={user.id} // Pass the user id
                          username={user?.name}
                          role={user?.department}
                          onDelete={deleteUser} // Pass deleteUser function to UserTiles
                      />
                  ))
              }
            </Grid>
          
          </Grid>
          <Grid className='butn_con_2'>
          <Button variant='outlined'>Reset</Button>
          {
            authUserGroupDetails[0] ? <Button variant='contained' onClick={()=>updateUserGroupUser()}>Update</Button> : 
            <Button variant='contained' disabled={true}>Save</Button>
          }
         
          </Grid>
          {/* </form> */}
        </Grid>
        <div className='border_bottom'></div>
        <Grid className='module_con'>
        <Grid className='description_module_con'>
            <Typography variant='h5'>User Access</Typography>
            <Typography className='text'>Add or delete users to this group from the users added to this application.</Typography>
        </Grid>
        <Grid className='module_table'>
        <ModuleTable/>   
        <Grid className='button_action'>
            <Button variant='outlined'>Reset</Button>
            <Button variant='contained' onClick={()=>createUserPermissions()}>Save</Button>
        </Grid>
        </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default styled(UserManagementForm)(userFormStyles);

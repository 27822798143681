import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import CombinedLayout from "../layouts/AgencyLayout";
import Collab from "../containers/Collab";



const collabAgencyRoutes = [
    {
        path:"/",
        element:<ProtectedRoute/>,
        children: [
            {
                path: '/collab',
                element: <CombinedLayout/>,
                children: [
                    {
                        path: '',
                        element: <Collab />
                    },
                    
                ]
            }
        ]
    }
]

export default  collabAgencyRoutes
import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import DetailedPage from "../containers/GlobalSearch/detailedPage";




const SearchRoutes = [
    {
        path:"/detailedpage",
        element:<ProtectedRoute/>,
        children:[{
            path:'',
            element:<DetailedPage/>
        },
        
    ]
    }
]

export default  SearchRoutes
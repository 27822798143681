import React from 'react'
import { styled, Grid, Typography } from '@mui/material'
import commentsStyles from "./commentsStyles"
// import FlozyLogo from "../../../../assets/svg/index"
import { formatDistanceToNow, parseISO } from 'date-fns';

const Comments = (props) => {
    const { className, commentsData } = props
    
    return (
        <Grid className={`${className} AddedByComp`}>
            <Typography variant='h5' style={{ fontWeight: "600" }}>Comments</Typography>
            {
                commentsData?.comments?.map((comment, id) => {
                    const formattedDate = formatDistanceToNow(parseISO(comment.createdAt), { addSuffix: true });
                    return (
                        <Grid className='added_by_comp_main' key={id}>
                            <Grid style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center", gap: "10px" }}>
                                <Grid className='image_container'>
                                    {/* <img src='' alt='image'/> */}
                                    FU
                                </Grid>
                                <Grid className='details_conainer'>
                                    <Grid className='name_container'>
                                        <Typography style={{ fontWeight: 500, color: "#000", fontSize: "16px" }}>{comment.commentedBy}</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px" }}>{comment.comment}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Typography style={{ fontWeight: 500, fontSize: "14px", width: "30%", color: "#9AA6B7" }}>{formattedDate}</Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default styled(Comments)(commentsStyles)

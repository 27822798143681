import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useTheme,
    styled,
} from '@mui/material';
import PropTypes from 'prop-types';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: '#ffffff !important',
    },
}));

const DialogComp = (props) => {
    const { openDialog, handleDialogClose, userInfo } = props;

    return (
        <StyledDialog
            open={openDialog}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Reset Password Confirmation"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to send a password recovery email to {userInfo.name}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDialogClose(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
                    Yes, Proceed
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

// Prop types
DialogComp.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    userInfo: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

export default DialogComp;

import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import UserManagementForm from "../containers/userManagement/UserManagementForm/userManagementForm";
import UserManagement from "../containers/userManagement";
import UserCreation from "../containers/userManagement/UserCreation";
import UserForm from "../containers/userManagement/UserCreation/newUserForm";


const userManagementRoutes = [
    {
        path:"/userManagement",
        element:<ProtectedRoute/>,
        children:[{
            path:'',
            element:()=><div>User Management</div>
        },
        {
            path: "userGroup",
            element: <UserManagement />
        },
        {
            path: "create",
            element: <UserManagementForm />
        },
        {
            path: ":id/edit",
            element: <UserManagementForm />
        },
        {
            path: "authusers",
            element: <UserCreation />
        },
        {
            path: "create/user",
            element: <UserForm />
        },
    ]
    }
]

export default  userManagementRoutes
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stepper, Step, StepLabel, Typography } from '@mui/material';

const SignUpTracker = ({ stepsData }) => {
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  
  const handleClose = () => {
    setOpen(false);
  };

 
  const steps = [
    { label: 'Step 1', completed: stepsData?.step_1 },
    { label: 'Step 2', completed: stepsData?.step_2 },
    { label: 'Step 3', completed: stepsData?.step_3 },
  ];

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen}>
        Tracker
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Sign-Up Process Tracker</DialogTitle>
        <DialogContent>
          <Stepper activeStep={stepsData?.step_3 ? 2 : stepsData?.step_2 ? 1 : 0} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={index} completed={step.completed}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignUpTracker;

import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import { EditPen } from "../../../assets/svg";

const Profile = (props) => {
  const { className } = props;

  //State
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const fileInputRef = useRef(null);
  const imagePreviewRef = useRef(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();

      reader.onload = () => {
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    // Trigger the hidden file input element
    fileInputRef.current.click();
  };

  return (
    <Grid
      container
      className={className}
    >
     <Grid container justifyContent={"space-between"} alignItems={"center"} className="mb-2">
      <Typography variant="h4">User Profile</Typography>
     </Grid>
     <Grid container className="profileContainer">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={3}>
                <input
                 type="file"
                 ref={fileInputRef}
                 style={{ display: 'none' }}
                 onChange={handleFileInputChange}
              />
                  <Grid className="profileImgContainer">
                  <IconButton className="uploadBtn" onClick={handleIconClick}><EditPen /></IconButton>
                  <Avatar ref={imagePreviewRef} src={previewUrl} alt="Uploaded" />
                  </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">First Name</Typography>
                    <TextField size="small" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Last Name</Typography>
                    <TextField size="small" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Email Id</Typography>
                    <TextField size="small" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Phone</Typography>
                    <TextField size="small" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Designation</Typography>
                    <TextField size="small" fullWidth />
                  </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 5, mb: 5, width:'100%' }} />

            <Grid item xs={12} md={3}>
                <Typography variant="body1" className="fw-600 pb-1">User Created by</Typography>
                <Typography variant="body2" className="fw-500 pb-1" color={"text.greyText2"}>The above user was added by the following person into the sales dashboard.</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Added By</Typography>
                    <TextField size="small"
                    fullWidth
                     InputProps={{
                      startAdornment: <InputAdornment position="start"><Avatar /></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><Typography variant="body2" color={"text.greyText4"} className="">Team Lead</Typography></InputAdornment>
                    }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Date</Typography>
                    <TextField size="small"
                    fullWidth
                    />
                  </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 5, mb: 5, width:'100%' }} />
            <Grid item xs={12} md={3}>
                <Typography variant="body1" className="fw-600 pb-1">User Role</Typography>
                <Typography variant="body2" className="fw-500 pb-1" color={"text.greyText2"}>Can edit and select the user role group for the above user, also custom the role access.</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Added By</Typography>
                    <Select size="small"
                    fullWidth
                    />
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
     </Grid>
    </Grid>
  );
};

// default props
Profile.defaultProps = {
  classes: {}
};

// prop types
Profile.propTypes = {
  classes: PropTypes.object
};


export default styled(Profile)(Style);

import  instance1 from "../../utils/api1";

async function getLicense(data) {
    return instance1.post('license/getAll', data)
}
async function deleteLicenseById(param) {
    return instance1.delete(`license/${param}`)
}
async function createLicence(data) {
    return instance1.post('license/create', data)
}
async function updateLicenceById(id, data) {
    return instance1.put(`license/${id}`, data);
}
async function getLicenseById(param) {
    if (param) return instance1.get(`license/${param}`)
}

export {
    getLicense,
    deleteLicenseById,
    createLicence,
    updateLicenceById,
    getLicenseById
}
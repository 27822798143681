
let logoutFn = null; 


export const setLogoutFunction = (fn) => {
 return logoutFn = fn;
};


export const getLogoutFunction = () => {
  return logoutFn;
};

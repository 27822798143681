
import instance2 from "../../utils/api";
import {tokenService} from "../../utils/token";
// const api = instance("v2")
class AuthService {
    async login(email, password) {
        try {
          const response = await instance2.post("/auth/login", { email, password });
          return response.data; 
        } catch (error) {
          throw error; // Throw the error to be caught in the action
        }
      }
    async permissions(data)  {
        try{
            const response = await instance2.post("/auth/permissions",data)
            return response.data
        }catch(error){
            throw error;
        }
    }
    async getAllAuthUsers(data)  {
      try{
          const response = await instance2.post("/auth/getAllAuthUsers",data)
          return response.data
      }catch(error){
          throw error;
      }
  }
   
  logout() {
    tokenService.action.removeUser();
  }

  register(data) {
    return instance2.post("/auth/signup",data);
  }
}

export const authService = {
    action:new AuthService()
}


import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme, variant, size }) => ({
  backgroundColor:
    variant === 'contained'
      ? theme.palette.primary.main
      : variant === "reset" ? theme.palette.primary.resetBtn : "transparent",
  color:
    variant === 'contained'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
  padding: size === 'large' ? theme.spacing(2, 5) : theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  border:
    variant === 'outlined'
      ? `1px solid ${theme.palette.primary.main}`
      : 'none',
  '&:hover': {
    backgroundColor:
      variant === 'contained'
        ? theme.palette.primary.dark
        : 'rgba(0, 0, 0, 0.08)',
  },
}));

export default CustomButton

import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import PublicLayout from "../layouts/PublicLayout";
import { useSelector } from "react-redux";
export const PublicRoute = ({ children }) => {
    const { accessToken} = useSelector((state) => state.auth);
    if (accessToken) {
        // user is not authenticated
        return <Navigate to="/home" />;
    }
    return (
        <PublicLayout>
            <Outlet />
            {children}
        </PublicLayout>
    );
};
import instance2 from "../../utils/api"; 

export const fetchUserProfile = async (data) => {
  try {
    const response = await instance2.post("/auth/userProfile", data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message; 
  }
};


export const updateUserProfile = async (data) => {
  try {
    const response = await instance2.patch("/auth/editProfile",data);
    return response.data; 
  } catch (error) {
    throw error.response?.data || error.message; 
  }
};
import { createSlice } from "@reduxjs/toolkit";
import { getAgencyDashboard, getAllPricingPlanNames, getTrialAgenciesWithinDateRange } from "../actions/agencyDashboardAction";


const initialState = {
  dashboardData: {},
  pricingPlans: [],
  trialAgencies: {},
  loading: null,
};

const agencydashboardSlice = createSlice({
  name: "Agency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgencyDashboard.pending, (state) => {
        state.loading = true;
      })

    .addCase(getAgencyDashboard.fulfilled, (state, { payload }) => {
        state.dashboardData = payload.data;  
        state.loading = false;
    })
      .addCase(getAgencyDashboard.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllPricingPlanNames.pending, (state) => {
        state.loading = true;
      })

    .addCase(getAllPricingPlanNames.fulfilled, (state, { payload }) => {
        state.pricingPlans = payload.data;  
        state.loading = false;
    })
      .addCase(getAllPricingPlanNames.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTrialAgenciesWithinDateRange.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrialAgenciesWithinDateRange.fulfilled, (state, { payload }) => {
        state.trialAgencies = payload.data;
        state.loading = false;
      })
      .addCase(getTrialAgenciesWithinDateRange.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default agencydashboardSlice.reducer;

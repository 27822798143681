import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Avatar, Divider, Grid, IconButton, Select, styled, TextField, Tooltip, Typography } from "@mui/material";

// import Style
import Style from "./style";

// import Components
import CountCard from "../../components/CountCard";
import CardComponent from "../../components/CardComponent"
import { InfoIcon, MoreIcon, ReportsCalenderIcon, TotalagenciesIcon } from "../../assets/svg";
import LineChart from "../../components/Charts/LinChart";
import AreaChart from "../../components/Charts/AreaChart";
import StackedVerticleChart from "../../components/Charts/StatckedVerticleChart";
import BarChart from "../../components/Charts/BarChart";
import DoughnetChart from "../../components/Charts/DoughnetChart";
import HalfPieChart from "../../components/Charts/HalfDoughnetChart";
import HalfDoughnetChart from "../../components/Charts/HalfDoughnetChart";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getAgencyDashboard, getAllPricingPlanNames, getTrialAgenciesWithinDateRange } from "../../store/actions/agencyDashboardAction";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WorldMapChart from "../../components/Charts/HalfDoughnetChart";
import MapChart from "../../components/Charts/MapChart";

const Home = (props, classes) => {

    const { className } = props;
    const dispatch = useDispatch();
    const [paymentStartDate, setPaymentStartDate] = useState(new Date(moment().startOf('month')));
    const [paymentEndDate, setPaymentEndDate] = useState(new Date());
    let formattedPaymentStart = '';
    let formattedPaymentEnd = '';


    if (paymentStartDate) {
        const momentDate = moment(paymentStartDate);
        formattedPaymentStart = momentDate.format('DD MMM YY');
    }

    if (paymentEndDate) {
        const momentDate = moment(paymentEndDate);
        formattedPaymentEnd = momentDate.format('DD MMM YY');
    }
    const handlePaymentStartChange = (date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        setPaymentStartDate(newDate);
    };

    const handlePaymentEndChange = (date) => {
        const newDate = new Date(date);
        newDate.setHours(23, 59, 59, 999);
        setPaymentEndDate(newDate);
    };

    const agencyState = useSelector((state) => state.agencyDashboard) || {};

    useEffect(() => {
        dispatch(getAgencyDashboard());
        dispatch(getAllPricingPlanNames());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getTrialAgenciesWithinDateRange({
            startDate: paymentStartDate,
            endDate: paymentEndDate,
        }));
    }, [dispatch, paymentStartDate, paymentEndDate]);

    const { dashboardData = {}, pricingPlans = [], trialAgencies = {} } = agencyState;
    const overallTrialAgencies = trialAgencies.trialAgenciesCount?.overallTrialAgencies || [];
    const overallPaidAgencies = trialAgencies.trialAgenciesCount?.overallPaidAgencies || [];
    const cancelledAgencies = trialAgencies.trialAgenciesCount?.cancelledAgencies || [];
    const totalTrialAgencies = overallTrialAgencies.length;
    const totalPaidAgencies = overallPaidAgencies.length; 
    const totalCancelledAgencies = cancelledAgencies.length;

    return (
        <Grid container spacing={3} className={className}>
            <CountCard />
            {/*  */}
            <Grid item xs={12} md={3} >
                <CardComponent color="secondary1" className={classes.totalsdetails} title={"Total Agencies"}>
                    <Typography variant='h4'>{dashboardData.totalAgencies}</Typography>
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={3}>
                <CardComponent title={"Number of Paid Agencies"}>
                <Typography variant='h4'> {dashboardData.paidAgencies}</Typography>

                </CardComponent>
            </Grid>
            <Grid item xs={12} md={3}>
                <CardComponent title={"Number of Trial Agencies"}>
                    <Typography variant='h4'>{dashboardData.trialAgencies}</Typography>

                </CardComponent>
            </Grid>
            <Grid item xs={12} md={3}>
                <CardComponent title={"Total Number of Users"} >
                    <Typography variant='h4'>{dashboardData.totalUsers}</Typography>

                </CardComponent>
            </Grid>
        
            {/*  */}
            <Grid item variant='h4'  fontSize="26px" fontWeight="bold" xs={12} md={3}>
                Summary
            </Grid>

            <Grid item xs={12} md={9}>
                <Grid container spacing={5}>
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                value={paymentStartDate}
                                onChange={handlePaymentStartChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            endAdornment: <ReportsCalenderIcon />
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="End Date"
                                value={paymentEndDate}
                                onChange={handlePaymentEndChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            endAdornment: <ReportsCalenderIcon />
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <CardComponent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant='h4'>{`Paid Agencies`}</Typography>
                        </Grid>
                        <Grid item className="dflex" gap="5px">
                            <Typography variant='h4' color="#2463eb">{totalPaidAgencies}</Typography>
                            <Typography>total nos </Typography>
                        </Grid>
                    </Grid>
                    <LineChart data={trialAgencies} startDate={paymentStartDate} endDate={paymentEndDate} />
                </CardComponent>
            </Grid>

            <Grid item xs={12} md={6}>
                <CardComponent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant='h4'>{`Trial Agencies`}</Typography>
                        </Grid>
                        <Grid item className="dflex" gap="5px">
                            <Typography variant='h4' color="#2463eb">{totalTrialAgencies}</Typography>
                            <Typography>total nos </Typography>
                        </Grid>
                    </Grid>
                    <AreaChart data={trialAgencies} startDate={paymentStartDate} endDate={paymentEndDate} />
                </CardComponent>
            </Grid>

            <Grid item xs={12} md={6}>
                <CardComponent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant='h4'>{`Cancelled Agencies`}</Typography>
                        </Grid>
                        <Grid item className="dflex" gap="5px">
                            <Typography variant='h4' color="#2463eb">{totalCancelledAgencies}</Typography>
                            <Typography>total nos </Typography>
                        </Grid>
                    </Grid>
                    <StackedVerticleChart data={trialAgencies} startDate={paymentStartDate} endDate={paymentEndDate} />
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant='h4'>{`Agency Location `}</Typography>
                        </Grid>
                        <Grid item className="dflex" gap="5px">
                            <Typography variant='h4' color="#2463eb">{totalCancelledAgencies}</Typography>
                            <Typography>total nos </Typography>
                        </Grid>
                    </Grid>
                    <MapChart data={trialAgencies} startDate={paymentStartDate} endDate={paymentEndDate} />
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant='h4'>{`Agencies Under Plan`}</Typography>
                        </Grid>
                    </Grid>
                    <DoughnetChart data={trialAgencies} startDate={paymentStartDate} endDate={paymentEndDate} />
                </CardComponent>
            </Grid>
             <Grid item xs={12} md={6}>
                <CardComponent>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant='h4'>{`Users`}</Typography>
                        </Grid>
                    </Grid>
                    <HalfDoughnetChart data={trialAgencies} startDate={paymentStartDate} endDate={paymentEndDate} />
                </CardComponent>
            </Grid>

            {/* <Grid item xs={12} md={6}>
            <CardComponent title={"Half Doughnet Chart"} actionBtn>
                <HalfDoughnetChart />
            </CardComponent>
            </Grid> */}

        </Grid>
    )
}

Home.defaultProps = {
    classes: {}
};

Home.propTypes = {
    className: PropTypes.string
};

export default styled(Home)(Style);
import React, { useEffect, useState,useRef } from "react";
import { styled,Tooltip, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getInvite } from "../../store/actions/inviteAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  clearFilter,
} from "../../store/reducers/inviteReducer";
import Table from "../../components/Table";
import { rulesToParams } from "../../components/Filter/filterUtil";
import moment from 'moment';
import { ActionCell } from "../../components/MoreAction/MoreAction";
import { useNavigate,useLocation } from "react-router-dom"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { CopyToClipboard } from 'react-copy-to-clipboard'; 
import { CopyIcon } from "../../assets/svg";

const styles = {
  ".avatar-cell": {
    display: "flex",
    alignItems: "center",
    ".avatar-title": {
      marginLeft: "8px",
    },
  },
  ".copy-icon": {
    cursor: "pointer",
    transition: "color 0.3s",
  },
  ".copy-icon:hover": {
    color: "#007bff", // Color for hover
  },
  ".copied-icon": {
    color: "green",
    transition: "color 0.3s",
  },
};



const agencyName = (item) => {

  return <span>{item.rowData.agency_name
  }</span>;
};
const inviteEmail = (item) => {

  return <span>{item.rowData.invite_email

  }</span>;
};

const StatusCell = (props) => {
  const { item, rowData } = props
  const color = rowData?.invite_status === "Unknown" ? "#9DA9BA": rowData?.invite_status === "Read" ? "#D6DFFF":rowData?.invite_status === "Accepted" ? "#D4F6D2":rowData?.invite_status === "Sent" ? "#EDEDED": rowData?.invite_status === "Rejected" ? "#FFDFDD" : "9DA9BA"
  const textColor = rowData?.invite_status === "Unknown" ? "#FFFFFF" : rowData?.invite_status === "Read" ? "#7866E4" : rowData?.invite_status === "Accepted" ? "#0E8E2A": rowData?.invite_status === "Sent" ? "#64748B": rowData?.invite_status === "Rejected" ? "background: #FF3B3B": "#FFFFFF"
  const width = "100px"; // Set a specific width for consistency
  const renderText = rowData?.newStatus === "Sent" ? "Trail" : rowData?.invite_status === "Active" ? "Active" : "Inactive"
  return (
    <span
      style={{
        display: "inline-block",
        width: width,
        textAlign: "center",
        color: textColor,
        backgroundColor: color,
        borderRadius: "6px",
        padding: "8px 10px",
      }}
    >
      {rowData?.invite_status}
    </span>
  );
};
const CreatedOnCell = ({ item }) => {
  const formatDate = (isoDate) => {
    return moment(isoDate).format('DD MMMM YYYY');
  };
  return <span>{formatDate(item)}</span>;
};
const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <Tooltip title={copied ? "Copied!" : "Copy"} arrow>
        <IconButton className={copied ? "copied-icon" : "copy-icon"}>
          {copied ? <CheckIcon /> : <CopyIcon height="24px" width="24px" />}
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
};
const cellMap = {
  name: agencyName,
  status: StatusCell,
  email: inviteEmail,
  
  created_on: CreatedOnCell,
  action: ActionCell,
  invite_code: (item) => <CopyButton text={item.rowData.invite_code} />
};

const Invite = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.Invite);
  const { pagination, ordering, orderBy, filter, count, page_size, num_pages, page_number } = list;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = list.search || queryParams.get('search') || '';
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    paymentMode: []
  })

  const filters = [
    { key: "status", label: "Status", options: [{ value: '0', label: "Draft" }, { value: '1', label: "Sent" },{ value: '2', label: "Read" },{ value: '3', label: "Accepted" },{ value: '4', label: "Rejected" }], },
    
  ];
  const hasFetchedData = useRef(false);
  useEffect(() => {
    // let keysearch = "";
    // let keyfilter = "";
    // if (search) {
    //   keysearch = `&search=${search}`;
    // }
    // if (filter.params) {
    //   keyfilter = `&filter=${filter.params}`;
    // }
   
    dispatch(
        getInvite(
        { ...pagination, search, ...selectedFilters, ordering: list.ordering }
      )
    );

  }, [dispatch, pagination.count,
    pagination?.page_size,
    pagination?.page_number,
    search, ordering,
    filter.params, 
    pagination?.num_pages,
    list.ordering
  ]);

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  const onFilter = (reason, data) => {
    switch (reason) {
      case "add":
        dispatch(addFilterRule());
        break;
      case "remove":
        dispatch(removeFilterRule(data));
        break;
      case "update":
        dispatch(updateFilterRule(data));
        break;
      case "apply":
        dispatch(applyFilter(rulesToParams(filter.rules)));
        break;
      case "clear":
        dispatch(clearFilter());
        break;
      default:
        return;
    }
  };

  const applyFilter = () => {
    dispatch(
        getInvite(
        { ...pagination, search, ...selectedFilters, ordering: list.ordering }
      )
    );
  }
  const navigate = useNavigate()
  function onEdit(data) {
    navigate(`/agencies/${data?.id}`)

  }
  return (
    <div className={className}>
      <Table
        title={"Invitations"}
        headers={list?.headers}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        filter={filters}
        onFilter={onFilter}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filterOptions={filters}
        applyFilter={applyFilter}
        onEdit={onEdit}
        isSort
        isFilter
        isSearch={true}
      />
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

export default styled(Invite)(styles);

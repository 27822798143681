import { createSlice } from "@reduxjs/toolkit";
import { updatePasswordAction } from "../actions/passwordUpdateAction";


const initialState = {
  list: {
    user: null,
    password: {},
    pagination: {
      count: 10,
      next: null,
      previous: null,
      page_size: 10,
      num_pages: 0,
      page_number: 1,
    },
  },
  loading: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setProfile(state, action) {
      state.list.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
      builder
      .addCase(updatePasswordAction.pending, (state) => {
        state.loading = true;  
      })
      .addCase(updatePasswordAction.fulfilled, (state, { payload }) => {
        state.list.profile = payload;
        state.loading = false; 
      })
      .addCase(updatePasswordAction.rejected, (state, action) => {
        state.loading = false;  
      });
  },
});

export const { setProfile } = authSlice.actions;
export default authSlice.reducer;

import React, { useState, useEffect } from "react";
import { TextField, Grid, FormControl, Select, MenuItem, Box } from "@mui/material";

const RangeInputFilter = ({ label, value, onChange, options }) => {
    const [condition, setCondition] = useState(value.condition || options[0].value);  
    const [rangeValue, setRangeValue] = useState({ min: value.min || '', max: value.max || '' });
    const [isBetween, setIsBetween] = useState(condition === 'between');

    useEffect(() => {
        setCondition(value.condition || options[0].value);
        setRangeValue({ min: value.min || '', max: value.max || '' });
    }, [value]);

    const handleInputChange = (event, type) => {
        const inputValue = event.target.value;
        const updatedRange = { ...rangeValue, [type]: inputValue };
        setRangeValue(updatedRange);
        notifyParent(condition, updatedRange);  
    };

    const handleConditionChange = (event) => {
        const selectedCondition = event.target.value;
        setCondition(selectedCondition);
        setIsBetween(selectedCondition === 'between');
        notifyParent(selectedCondition, rangeValue); // Notify parent with the current range values
    };

    const notifyParent = (selectedCondition, updatedRange) => {
        onChange({ condition: selectedCondition, ...updatedRange });
    };

    return (
        <Box 
            sx={{ 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                padding: '16px', 
                fontSize: '12px', 
                width: '300px' 
            }}
        >
            <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Select 
                            value={condition} 
                            onChange={handleConditionChange} 
                            size="small" 
                            style={{ fontSize: '12px' }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid container item xs={12} spacing={1} direction={isBetween ? "row" : "column"}>
                    {isBetween && (
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                placeholder="Min"
                                value={rangeValue.min}
                                onChange={(e) => handleInputChange(e, 'min')}
                                size="small"
                                sx={{
                                    width: '130px',  
                                    height: '30px',  
                                    fontSize: '12px',
                                }}
                                InputProps={{ style: { fontSize: '12px' } }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={isBetween ? 6 : 12}>
                        <TextField
                            type="number"
                            placeholder={isBetween ? "Max" : "0"}
                            value={rangeValue.max}
                            onChange={(e) => handleInputChange(e, 'max')}
                            size="small"
                            sx={{
                                width: isBetween ? '130px' : '270px',  
                                height: '30px',  
                                fontSize: '12px',
                            }}
                            InputProps={{ style: { fontSize: '12px' } }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RangeInputFilter;

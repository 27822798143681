import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import { getAllCollabAgencies } from "../services/collabService";

export const getAllCollabAgenciesAsync = createAsyncThunk(
  "collab/getAll",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading("Fetching Collab Agencies..."));
      const response = await getAllCollabAgencies(data);
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import FlozySupport from "../containers/FlozySupport";
import CombinedLayout from "../layouts/AgencyLayout";
import FaqSupport from "../containers/Faq";




const FlozySupportRoutes = [
    {
        path:"/flozysupport",
        element:<ProtectedRoute/>,
        children:[{
            element: <CombinedLayout />,
            Children:[
           {
            path:"",
            Children:[
                {
                    path:"pagesupport",
                    element:<FlozySupport/>
                },
                {
                    path: 'faq',
                    element: < FaqSupport/>
                },
            ]
           }
            ]
        },
        
    ]
    }
]

export default  FlozySupportRoutes
// import React from "react";
// import Affliate from "../containers/Referral/Affliate";
// import Conversion from "../containers/Referral/Conversion/index";
// import { ProtectedRoute } from "../hoc/ProtectedRoute";
// import CombinedLayout from "../layouts/AgencyLayout";

const supportDashboardRoutes = [
  {
   
 
  },
];

export default supportDashboardRoutes;

import { createSlice } from "@reduxjs/toolkit";
import { getAgencies, getAgenciesById, getAgencyDeatil,getAllAgencyDetailsAsync } from "../actions/agenciesAction";

const initialState = {
  list: {
    results: [],
    agencyDetails: {},
    headers: [
      { field: 'agency_name', label: 'Agency Name' },
      { field: 'agencyStatus', label: 'Status' },
      // { field: 'username', label: 'User name' },
      { field: 'agency_email', label: 'Agency Email' },
      // { field: 'profile_name', label: 'Profile Name' },
      { field: "plan_name", label: "Plan Name" },
      { field: "price", label: "Price" },
      { field: 'created_on', label: 'Created On' },
      { field: "action", label: "ACTION" }
    ],
    connectedInvoices: [
      { field: 'customer_name', label: 'Customer Name' },
      { field: 'customer_email', label: 'Customer Email' },
      { field: 'invoice_total', label: 'Amount' },
      { field: 'created_on', label: 'Create On' },
      { field: 'invoice_type', label: 'Invoice Type' },
    ],
    clients: [
      { field: 'client_name', label: 'Client Name' },
      { field: 'client_email', label: 'Client Email' },
      { field: 'short_description', label: 'Short description' },
      { field: 'client_brief', label: 'Client Brief' },
    ],
    bills:[
      { field: 'amount_paid', label: 'Amount Paid' },
      { field: 'next_bill', label: 'Next Bill' },
      { field: 'status', label: 'Status' },
      { field: 'period', label: 'Period' },
    ],
    billingInvoices:[  
      { field: 'period', label: 'PLAN PERIOD' },  
      { field: 'amount_paid', label: 'AMOUNT ' },
      { field: 'product_name', label: 'SUBSCRIPTION' },
      { field: 'created_on', label: 'CREATED DATE' },
      { field: 'status', label: 'STATUS' },
      { field: 'action', label: 'ACTION' },

    ],
    pagination: {
      count: 10,
      next: null,
      previous: null,
      page_size: 10,
      num_pages: 0,
      page_number: 1,
    },
    search: "",
    filter: {
      params: "",
      rules: [{ index: 0, field: "", operator: "", value: "" }],
    },
    ordering: "created_on",
    orderBy: [
      { field: "created_on", label: "Created On" },
      { field: "agency_name", label: "Agency Name" },
      { field: "agency_email", label: "Agency Email" }
      // , { field: "expiry", label: "Expiry" }, 
      // { field: "agency_plan", label: "Plan" }, 
      // { field: "price", label: "Price" },

    ],
  },
  loading: null,
};

export const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    setPageSize(state, action) {
      state.list.pagination.page_size = action.payload;
    
    },
    setPage(state, action) {
      state.list.pagination.page_number = action.payload;
    },
    setSearch(state, action) {
      state.list.pagination.page_number = 1;
      state.list.search = action.payload;
    },
    setOrdering(state, action) {
      state.list.ordering = action.payload;
    },
    addFilterRule(state, action) {
      state.list.filter.rules.push({
        index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
        field: "",
        operator: "",
        value: "",
      });
    },
    removeFilterRule(state, action) {
      state.list.filter.rules = state.list.filter.rules.filter(
        (f) => f.index !== action.payload
      );
    },
    updateFilterRule(state, action) {
      state.list.filter.rules = state.list.filter.rules.map((m) => {
        if (m.index === action.payload.index) {
          return action.payload;
        }
        return m;
      });
    },
    applyFilter(state, action) {
      state.list.pagination.page_number = 1;
      state.list.filter.params = action.payload;
    },
    clearFilter(state) {
      state.list.pagination.page_number = 1;
      state.list.filter.params = "";
      state.list.filter.rules = [{ index: 0, field: "", value: "" }];
    },
    clearAgencyDetails(state){
      state.list.agencyDetails  = {}
    }
  },
  extraReducers: (builder) => {
    // add cases for getAgencies api calls
    builder
      .addCase(getAgencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgencies.fulfilled, (state, { payload }) => {
        state.list.pagination = {
          ...payload?.data?.pagination,
        };

        state.list.results = payload?.data?.results
        state.loading = false;
      })
      .addCase(getAgencies.rejected, (state) => {
        state.loading = false;
      });

    // add cases for other api calls
    // like builder.addCase

    builder
      .addCase(getAgenciesById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgenciesById.fulfilled, (state, { payload }) => {
        state.list.agencyDetails = payload?.data[0]
        state.loading = false;
      })
      .addCase(getAgenciesById.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getAgencyDeatil.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgencyDeatil.fulfilled, (state, { payload }) => {
    
        state.list.pagination = {
          ...state.list.pagination,
          ...payload.data.pagination,
        };
  
        state.list.results = payload.data.results
        state.loading = false;
      })
      .addCase(getAgencyDeatil.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getAllAgencyDetailsAsync.pending,(state)=>{
        state.loading = true
      })
      .addCase(getAllAgencyDetailsAsync.fulfilled,(state,{payload})=>{

        state.list.agencyDetails = payload?.data
      })
      .addCase(getAllAgencyDetailsAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setPage,
  setPageSize,
  setSearch,
  setOrdering,
  addFilterRule,
  removeFilterRule,
  updateFilterRule,
  applyFilter,
  clearFilter,
  clearAgencyDetails,
} = agenciesSlice.actions;

export default agenciesSlice.reducer;

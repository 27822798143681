import React from "react";
import Affliate from "../containers/Referral/Affliate";
import Conversion from "../containers/Referral/Conversion/index";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import CombinedLayout from "../layouts/AgencyLayout";
import Payouts from "../containers/Referral/Payouts";
import Commissions from "../containers/Referral/Commissions";

const affiliateDashboardRoutes = [
  {
    path: "/affiliate",
    element: <ProtectedRoute />,
    children: [
      {
        element: <CombinedLayout />,
        children: [
          {
            path: "",
            children: [
              {
                path: "list",
                element:<Affliate/>
              },
              {
                path: "create",
                // element:
              },
              {
                path: "edit/:id",
                // element:
              },
            ],
          },
          {
            children: [
              {
                path: "Conversions",
                 element:<Conversion/>
              },
              // {
              //   path: "create",
              //   // element:
              // },
              // {
              //   path: "edit/:id",
              //   // element:
              // },
            ],
          },
          {
            children: [
              {
                path: "Commissions",
                 element:<Commissions />
              },
            ],
          },
          {
            children: [
              {
                path: "Payouts",
                 element:<Payouts/>
              },
             
            ],
          },
        
        ],
      },
    ],
  },
];

export default affiliateDashboardRoutes;

import axios from "axios";
const REACT_APP_API_HOST_1 = process.env.REACT_APP_API_HOST_1;

 const instance1 = axios.create({
  //  baseURL:"https://qa-supportserver.agenciflow.com/api/v1",
  // baseURL:"http://localhost:9000/api/v1",

  baseURL:REACT_APP_API_HOST_1,
    withCredentials:true,
    headers: {
      "Content-Type": "application/json",
    },
  })

  export default instance1;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import { agencydashboardService } from "../services/agencyDashboardService";


export const getAgencyDashboard = createAsyncThunk(
  "agency/getDashboard",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Fetching Dashboard Data..."));
      const response = await agencydashboardService.getAgencyDashboard(data);
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getAllPricingPlanNames = createAsyncThunk(
  "agency/getPricingPlans",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Fetching Pricing Plans..."));
      const response = await agencydashboardService.getAllPricingPlanNames();
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);


export const getTrialAgenciesWithinDateRange = createAsyncThunk(
    "agency/getTrialAgenciesWithinDateRange",
    async (data, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Fetching Trial Agencies..."));
        const response = await agencydashboardService.getTrialAgenciesByDate(data);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );
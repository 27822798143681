import { createSlice } from "@reduxjs/toolkit";
import { updateUserProfileAction, userProfile } from "../actions/userProfileAction";

const initialState = {
  list: {
    user: null,
    profile: {},
    pagination: {
      count: 10,
      next: null,
      previous: null,
      page_size: 10,
      num_pages: 0,
      page_number: 1,
    },
  },
  loading: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setProfile(state, action) {
      state.list.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(userProfile.fulfilled, (state, { payload }) => {
        state.list.profile = payload.data; 
        state.loading = false;
      })
      .addCase(userProfile.rejected, (state, action) => {
        state.loading = false;
      });
      builder
      .addCase(updateUserProfileAction.pending, (state) => {
        state.loading = true;  
      })
      .addCase(updateUserProfileAction.fulfilled, (state, { payload }) => {
        state.list.profile = payload;
        state.loading = false; 
      })
      .addCase(updateUserProfileAction.rejected, (state, action) => {
        state.loading = false;  
      });
  },
});

export const { setProfile } = authSlice.actions;
export default authSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { createsupportFaqAsync, getAllFaqsAsync, updateSupportFaqAsync } from "../actions/faqAction";

const initialState = {
  list: {
    results: [],
    pagination: {
      count: 10,
      next: null,
      previous: null,
      page_size: 10,
      num_pages: 0,
      page_number: 1,
    },
    search: "",
    filters: {
      question_type: [],
      tags: [],
      mainModule: [],
      created_by: "",
    },
  },
  faqById: {},
  loading: null,
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setPageSize(state, action) {
      state.list.pagination.page_size = action.payload;
    },
    setPage(state, action) {
      state.list.pagination.page_number = action.payload;
    },
    setSearch(state, action) {
      state.list.pagination.page_number = 1; 
      state.list.search = action.payload;
    },
    applyFilter(state, action) {
      state.list.pagination.page_number = 1;
      state.list.filters = { ...state.list.filters, ...action.payload };
    },
    clearFilter(state) {
      state.list.pagination.page_number = 1;
      state.list.filters = {
        question_type: [],
        tags: [],
        mainModule: [],
        created_by: "",
      };
    },
    setFaqById(state, action) {
      state.faqById = action.payload; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createsupportFaqAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createsupportFaqAsync.fulfilled, (state, { payload }) => {
        state.list.results.push(payload); 
        state.loading = false;
      })
      .addCase(createsupportFaqAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllFaqsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFaqsAsync.fulfilled, (state, { payload }) => {
        state.list.results = payload.results;
        state.list.pagination = {...payload.pagination };
        state.loading = false;
      })
      .addCase(getAllFaqsAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateSupportFaqAsync.pending, (state) => {
        state.loading = true;
    })
    .addCase(updateSupportFaqAsync.fulfilled, (state, { payload }) => {

        const index = state.list.results.findIndex(faq => faq.id === payload.id);
        if (index !== -1) {
            state.list.results[index] = payload; 
        }
        state.loading = false;
    })
    .addCase(updateSupportFaqAsync.rejected, (state) => {
        state.loading = false;
    });
  },
});

export const {
  setPageSize,
  setPage,
  setSearch,
  applyFilter,
  clearFilter,
  setFaqById,
} = faqSlice.actions;

export default faqSlice.reducer;

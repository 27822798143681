import instance1 from "../../utils/api1";

export const agencydashboardService = {
  getAgencyDashboard,
  getAllPricingPlanNames,
  getTrialAgenciesByDate
};

async function getAgencyDashboard(data) {
  return await instance1.post(`agencyDashboard/get`, data);
}

async function getAllPricingPlanNames() {
  return await instance1.get(`agencyDashboard/getPricingPlans`);
}


async function getTrialAgenciesByDate(data) {
    return await instance1.post(`agencyDashboard/getTrialAgenciesByDate`, data);
  }
/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';

const TextAreaContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '7px',
    padding: '5px',
    backgroundColor: '#fff',
    '&:hover': {
        borderColor: 'black',
    },
    '&:focus-within': {
        borderColor: 'blue',
        border: "2px solid blue"

    },
}));

const TextArea = styled('textarea')(({ theme }) => ({
    width: '100%',
    height: '150px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    fontSize: '16px',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontStyle: props => props.fontStyle || 'normal',
    fontWeight: props => props.fontWeight || 'normal',
    textDecoration: props => props.textDecoration || 'none',
}));

// const Toolbar = styled('div')(({ theme }) => ({
//     display: 'flex',
//     gap: '5px',
//     padding: '5px 10px',
//     backgroundColor: '#f0f0f0',
//     borderTop: '1px solid #ccc',
//     position: 'absolute',
//     bottom: '1px',
//     left: '0',
//     width: '100%',
//     height: "30px",
//     borderRadius: "3px"
// }));

// const IconButton = styled('button')(({ theme }) => ({
//     cursor: 'pointer',
//     padding: '5px',
//     backgroundColor: 'transparent',
//     border: 'none',
//     '&:hover': {
//         color: '#000',
//     },
// }));

const RichTextAreaComponent = ({
    id = "",
    name = "",
    placeholder = "Add description",
    ...rest
}) => {
    const textAreaRef = useRef(null);
    const [fontStyle, setFontStyle] = useState('normal');
    const [fontWeight, setFontWeight] = useState('normal');
    const [textDecoration, setTextDecoration] = useState('none');

    // const applyStyle = (style) => {
    //     if (style === 'italic') {
    //         setFontStyle(prev => (prev === 'normal' ? 'italic' : 'normal'));
    //     } else if (style === 'bold') {
    //         setFontWeight(prev => (prev === 'normal' ? 'bold' : 'normal'));
    //     } else if (style === 'underline') {
    //         setTextDecoration(prev => (prev === 'none' ? 'underline' : 'none'));
    //     }
    // };

    return (
        <TextAreaContainer>
            <TextArea
                id={id}
                name={name}
                ref={textAreaRef}
                placeholder={placeholder}
                {...rest}
                style={{
                    fontStyle: fontStyle,
                    fontWeight: fontWeight,
                    textDecoration: textDecoration
                }}
            />
            {/* <Toolbar>
                <IconButton type="button" onClick={() => applyStyle('italic')}
                    style={{ borderBottom: `${fontStyle === 'italic' ? '1px solid blue' : 'none'}` }}
                >
                    I
                </IconButton>
                <IconButton type="button" onClick={() => applyStyle('bold')}
                    style={{ borderBottom: `${fontWeight === 'bold' ? '1px solid blue' : 'none'}` }}
                >
                    B
                </IconButton>
                <IconButton type="button" onClick={() => applyStyle('underline')}
                    style={{ borderBottom: `${textDecoration === 'underline' ? '1px solid blue' : 'none'}` }}
                >
                    U
                </IconButton>
            </Toolbar> */}
        </TextAreaContainer>
    );
};

RichTextAreaComponent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

export default RichTextAreaComponent;

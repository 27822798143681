import { createSlice } from "@reduxjs/toolkit";
import { getAllModules } from "../actions/mainModuleAction";

const initialState = {
  mainModules: null,
//   loading: null,
//   permissions:{},
//   userId:null,
//   accessToken: ""
};

export const mainModuleSlice = createSlice({
  name: "mainModule",
  initialState,
  reducers: {
    setNull(state) {
      state.mainModules = {};
    },
    // accessToken(state,action){
    //   state.accessToken = action.payload;
    // },
    // loginSuccess(state,action){
    //   state.user = action.payload
    // }

  },
  extraReducers: (builder) => {
    // add cases for login api calls

    builder
      .addCase(getAllModules.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllModules.fulfilled, (state, { payload }) => {
        // state.user = payload;
        state.mainModules = payload?.data
        
      })
      .addCase(getAllModules.rejected, (state) => {
        state.loading = false;
      });
    
    // add cases for other api calls
    // like builder.addCase
  },
});

export const {setNull } = mainModuleSlice.actions;

export default mainModuleSlice.reducer;

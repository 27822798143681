import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



// function a11yProps(index) {
//     return {
//         id: `tab-${index}`,
//         'aria-controls': `tabpanel-${index}`,
//     };
// }

export function LabTabs({ tabs, onChange, tabValue }) {

    const handleChange = (event, newValue) => {
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <Box sx={{ typography: 'body1' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.name} value={tab.value} />
                    ))}
                </Tabs>
            </Box>

        </Box>
    );
}






import React, { useState ,useEffect} from "react";
import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Table as Core,
  TableBody,
  Typography,
  Pagination,
  Select,
  MenuItem,
  // IconButton,
  // Menu,
  List,
  ListItem,
  // ListItemText,
  // Button,
  Tooltip,
} from "@mui/material";
import Style from "./style";
import TableHeader from "./TableHeader";
// import { ActionCell } from "../MoreAction/MoreAction";
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterHeader from "../Filter/components/FilterHeader"
import { useSelector,dispatch, useDispatch } from "react-redux";
import { FaqCopyIcon } from "../../assets/svg/index"
import { ActionCell } from "../MoreAction/MoreAction";
import {setDeactivateFilterHeader} from "../../store/reducers/uiReducer"
const Table = (props) => {
  const {
    className,
    headers,
    data,
    cellMap,
    pagination,
    search,
    handlePagination,
    handlePageSize,
    title,
    handleSearch,
    ordering,
    orderBy,
    onSort,
    filter,
    onFilter,
    buttonText,
    createUrl,
    onEdit,
    onDelete,
    showHeader = false,
    showPagination = false,
    filterOptions,
    setSelectedFilters,
    selectedFilters,
    applyFilter,
    isFilter,
    isSort,
    // activeFilter,
    popUp,
    popUpHandler,
    isSearch,
    isFaqView = false,
    openMorePreviewPopup = () => { }
  } = props;
  const { filterHeader } = useSelector((state) => state.ui);

  const { num_pages, page_number, page_size, count } = pagination || {};
  const startIndex = page_number > 1 ? (page_number - 1) * page_size : 0;
  let endIndex = startIndex + page_size;
  endIndex = endIndex > count ? count : endIndex;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullAnswer, setShowFullAnswer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [active, setActive] = useState("")
  const handleMoreClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleAction = (action) => {
    handleClose();
    if (action === "edit") {
      onEdit(selectedRow);
    } else if (action === "delete") {
      onDelete(selectedRow);
    }
  };


  const renderCellValue = (field, value, rowData) => {
    const CellComponent = cellMap[field];

    if (CellComponent) {
      return <CellComponent item={value} rowData={rowData} onEdit={onEdit} onDelete={onDelete} />;
    } else {
      return value;
    }
  };

  const tableCell = (header, rowData) => {

    const field = header?.field;


    return (
      <TableCell key={`cell-value-${field}`}>
        <Typography component="div" color={"grey.light"}>
          {renderCellValue(field, rowData[field], rowData)}
        </Typography>
      </TableCell>
    );
  };

  const tableRow = (rowData, i) => {
    return (
      <TableRow key={`row-value-${i}`}>
        {headers?.map((header) => {
          return tableCell(header, rowData);
        })}
        {/* <TableCell>
          <IconButton onClick={(event) => handleMoreClick(event, rowData)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleAction("edit")}>Edit</MenuItem>
            <MenuItem onClick={() => handleAction("delete")}>Delete</MenuItem>
            <MenuItem onClick={() => handleAction("view")}>View</MenuItem>
          </Menu>
        </TableCell> */}
      </TableRow>
    );
  };

  const onSearch = (val) => {
    handleSearch(val);
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  
  const handleCopyAnswer = (answer) => {
    try {
      navigator.clipboard.writeText(answer);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 2000); 
    } catch (err) {
      console.error('Failed to copy the answer:', err);
    }
  };
  

  const handleOpenPop = (data) => {
    openMorePreviewPopup(data)
  }

  const tagColors = [
    "#FF5733","#33FF57","#3357FF","#FF33A1","#FFD833","#33FFD8", "#A133FF","#FF3333","#33FF33","#FF8C33",];

  const getColorForTag = (index) => {
    return tagColors[index % tagColors.length];
  };
  const handleEdit = (item) => {
    if (onEdit && typeof onEdit === 'function') {
      onEdit(item); 
    } else {
      console.error("not a function");
    }
  };
  const renderFaqList = () => (
    <List>
      {data?.map((item, index,tagIndex) => {
        const words = item.answer.split(" ");
        const isLongAnswer = words.length > 40;
        const toggleAnswer = () => setShowFullAnswer((prev) => !prev);
        return (
          <ListItem key={item.id}>
            <Grid container direction="column" spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item>
                <Typography variant="h4" style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                  {item.question_type}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="secondary1">
                  {showFullAnswer ? item.answer : words.slice(0, 40).join(" ") + (isLongAnswer ? "..." : "")}{item.icon}
                  {isLongAnswer && (
                    <Grid onClick={() => handleOpenPop(item)} color="#2563EB" className="cursorPointer">
                      {showFullAnswer ? "Read Less" : "Read More"}
                    </Grid>
                  )}
                </Typography>
              </Grid>
              <Grid item
                style={{
                  display: 'flex',
                  flexDirection: "row", alignItems: "center", gap: "2px"
                }}
              >
                <Typography variant="body2" color="secondary2" sx={{
                  width: 'fit-content', padding: '5px',
                  backgroundColor: '#F4F6F9',
                  borderRadius: '5px',
                  gap: '2px',
                }}>
                  Created: {item.createdBy?.name || "N/A"} {formatDate(item.created_at)}
                </Typography>
                <Typography variant="body2" className="pl5" color="secondary2" paddingTop="5px" >
                  {item.mainModule?.icon ? (
                    <img src={item.mainModule.icon} alt="Module Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                  ) : null}
                </Typography>
                <Typography variant="body1" color="secondary1" paddingBottom="3px" >
                  {item.mainModule?.module_name || "N/A"}
                </Typography>
                <Typography variant="body1"  fontWeight="600" color="secondary1" paddingBottom="3px"  className="ml5"   
                  style={{
                    color: getColorForTag(index),
                  }}>
                  {item.tags}
                </Typography>
                <Typography variant="body2" className="pl5" color="secondary2">
                <Tooltip title={tooltipOpen ? "Copied!" : "Copy"} arrow>
                  <span onClick={() => handleCopyAnswer(item.answer)} style={{ cursor: 'pointer' }}>
                    <FaqCopyIcon />
                  </span>
                </Tooltip>
              </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <ActionCell  onEdit={() => handleEdit(item)}/>
            </Grid>
          </ListItem>

        );
      })}
    </List>
  );
  const dispatch = useDispatch()
  useEffect(()=>{
    return () => {

      dispatch(setDeactivateFilterHeader())
    };
  
  },[])
  return (
    <Grid className={className}>
      {!showHeader && (
        <TableHeader
          title={title}
          onSearch={onSearch}
          onSort={onSort}
          search={search}
          orderBy={orderBy}
          ordering={ordering}
          headers={headers}
          onFilter={onFilter}
          filter={filter}
          buttonText={buttonText}
          createUrl={createUrl}
          isFilter={isFilter}
          isSort={isSort}
          popUp={popUp}
          popUpHandler={popUpHandler}
          isSearch={isSearch}
        />
      )}
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="table_container">
        {
          filterHeader && filterOptions && <Grid style={{ width: "100%", padding: "5px" }}>
            <FilterHeader selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} filterOptions={filterOptions} applyFilter={applyFilter} />
          </Grid>
        }
        <TableContainer style={{ padding: "10px" }}>
          {isFaqView ? renderFaqList() : null}
        </TableContainer>
        <TableContainer style={{ padding: "10px" }}>
          <Core stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers && headers?.map((m, i) => {
                  return (
                    <TableCell key={`cell-header-${m.field}`} className={`${active ? 'item-header-active' : 'item-header'}`}>
                      <Grid >
                        <Typography variant="body2" color={"grey.light"}>
                          {m.label}
                        </Typography>
                      </Grid>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data?.map((row, i) => tableRow(row, i))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers?.length}>
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Core>
        </TableContainer>
        {!showPagination && (
          <div className="pagination-wrapper">
            <div className="form-group">
              <label className="form-label">Show Rows:</label>
              <Select
                className="form-field"
                size="small"
                value={page_size}
                onChange={handlePageSize}
              >
                <MenuItem value={5}>5 Items</MenuItem>
                <MenuItem value={10}>10 Items</MenuItem>
                <MenuItem value={25}>25 Items</MenuItem>
                <MenuItem value={50}>50 Items</MenuItem>
              </Select>
            </div>
            <div className="form-group">
              {startIndex || 1} to {endIndex} of {pagination?.count}
            </div>
            <Pagination
              count={num_pages}
              page={page_number}
              onChange={handlePagination}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default styled(Table)(Style);

// applicationModuleService.ts
import instance2 from "../../utils/api"
async function createApplicationModule(data) {
    return instance2.post('faqs/modulecreate', data);
}

async function getAllApplicationModules() {
    return instance2.get('faqs/getAllModules');
}

export {
    createApplicationModule,
    getAllApplicationModules
};

import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts'; 

const MapChart = ({ style = { height: 400, width: '100%' } }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    fetch('https://cdn.jsdelivr.net/npm/echarts@latest/map/json/world.json')
      .then((response) => response.json())
      .then((geoJson) => {
        echarts.registerMap('world', geoJson);

        const chartOptions = {
          title: {
            sublink: 'https://en.wikipedia.org/wiki/List_of_countries_and_dependencies_and_their_capitals',
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}<br/>{c} (p / km²)',
          },
          geo: {
            map: 'world',
            roam: true,
            label: {
              show: false,
            },
            itemStyle: {
              borderWidth: 0,
              areaColor: '#DBEAFE',
            },
          },
          series: [
            {
              name: 'World Population Density',
              type: 'map',
              map: 'world',
              geoIndex: 0,
              label: {
                show: false,
              },
              data: [
                { name: 'China', value: 1450000000 },
                { name: 'India', value: 1330000000 },
                { name: 'USA', value: 331000000 },
                { name: 'Indonesia', value: 267000000 },
                { name: 'Pakistan', value: 225000000 },
                { name: 'Brazil', value: 213000000 },
                { name: 'Nigeria', value: 206000000 },
              ],
            },
          ],
        };

        setOptions(chartOptions);
      })
      .catch((error) => {
        console.error('Error loading map data:', error);
      });
  }, []);

  if (!options) {
    return <div>Loading...</div>;
  }

  return (
    <ReactECharts 
      option={options} 
      style={style} 
      notMerge={true} 
      lazyUpdate={true} 
    />
  );
};


export default MapChart;

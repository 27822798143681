// CancelSubscription.js
import React, { useState } from 'react';
import { Button, Typography, Grid, TextField, Radio, RadioGroup, FormControlLabel, Select, MenuItem } from '@mui/material';
import RichTextAreaComponent from "../../../../../components/TextArea/index"; // Adjust the import path as needed
import { useDispatch } from 'react-redux';
import { cancelSubscriptionAsync } from "../../../../../store/actions/subscriptonsAction";
import { useParams } from 'react-router-dom';

const CancelSubscription = ({ rowData, onClose }) => {
  const dispatch = useDispatch();
  const { agency_id } = useParams();
  const [selectedValue, setSelectedValue] = useState('immediate');
  const [customDate, setCustomDate] = useState('');
  const [notes, setNotes] = useState('');
  const [selectedReason, setSelectedReason] = useState('duplicate');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleDateChange = (event) => {
    setCustomDate(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };
  const handleCancel = () => {
    const data = {
      subscription_id: rowData.subscription_id,
      amount: rowData.amount,  
      cancellationReason: selectedReason,
      invoice_id: rowData.invoice_id,
      agency_id: agency_id.toString(),
      mode: selectedValue,
      date: customDate,
    };
    dispatch(cancelSubscriptionAsync(data));
    onClose(); // Close the cancel dialog after dispatching
  };

  return (
    <Grid style={{ width: "100%" }}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value="immediate"
          control={<Radio />}
          label={<Typography sx={{ color: selectedValue === 'immediate' ? 'black' : 'grey' }}>Immediate</Typography>}
        />
        <FormControlLabel
          value="endOfPeriod"
          control={<Radio />}
          label={<Typography sx={{ color: selectedValue === 'endOfPeriod' ? 'black' : 'grey' }}>End of Current Period</Typography>}
        />
        <FormControlLabel
          value="customDate"
          control={<Radio />}
          label={<Typography sx={{ color: selectedValue === 'customDate' ? 'black' : 'grey' }}>Custom Date</Typography>}
        />
      </RadioGroup>
      {selectedValue === 'customDate' && (
        <TextField
          type="date"
          value={customDate}
          onChange={handleDateChange}
        />
      )}

      <Typography variant='h6' style={{ marginBottom: "10px" }}>Notes for cancellation</Typography>
      <RichTextAreaComponent onChange={handleNotesChange} />

      <Typography style={{ marginTop: "10px" }}>Reason for Cancellation</Typography>
      <Select
        value={selectedReason}
        onChange={handleReasonChange}
        style={{ width: "100%" }}
      >
        <MenuItem value="duplicate">Duplicate</MenuItem>
        <MenuItem value="fraudulent">Fraudulent</MenuItem>
        <MenuItem value="expired_uncaptured_charge">Expired Uncaptured Charge</MenuItem>
        <MenuItem value="requested_by_customer">Requested by Customer</MenuItem>
      </Select>

      <Button onClick={handleCancel} color="primary" variant='contained' style={{ marginTop: "20px" }}>
        Yes Cancel
      </Button>
    </Grid>
  );
};

export default CancelSubscription;

const moduleTableStyles = (theme) => ({
  border:"1px solid #F3F3F3",
  borderRadius:"10px",
  ".main_con":{
    display:"flex",
    flex:"1 1",
  },
 
  ".left_main":{
    border:"1px solid #F3F3F3",
    flex:"0.7",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    wordWrap: "break-word",
  },
  ".right_main":{
    borderBottom:"1px solid #F3F3F3",
    flex:"1.2",
    display:"flex",
    flexDirection:"column",
    width:"100%",
    // alignItems:"center"
  },
  ".menu_name":{
    display:"flex",
    alignSelf:"center",
    padding:"10px"
  },
  ".access_level":{
    display:"flex",
    justifyContent:"space-between",
    padding:"10px",
    color:"#64748B",
    border:"1px solid #F3F3F3"
  },
  ".text":{
    color:"#8C9AAD"
  },
  ".border_main":{
    height:"15px",
    backgroundColor:"#F4F6F9",
    padding:"5px"
  },
  ".access_types":{
    display:"flex",
    flex:"1 1",
  },
  ".access_name":{
    border:"1px solid #F3F3F3",
    flex:"0.7",
    display:"flex",
    paddingLeft:"10px",
    alignItems:"center"
  },
  ".access_name_sub":{
    display:"flex",

    alignItems:"center"
  },
  ".access_level_con":{
    borderBottom:"1px solid #F3F3F3",
    flex:"1.2",
    display:"flex",
    justifyContent:"space-between",
    padding:"10px",
  },
  ".button_action":{
    display:"flex"
  }
 
})
export default moduleTableStyles
import { createSlice } from "@reduxjs/toolkit";
import {createAuthUserGroup,createAuthUserGroupDetail,getAllUserGroup,getAllUserGroupById,updateAuthUserGroupDetails,updateAuthUserGroupUser,updateAuthuserGroup} from "../actions/authUserGroupActions"
const initialState = {
    loading: null,
    authUserGroup:[],
    authUsers:[],
    authUserGroupUsers:[],
    authUserGroupDetails :[],
    authUserPermissions:[],
    specialPermissions:[],
    pagination: {
        count: 10,
        next: null,
        previous: null,
        page_size: 10,
        num_pages: 0,
        page_number: 1,
      },
  };
  
  export const authUserGroupSlice = createSlice({
    name: "authUserGroupS",
    initialState,

    reducers: {
        setAuthUsers(state, action) {
            state.authUsers = action.payload
          },
         setAuthUserPermissions(state,action){
            state.authUserPermissions = action.payload
         } ,
         setRestSpecialPermissions(state,action){
            state.specialPermissions = []
            state.authUserGroup = []
            state.authUserGroupDetails = []
         },
         setPageSize(state, action) {
            state.pagination.page_size = action.payload;
          
          },
          setPage(state, action) {
            state.pagination.page_number = action.payload;
          },
          setSearch(state, action) {
            state.pagination.page_number = 1;
            state.search = action.payload;
          },
    },

    extraReducers:(builder)=>{
        builder
            .addCase(createAuthUserGroup.pending,(state)=>{
                state.loading = true;

            })
            .addCase(createAuthUserGroup.fulfilled,(state,{payload})=>{
                state.loading = false;
                state.authUserGroup= payload
            })
            .addCase(createAuthUserGroup.rejected,(state,{payload})=>{
                state.loading = false;
                state.authUserGroup= payload
            });
        builder
            .addCase(createAuthUserGroupDetail.pending,(state)=>{
                state.loading = true;

            })
            .addCase(createAuthUserGroupDetail.fulfilled,(state,{payload})=>{
                state.loading = false;
                state.authUserGroupUsers= payload
            })
            .addCase(createAuthUserGroupDetail.rejected,(state,{payload})=>{
                state.loading = false;
                state.authUserGroup= payload
            })
        builder
            .addCase(getAllUserGroup.pending,(state)=>{
                state.loading = true;

            })
            .addCase(getAllUserGroup.fulfilled,(state,{payload})=>{
                state.loading = false;
                state.authUserGroup= payload?.res
                state.pagination={...payload?.pagination}
            })
            .addCase(getAllUserGroup.rejected,(state,{payload})=>{
                state.loading = false;
                state.authUserGroup= payload
            })
        builder
            .addCase(getAllUserGroupById.pending,(state)=>{
                state.loading = true;

            })
            .addCase(getAllUserGroupById.fulfilled,(state,{payload})=>{
                state.loading = false;
                state.authUserGroupDetails= [payload]
                state.specialPermissions = payload?.userGroupId[0]?.permissions?.modules
            })
            .addCase(getAllUserGroupById.rejected,(state,{payload})=>{
                state.loading = false;
                state.authUserGroupDetails= [payload]
            })
         builder
            .addCase(updateAuthUserGroupDetails.pending,(state)=>{
                state.loading = true;

            })
            .addCase(updateAuthUserGroupDetails.fulfilled,(state,{payload})=>{
                state.loading = false;
                state.authUserPermissions= payload
            })
            .addCase(updateAuthUserGroupDetails.rejected,(state,{payload})=>{
                state.loading = false;
                state.authUserPermissions= payload
            })
        builder
            .addCase(updateAuthUserGroupUser.pending,(state)=>{
                state.loading = true;

            })
            .addCase(updateAuthUserGroupUser.fulfilled,(state,{payload})=>{
                state.loading = false;
                state.authUserPermissions= payload
            })
            .addCase(updateAuthUserGroupUser.rejected,(state,{payload})=>{
                state.loading = false;
                state.authUserPermissions= payload
            })
        builder
        .addCase(updateAuthuserGroup.pending,(state)=>{
            state.loading = true;
        })
        .addCase(updateAuthuserGroup.fulfilled,(state,{payload})=>{
            state.loading = false;
            state.usergroupnames = payload
        })
        .addCase(updateAuthuserGroup.rejected,(state,{payload})=>{
            state.loading= false;
            state.usergroupnames=payload

        })
    }
  });
  
  export const { 
    setAuthUsers,
    setAuthUserPermissions,
    setRestSpecialPermissions,
    setPage,
    setPageSize,
    setSearch,
  } = authUserGroupSlice.actions;
  
  export default authUserGroupSlice.reducer;
  
import { createAsyncThunk } from "@reduxjs/toolkit";
import { mainModules } from "../services/mainModules";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";


export const getAllModules = createAsyncThunk(
    "mainModules/getAll",
    async (data, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Get All modules"));
        const response = await mainModules.getAllModules(data)
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );
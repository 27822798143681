import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getpagessupport,createFlow  as createFlowAction} from "../../store/actions/flozySupportAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
} from "../../store/reducers/flozySupportReducer";
import Table from "../../components/Table";
import moment from 'moment';
import Action from "../Agencies/Users/Component/Moreaction"
import {useLocation } from "react-router-dom"
const agencyName = (item) => {

  return <span>{item.rowData.agency_name
  }</span>;
};



const CreatedOnCell = ({ item }) => {
  const formatDate = (isoDate) => {
    return moment(isoDate).format('DD MMMM YYYY');
  };
  return <span>{formatDate(item)}</span>;
};

const cellMap = {
  name: agencyName,
  created_on: CreatedOnCell,
  action: (props) => (
    <Action {...props} type="create" />
  )

};

const PageSupport = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.support);
  const { pagination, ordering, orderBy,} = list;
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = list.search || queryParams.get('search') || '';
   useEffect(() => {
    dispatch(
      getpagessupport(
        { ...pagination, search, ordering: list.ordering }
      )
    );
  }, [dispatch, pagination.count,
    pagination?.page_size,
    pagination?.page_number,
    search, ordering,
    // filter.params,
    pagination?.num_pages,
    list.ordering
  ]);
  

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  
  const createFlow = (rowData) => {
    const { agency_name, agency_logo, answers,user_id,agency_id } = rowData;
    dispatch(createFlowAction({ agency_name, agency_logo, answers ,user_id,agency_id}));
  };
  

  
  return (
    <div className={className}>
      <Table
        title={"Flozy Support"}
        headers={list?.headers}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        isSort
        //isFilter
        onEdit={createFlow}
        isSearch={true}
      />
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

export default PageSupport;

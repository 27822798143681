import theme from "../../../theme";

const Style = () => ({
    '.userGroupContainer': {
        padding: '15px 24px',
        borderRadius: '12px',
        background: theme.palette.containers.card,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.06)',
        '& .MuiDivider-root': {
            borderColor: theme.palette.grey.border
        }
    },
    
    // '.accessTable': {
    //     border: `1px solid ${theme.palette.grey.border}`,
    //     borderRadius: '12px',
    //     '& .cell':{
    //         padding: '12px'
    //     },
    //     '& .emptySpace': {
    //         background: theme.palette.containers.body,
    //         height: '23px',
    //         borderTop: `1px solid ${theme.palette.grey.border}`,
    //         borderBottom: `1px solid ${theme.palette.grey.border}`
    //     },
    //     '& .switches': {
    //         '& .MuiFormControlLabel-root': {
    //             margin: '0px !important'
    //         },
    //         '& .MuiSwitch-root': {
    //             margin: '0px !important'
    //         }
    //     },
    //     '& .br-rt': {borderRight: `1px solid ${theme.palette.grey.border}`},
    //     '& .br-bt': {borderBottom: `1px solid ${theme.palette.grey.border}`},
    //     '& .br-tp': {borderTop: `1px solid ${theme.palette.grey.border}`},
    //     '& .br-lt': {borderLeft: `1px solid ${theme.palette.grey.border}`},
    // },
    // '.userItem': {
    //     background: theme.palette.containers.greyBg5,
    //     borderRadius: '12px',
    //     boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.08)',
    //     padding: '8px 14px',
    //     position: 'relative',
    //     '& .closeIcon': {
    //         position: 'absolute',
    //         right: '5px',
    //         top: '5px',
    //         width: '20px',
    //         width: '20px'
    //     }
    // },
    '.breadcrumb': {
        marginBottom: '20px',
        '& .MuiButton-contanedPrimary': {
          background: theme.palette.primary.main,
          color: '#fff',
          fontWeight: '600'
        }
      },
      '.activityItem': {
        borderRight: `1px solid ${theme.palette.grey.border}`,
        paddingRight: '24px',
        '&:last-child': {
            borderRight: 'none'
        }
      },
      '.otherAgenciesHeader': {
        borderBottom: `1px solid ${theme.palette.grey.border}50`,
        marginBottom: '10px',
        paddingBottom: '10px'
      },
      '.otherAgenciesItem': {
        padding: '8px 0px',
        borderBottom: `1px solid ${theme.palette.grey.border}50`,
      },
      " .resetpopup": {
        height: '24px !important',  // Adding !important to ensure it takes precedence
        width: '30px !important',
        "& .css-hz1bth-MuiDialog-container" : {
          backgroundColor:"red",
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper" :{
            height: '24px !important',  // Adding !important to ensure it takes precedence
            width: '30px !important',
          }
        }
      },
      ".Password_Reset":{
        paddingTop:'30px',
        paddingLeft:"25px",
      },
      ".MuiOutlinedInput-root":{
        height:"42px",
        size:"12px",
        borderRadius:"7px",
      },
      ".MuiOutlinedInput-input":{
        paddingRight:"5px"
      },
      ".reset_Button":{
        width:"130px",
        height:"40px",
        borderRadius:"7px",
        size:"12px",
        fontWeight:"600px",
        padding:"0px !important"
      },
      ".headerText":{
        ".MuiOutlinedInput-root":{
          paddingRight:"0px"
        }
      }
     
})

export default Style;
import React, { useEffect, useState } from 'react';
import createFormStyles from './createFeatureFormStyles';
import { Grid, Typography, styled } from '@mui/material';
import FormHeader from '../CreateAddonHeader/formHeader';
import CustomInput from "../../../../components/TextField/index";
import LogoInput from "../../../../components/LogoInput";
import RichTextAreaComponent from "../../../../components/TextArea/index";
import CustomSelect from "../../../../components/InputSelect";
import { useDispatch, useSelector } from 'react-redux';
import { createfeatureAsync, getFeatureByIdAsync, updateFeatureByIdAsync } from "../../../../store/actions/featureAction";
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import createFeatureSchema from '../validation/createFeatureValidation';
import Comments from '../Comments/comments';
import { useParams, useLocation } from 'react-router-dom';

const CreateFeatureForm = (props) => {
    const dispatch = useDispatch();
    const { className } = props;

    const [imageType, setImageType] = useState(null);
    const [openTier, setOpenTier] = useState(false);
    const [error, setError] = useState("");
    const { id } = useParams();
    const location = useLocation();

    const { featureById } = useSelector((state) => state.feature);
    const {feature_name,feature_image_url,} = featureById;
    const [logo, setLogo] = useState(feature_image_url);
    
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(createFeatureSchema),
        
    });

    useEffect(()=>{
        const {feature_name,feature_description,billing_cycle,feature_image_url,price,status,feature_flag} = featureById;
        if(featureById){
            setValue("feature_name",feature_name)
            setValue("description",feature_description)
            setValue("price",price)
            setValue("billing_cycle",billing_cycle)
            setValue("feature_status",status)
            setValue("feature_flag",feature_flag)
            setLogo(feature_image_url)
        }
    },[featureById])
 
    const onSubmit = (data) => {
    
        if(id){
       
            dispatch(updateFeatureByIdAsync({id,data:{...data,...imageType,feature_image_url}}))
           
        }else{
            dispatch(createfeatureAsync({...data,...imageType}))
        }
    };

    useEffect(() => {
        if (id) {
            dispatch(getFeatureByIdAsync(id));
        }
    }, [id]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 1024 * 1024) { // Check if file size exceeds 1MB
                setError('File size should be less than 1MB');
                return;
            }
            setError(''); // Clear previous error messages
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result;
                const imageMimeType = file.type;
                setLogo(e.target.result);
                setImageType({
                    base64Image: base64String?.split(',')[1],
                    imageMimeType: imageMimeType,
                    imageName: "image"
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const statusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Paused' },
        { value: '2', label: 'Disabled' },
    ];

    const billingCycle = [
        { value: '1', label: 'Day' },
        { value: '2', label: 'Week' },
        { value: '3', label: 'Month' },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`${className} createFeatureForm`}>
            <Grid>
                <FormHeader title={'Feature'} error={error} />
            </Grid>
            <Grid className='form_container'>
                <Grid className='form_container_right'>
                    <Grid className='form_container_right_sub'>
                        <Grid>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Feature Name</Typography>
                            <Controller
                                name="feature_name"
                                control={control}
                                render={({ field }) => <CustomInput {...field} defaultValue={feature_name} />}
                            />
                            {errors.feature_name && <span className='error_text'>{errors.feature_name.message}</span>}
                        </Grid>
                        <Grid>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Feature Image</Typography>
                            <Controller
                                name="productImage"
                                control={control}
                                render={({ field }) => <LogoInput {...field} onChange={handleImageChange} logo={logo} />}
                            />
                        </Grid>
                        <Grid>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Description</Typography>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => <RichTextAreaComponent {...field} defaultValue={featureById?.feature_description} />}
                            />
                            {errors.description && <span className='error_text'>{errors.description.message}</span>}
                        </Grid>
                    </Grid>
                    <Grid className='tier_container'>
                        <Typography>Billing Cycle</Typography>
                        <Controller
                            name="billing_cycle"
                            control={control}
                            render={({ field }) => <CustomSelect {...field} options={billingCycle}  defaultValue={featureById?.billing_cycle}/>}
                        />
                        {errors.billing_cycle && <span className='error_text'>{errors.billing_cycle.message}</span>}
                    </Grid>
                    <Grid className='form_container_right_sub'>
                        <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Add Comments</Typography>
                        <Controller
                            name="comments"
                            control={control}
                            render={({ field }) => <CustomInput {...field} />}
                        />
                        {errors.comments && <span className='error_text'>{errors.comments.message}</span>}
                        <Comments commentsData={featureById} />
                    </Grid>
                </Grid>
                <Grid className='main_form_container_left'>
                    <Grid className='form_container_left'>
                        <Typography variant='h4' style={{ fontWeight: "600", fontSize: "16px" }}>Primary Details</Typography>
                        <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography variant='h5'>Feature Status</Typography>
                            <Controller
                                name="feature_status"
                                control={control}
                                render={({ field }) => <CustomSelect {...field} options={statusOptions} defaultValue={featureById?.status}/>}
                            />
                            {errors.feature_status && <span className='error_text'>{errors.feature_status.message}</span>}
                            <Typography variant='h4' style={{ fontWeight: "600", fontSize: "16px" }}>Product Pricing Details</Typography>
                        <Grid style={{ display: "flex", flexDirection: "column", gap: "7px" }}>
                            <Typography variant='h5'>Current Price</Typography>
                            <Controller
                                name="price"
                                control={control}
                                render={({ field }) => <CustomInput {...field} defaultValue={featureById?.status} />}
                            />
                            {errors.price && <span className='error_text'>{errors.price.message}</span>}
                        </Grid>
                        <Grid>

                       
                        <Typography variant='h5'>Feature Flag</Typography>
                            <Controller
                                name="feature_flag"
                                control={control}
                                render={({ field }) => <CustomInput {...field} defaultValue={featureById?.feature_flag} />}
                            />
                            {errors.feature_flag && <span className='error_text'>{errors.feature_flag.message}</span>}
                            </Grid>
                            {/* <Typography variant='h5'>Plan Code</Typography>
                            <Controller
                                name="plan_code"
                                control={control}
                                render={({ field }) => <CustomSelect {...field} options={planCodeOptions} />}
                            />
                            {errors.plan_code && <span className='error_text'>{errors.plan_code.message}</span>} */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </form>
    );
};

export default styled(CreateFeatureForm)(createFormStyles);

import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import PrivateLayout from "../layouts/PrivateLayout";
import { useSelector } from "react-redux";
export const ProtectedRoute = ({ children }) => {
  
    const { accessToken } = useSelector((state) => state.auth);
    if (!accessToken) {
        return <Navigate to="/auth/login" />;
    }

    return (
        <PrivateLayout>
            {/* <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }} >Back</div> */}
            <Outlet />
        </PrivateLayout>
    );
};
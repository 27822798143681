import React from 'react';
import PropTypes from 'prop-types';
import { Button, styled } from '@mui/material';
import style from './style';

const Action = ({ rowData, type, onEdit }) => {
  const handleClick = () => {
    if (onEdit) {
      onEdit(rowData);
    }
  };

  return (
    <>
      {type === 'view' && (
        <Button onClick={handleClick} className={'moreOption'}>
          View
        </Button>
      )}
      {type === 'create' && (
        <Button onClick={handleClick} className={'moreOption'}>
          Migration
        </Button>
      )}
    </>
  );
};

Action.propTypes = {
  rowData: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['view', 'create']).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default styled(Action)(style);

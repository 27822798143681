import React from 'react'
import { Grid,IconButton,styled, } from '@mui/material';
import userTilesStyles from "./userTilesStyle"
import { CloseIcon } from '../../../../../assets/svg';
const UserTiles = (props) => {

    const { className ,username,role,id,onDelete} = props;
  return (
    <Grid className={`${className}`}>
        <div className='user_image'>
           {username[0].toUpperCase()}
        </div>
        <div className='user_details'>
            <div className='username'>{username}</div>
            <div className='role'>{role}</div>
        </div>
        <div className='close_icon' >
                <IconButton onClick={() => onDelete(id)}>
                <CloseIcon />
                </IconButton>
            </div>
    </Grid>
  )
}

export default styled(UserTiles)(userTilesStyles)
import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Button, Typography, Grid,TextField,Select} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Modal from '../../../../../components/Modal';
import RichTextAreaComponent from "../../../../../components/TextArea/index" // Adjust the import path as needed
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';
import {cancelSubscriptionAsync,refundSubscriptionAsync} from "../../../../../store/actions/subscriptonsAction"
import { useParams } from 'react-router-dom';
const BillingAction = ({ rowData,onView }) => {
  const dispatch = useDispatch()
  const { agency_id} = useParams()
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [actionState, setActionState] = useState("");
  const [selectedValue, setSelectedValue] = useState('a');
  const [customDate, setCustomDate] = useState('');
  const [notes,setNotes] =  useState('')
  const [refundAmount, setRefundAmount] = useState(rowData.amount_paid);
  const [selectedReason, setSelectedReason] = useState('requested_by_customer');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleDateChange = (event) => {
    setCustomDate(event.target.value);
  };

  const handleNotesChange = (event) =>{
    setNotes(event.target.value)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleRefundAmountChange = (event) => {
    const newValue = parseFloat(event.target.value);
    if (newValue <= rowData.amount_paid && newValue >= 0) {
      setRefundAmount(newValue);
    }
  };
  
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleAction = (action) => {
    handleClose();
    setActionState(action);
    if (action === 'view') {
      onView(rowData);
     
    }
    else if (action === 'download') {
      // Trigger download of the invoice PDF
      const link = document.createElement('a');
      link.href = rowData.invoice_pdf; // Get the PDF URL from rowData
      link.target = '_self'; // Open in the same tab
      link.download = `Invoice_${rowData.invoice_id}.pdf`; // Suggest a filename
      document.body.appendChild(link); // Append to the DOM
      link.click(); // Programmatically click the link to trigger download
      document.body.removeChild(link); 
     }
      else {
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmAction = () => {
    const data = {
    subscription_id: rowData.subscriptionId, 
    amount: refundAmount, 
    cancellationReason: selectedReason,  
    invoice_id: rowData.invoice_id,  
    agency_id: agency_id.toString(),
    mode:selectedValue,
    date:customDate 
    }
    
    if (actionState === 'cancel') {
      dispatch(cancelSubscriptionAsync(data))
    } else if (actionState === 'refund') {
      dispatch(refundSubscriptionAsync(data))
    }
    setOpenDialog(false);
  };

  const getDialogContent = () => {
  
    if (actionState === 'cancel') {
      return (
        <>
        <Grid style={{width:"100%"}}>
     
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value="immediate"
            control={<Radio />}
            label={<Typography sx={{ color: selectedValue === 'immediate' ? 'black' : 'grey' }}>Immediate</Typography>}
          />
          <FormControlLabel
            value="endOfPeriod"
            control={<Radio />}
            label={<Typography sx={{ color: selectedValue === 'endOfPeriod' ? 'black' : 'grey' }}>End of Current Period</Typography>}
          />
          <FormControlLabel
            value="customDate"
            control={<Radio />}
            label={<Typography sx={{ color: selectedValue === 'customDate' ? 'black' : 'grey' }}>Custom Date</Typography>}
          />
        </RadioGroup>
        {selectedValue === 'customDate' && (
          <TextField
            type="date"
            value={customDate}
            onChange={handleDateChange}
          />
        )}

      <Typography variant='h6' style={{ marginBottom: "10px" }}>Notes for cancellation</Typography>
      <Grid style={{width:"100%"}}>

      <RichTextAreaComponent  onChange={handleNotesChange} />
      </Grid>

        </Grid>
        </>
      );
    } else if (actionState === 'refund') {
      return (
        <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Grid style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Typography style={{ fontSize: "14px", fontWeight: "500", width: "10%" }}>Refund</Typography>
          <Typography style={{ width: "100%", color: "#778599" }}>
            <TextField
              type="number"
              style={{ width: '100%',}}
              InputProps={{
                style: { color: '#778599',
                height: "40px",
                borderRadius: "10px",
                }, 
              }}
              value={refundAmount}
              onChange={handleRefundAmountChange}
            />
          </Typography>



        </Grid>
        {/* <Grid style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Typography style={{ fontSize: "14px", fontWeight: "500", width: "10%" }}>Email</Typography>
          <Typography style={{ width: "100%", color: "#778599" }}>
            <TextField
              type="number"
              style={{ width: '100%',}}
              InputProps={{ style: { color: '#778599',
                height: "40px",
                borderRadius: "10px",
                }, 
              }}
              onChange={handleRefundAmountChange}
            />
          </Typography>

        </Grid> */}
        <Grid style={{ display: "flex", gap: "20px" ,alignItems:"center"}}>
          <Typography style={{ width: "10%" }}> Reason</Typography>
          <Typography style={{ width: "100%", }}>
            <Select
              labelId="reason-select-label"
              value={selectedReason}
              onChange={handleReasonChange}
              label="Select Reason"
              style={{width:"100%"}}
            >
              <MenuItem value="duplicate">Duplicate</MenuItem>
              <MenuItem value="fraudulent">Fraudulent</MenuItem>
              <MenuItem value="expired_uncaptured_charge">Expired Uncaptured Charge</MenuItem>
              <MenuItem value="requested_by_customer">Requested by Customer</MenuItem>
            </Select>
             </Typography>
        </Grid>

      </Grid>

      );
    }
    return null;
  };

  const getDialogActions = () => {
    if (actionState === 'cancel' || actionState === 'refund') {
      return (
        <>
            <Button onClick={handleDialogClose} color="secondary" variant='outlined'>
            {actionState === 'cancel' ? 'Don’t Cancel' : 'Cancel'}
          </Button>
          <Button onClick={handleConfirmAction} color="primary" variant='contained'>
            {actionState === 'cancel' ? 'Yes Cancel' : 'Refund'}
          </Button>
        </>
      );
    }
    return null;
  };
  
  return (
    <div>
      
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => handleAction('view')}>View</MenuItem> */}
        {/* <MenuItem onClick={() => handleAction('cancel')}>Cancel</MenuItem> */}
        <MenuItem onClick={() => handleAction('refund')}>Refund</MenuItem>
        <MenuItem onClick={() => handleAction('download')}>Download</MenuItem>
      </Menu>

      <Modal
        open={openDialog}
        handleClose={handleDialogClose}
        title={actionState === 'cancel' ? 'Cancel Subscription' : 'Refund Subscription'}
        actions={getDialogActions()}
      >
        <Grid style={{ gap: "10px", width: "100%" }}>
          {getDialogContent()}
        </Grid>
      </Modal>
    </div>
  );
};

export default BillingAction;

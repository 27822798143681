import React, {useState} from 'react'
import { Button} from "@mui/material";
import {Grid} from "@mui/material";
  import Modal from '../../../../components/Modal';
import { DeleteIcon } from '../../../../assets/svg';
import { useDispatch } from 'react-redux';
import { deleteAgencyAddonsAsync } from "../../../../store/actions/agencyAddonsAction";

const DeleteAgencyAddons= (props) => {
  const {rowData} = props
  const dispatch = useDispatch();
   const [open,setOpen] =  useState(false);
   const popUpHandler = ()=>{
    setOpen(!open)
  }
  const deleteFeatureFlag = ()=>{
    dispatch(deleteAgencyAddonsAsync(rowData.id))
  }
  return (
    <div>
    <div onClick={popUpHandler} style={{cursor:"pointer"}} ><DeleteIcon/></div>
    <Modal
        open={open}
        handleClose={popUpHandler}
        title="Agency Feature Mapping Delete"
        actions={
          <>
            <Button onClick={popUpHandler} color="secondary" variant='outlined'>
              Cancel
            </Button>
            <Button onClick={()=>deleteFeatureFlag()} color="primary" variant='contained'>
            Delete
            </Button>
          </>
        }
      >
        <Grid style={{display:"flex",gap:"10px",width:"100%"}}>
         Are you sure want to delete this feature flag
        </Grid>
      </Modal>
    </div>
  )
}

export default DeleteAgencyAddons

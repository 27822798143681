

import { createSlice } from "@reduxjs/toolkit";
import { getCommissionsAsync } from "../actions/commissionsAction";

const initialState = {
    list: { 
        data: [],
        headers: [
            { field: "agency_name", label: "Affiliate" },
            { field: "referral_code", label: "Link Name" },
            { field: "customer_name", label: "Customer Name" },
            { field: "No_of_Clicks", label: "No of Clicks" },
            { field: "plan_price", label: "Plan Price" },
            { field: "date", label: "Date" },
            { field: "status", label: "Status" },
            { field: "commission_amount", label: "Commission" },
            { field: "due_date", label: "Due Date" },
        ],
        pagination: {
            count: 0,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        ordering: "modified_on",
    },
    loading: false,
    error: null,
};

const commissionsSlice = createSlice({
    name: "commissions",
    initialState,
    reducers: {
        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1; 
            state.list.search = action.payload;
        },
        setOrdering(state, action) {
            state.list.ordering = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCommissionsAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCommissionsAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.list.results = action.payload.data || [];
                state.list.pagination = {
                    count: action.payload.count || 0,
                    next: action.payload.next || null,
                    previous: action.payload.previous || null,
                    page_size: action.payload.page?.limit || state.list.pagination.page_size,
                    num_pages: action.payload.page?.num_pages || 0,
                    page_number: action.payload.page?.skip / (state.list.pagination.page_size || 10) + 1 || 1,
                };
            })
            .addCase(getCommissionsAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const {
    setPageSize,
    setPage,
    setSearch,
    setOrdering,
} = commissionsSlice.actions;

export default commissionsSlice.reducer;


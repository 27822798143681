import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Grid, TextField, Select, MenuItem, ListItemIcon, Chip, InputAdornment, Box } from '@mui/material';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import { createApplicationModuleAsync, getAllApplicationModulesAsync } from '../../store/actions/applicationModuleAction';
import { createsupportFaqAsync, getAllFaqsAsync, updateSupportFaqAsync } from '../../store/actions/faqAction';
import {
  setPageSize,
  setPage,
  setSearch,
  setFilters,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  applyFilter,
  clearFilter,
} from "../../store/reducers/faqReducer";
import { ActionCell } from '../../components/MoreAction/MoreAction';
import RichTextAreaComponent from '../../components/TextArea';
import { rulesToParams } from '../../components/Filter/filterUtil';

const FaqSupport = (props) => {
  const { className } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [tags, setTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSizeState] = useState(10);
  const [selectedFilters, setSelectedFilters] = useState({
    mainModule: [],
  });
  const [editId, setEditId] = useState(null); 
  const [modalType, setModalType] = useState('create');
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const { list } = useSelector((state) => state.faq);
  const { list: applicationModules } = useSelector((state) => state.applicationModule);
  const { pagination, ordering, orderBy, filter ,faqs } = list;
  const search = list.search
  useEffect(() => {
    dispatch(getAllApplicationModulesAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllFaqsAsync({ ...pagination,search}));
  }, [dispatch, currentPage, pageSize, searchQuery,pagination?.pageSize ]);

  const handleSave = () => {
    const faqData = {
      id: editId,
      question_type: question,
      answer,
      module_name: selectedModule?.module_name,
      icon: selectedModule?.icon,
      tags,
      created_by: userId,
      application_module_id: selectedModule?.id

    };
    if (modalType === 'edit' && editId) {

      dispatch(updateSupportFaqAsync({ id: editId, data: faqData }));
    } else {
    
      dispatch(createsupportFaqAsync(faqData));
    }
    popUpHandler();
    setModalOpen(false);
  };

  const popUpHandler = () => {
    setOpen(!open);
  };

  const answerPopUpHandler = () => {
    setOpenAnswer(!openAnswer);
  };

  const handleSearch = (searchVal) => {
    setSearchQuery(searchVal);
    dispatch(setSearch(searchVal));
    setCurrentPage(1);
  };

  const handlePagination = (event, pageValue) => {
    setCurrentPage(pageValue);
    dispatch(setPage(pageValue));
  };

  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    setPageSizeState(newSize);
    dispatch(setPageSize(newSize));
    setCurrentPage(1);
  };

  const modules = applicationModules.results || [];
  const [tagInput, setTagInput] = useState('');

  const handleTagAdd = (event) => {
    if (event.key === 'Enter' && tagInput.trim() !== '') {
      setTags((prevTags) => [...prevTags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const [modalData, setModalData] = useState(null);
  const handleGetdata = (data) => {
    setOpenAnswer(true);
    setModalData(data);
  };

  const filters = [
    {
      key: "mainModule",
      label: "Module",
      options: [
        { value: 'task', label: "Tasks" },
        { value: 'Client', label: "Clients" },
        { value: 'Chat', label: "Chat" },
        { value: 'Booking', label: "Booking" },
        { value: 'Brain', label: "Brain" },
        { value: 'Flow', label: "Flow" },
        { value: 'Brain', label: "Brain" },
        { value: 'invoice', label: "Invoice" },
        { value: 'pages', label: "Page builder" },
        { value: 'Billing Invoice', label: "Billing Invoice" },
        { value: 'Others', label: "Others" },
        { value: 'Feature Request', label: "Feature Request" },
        
      ],
      type: "checkbox"
    },
  ];



  const applyFilter = () => {
    dispatch(
      getAllFaqsAsync({
        ...pagination, searchQuery, filters: {
          mainModule: selectedFilters.mainModule
        }
      })
    );
  };
  const onFilter = (reason, data) => {
    switch (reason) {
      case "add":
        // dispatch(addFilterRule());
        break;
      case "remove":
        // dispatch(removeFilterRule(data));
        break;
      case "update":
        // dispatch(updateFilterRule(data));
        break;
      case "apply":
        dispatch(applyFilter(rulesToParams(filter.rules)));
        break;
      case "clear":
        dispatch(clearFilter());
        break;
      default:
        return;
    }
  };
  const cellMap = {

    action: ActionCell,
  };
  

  const onEdit = (data) => {
    setQuestion(data.question_type);
    setAnswer(data.answer);
    setSelectedModule({
      id: data.mainModule.id,
      icon: data.mainModule.icon,
      module_name: data.mainModule.module_name,

    });
    setTags(data.tags);
    setOpen(true);
    setEditId(data.id);
    setModalType('edit');
    setModalOpen(true);
  };


  return (
    <Grid className={className}>
      <Table
        title={'FAQ'}
        popUp={'+ FAQ'}
        data={list?.results}
        popUpHandler={popUpHandler}
        isFaqView={true}
        isSearch={true}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSizeChange}
        handleSearch={handleSearch}
        openMorePreviewPopup={handleGetdata}
        isFilter
        filter={filters}
        onFilter={onFilter}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filterOptions={filters}
        applyFilter={applyFilter}
        onEdit={onEdit}
        cellMap={cellMap}
      />
      <Modal
        open={open}
        handleClose={popUpHandler}
        title="FAQ"
        style={{ width: '100%' }}
        actions={
          <>
            <Button color="secondary" onClick={popUpHandler}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave}>
              {modalType === 'edit' ? 'Update' : 'Save'}
            </Button>
          </>
        }
      >
        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
          <div className="dflex" style={{ flexDirection: 'column', gap: "10px" }}>
            <Typography variant="h5">Questions?</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
          <div style={{ flexDirection: 'column', gap: "10px" }}>
            <Typography variant="h5">Answer</Typography>
            <RichTextAreaComponent
              fullWidth
              variant="outlined"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: "10px", width: '100%' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: "10px" }} >
              <Typography variant="h5">Relate</Typography>
              <Select
                fullWidth
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Module</MenuItem>
                {Array.isArray(modules) && modules.map((module) => (
                  <MenuItem key={module.id} value={module}>
                    <ListItemIcon>
                      {module.icon && module.icon.startsWith('<svg') ? (
                        <span dangerouslySetInnerHTML={{ __html: module.icon }} />
                      ) : module.icon ? (
                        <img
                          src={module.icon}
                          alt={module.module_name}
                          style={{ width: '20px', height: '20px', marginRight: '10px' }}
                        />
                      ) : null}
                    </ListItemIcon>
                    {module.module_name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: "10px" }}>
              <Typography variant="h5">Tags</Typography>
              <TextField
                variant="outlined"
                placeholder="Click here to enter tags"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                onKeyPress={handleTagAdd}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', maxWidth: '100%' }}>
                        {tags.map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag}
                            onDelete={() => handleTagDelete(tag)}
                            color="secondary"
                            size="small"
                          />
                        ))}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </Grid>
      </Modal>
      <Modal
        open={openAnswer}
        handleClose={answerPopUpHandler}
        style={{ width: '100%' }}
      >
        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Typography variant="h5">{modalData?.question_type}</Typography>
          <Typography variant="body1">{modalData?.answer}</Typography>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default FaqSupport;

import instance1 from "../../utils/api1";

async function getAllCollabAgencies(data) {
    return instance1.post('collab/getAll', data);
}


export {
    getAllCollabAgencies,
};

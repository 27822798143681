import { setAlert } from "../store/reducers/uiReducer";

export const parseErrorResponses = (errResponse, dispatch) => {
  
  const errStatus = errResponse?.status || errResponse?.code;
  let alert = {};
  switch (errStatus) {
    case "ERR_NETWORK":
      alert = {
        type: "error",
        message: "Network Error",
      };
      break;
    // Invalid Credentials or UnAuthorized
    case 401:
      alert = {
        type: "success",
        message: "Logged out successfully",
      };
      break;
    case 403:
      alert = {
        type: "error",
        message: "Access Denied",
      };
      break;
    default:
  }

  if (dispatch) {
    dispatch(setAlert(alert));
  }
};

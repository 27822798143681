import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import { commissionsService } from "../services/commissionsService";

export const getCommissionsAsync = createAsyncThunk(
    "affiliate/get",
    async (data, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Fetching Agencies..."));
        const response = await commissionsService.getCommissions(data);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );


import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default function AsyncAutocomplete({
  callback,
  inputOptions,
  onChange,
  defaultValue,
  disabled
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const debouncedInput = useDebounce(inputValue, 500);

  useEffect(() => {
    if (debouncedInput && callback) {
      setLoading(true);
      dispatch(callback(debouncedInput));
      setLoading(false);
    }
  }, [debouncedInput, dispatch, callback]);

  const handleSelectionChange = (event, newValue) => {
    setSelectedOptions(newValue);  // Add the new value to the array
    onChange(newValue);  // Trigger onChange callback
  };

  return (
    <Autocomplete
      multiple
      id="async-autocomplete"
      sx={{ width: 270, height: 80 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, value) => setInputValue(value)}
      onChange={handleSelectionChange}
      getOptionLabel={(option) => option?.label || option?.name || ''}
      options={inputOptions}
      loading={loading}
      value={selectedOptions}
      defaultValue={defaultValue}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

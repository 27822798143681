import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, TextField, Button, IconButton, InputAdornment, DialogActions } from '@mui/material';
import { PasswordEyeIcon, PasswordCloseEyeIcon } from '../../assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAuthUsers } from '../../store/actions/authAction';
import { updatePasswordAction } from '../../store/actions/passwordUpdateAction';
import FormHeader from '../Addons/components/CreateAddonHeader/formHeader';

const PasswordSetting = (props) => {
    const { className } = props;
    const dispatch = useDispatch();
    const { list } = useSelector((s) => s.auth);
    const { userId } = useSelector((s) => s.auth);
    const { user, pagination } = list;

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        dispatch(getAllAuthUsers({ ...pagination, search: "" }));
    }, [
        dispatch,
        pagination.page_size,
        pagination.page_number,
    ]);

    const togglePasswordVisibility = (field) => {
        if (field === 'old') setShowOldPassword((prev) => !prev);
        else if (field === 'new') setShowNewPassword((prev) => !prev);
        else if (field === 'confirm') setShowConfirmPassword((prev) => !prev);
    };

    const handleCancel = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    const handleUpdatePassword = () => {
        if (newPassword !== confirmPassword) {
            alert('New password and confirm password do not match.');
            return;
        }

        const data = {
            oldPassword,
            newPassword,
            userId, // Assuming `userId` is available from the auth state.
        };

        dispatch(updatePasswordAction(data));
    };

    return (
        <Grid className={`${className} createFeatureForm`} >
       <Grid className={`${className} createFeatureForm`}>
                <Grid>
                    <FormHeader title={'Password Setting'}
                    isEditing={true}
                    />
                </Grid>
                <Grid 
            className={className} 
            style={{
                backgroundColor: "white", 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                justifyContent: "center", 
                padding: "10%"
            }}
        >
            <Grid item xs={12} style={{ margin: "10px 0", width: "100%" }}>
                <TextField
                    label="Old Password"
                    variant="outlined"
                    type={showOldPassword ? "text" : "password"}
                    fullWidth
                    required
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => togglePasswordVisibility('old')}>
                                    {showOldPassword ? <PasswordEyeIcon /> : <PasswordCloseEyeIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{ margin: "10px 0", width: "100%" }}>
                <TextField
                    label="New Password"
                    variant="outlined"
                    type={showNewPassword ? "text" : "password"}
                    fullWidth
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => togglePasswordVisibility('new')}>
                                    {showNewPassword ? <PasswordEyeIcon /> : <PasswordCloseEyeIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{ margin: "10px 0", width: "100%" }}>
                <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => togglePasswordVisibility('confirm')}>
                                    {showConfirmPassword ? <PasswordEyeIcon /> : <PasswordCloseEyeIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid container justifyContent="flex-end" style={{ width: "100%", marginTop: "20px" }}>
                <DialogActions sx={{ gap: '10px' }}>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        sx={{ color: '#64748B', borderColor: '#BBC4CF' }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button 
                        color="primary" 
                        autoFocus 
                        variant="contained" 
                        onClick={handleUpdatePassword}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Grid>
        </Grid>

            </Grid>

    </Grid>
    );
};

PasswordSetting.defaultProps = {
    className: ''
};

PasswordSetting.propTypes = {
    className: PropTypes.string,
};

export default PasswordSetting;

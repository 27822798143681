import React from "react";
import PropTypes from 'prop-types';
import { Button, Grid, IconButton, styled, Tooltip, Typography } from "@mui/material";

// import Style
import Style from "./style";
import { InfoIcon, MoreIcon } from "../../assets/svg";

const CardComponent = (props) => {

    const { className, height, children, title, titleWeight, actions, titleVariant, infoText, infoIcon, actionBtn, btnText, handleBtn, handleMore, moreBtn } = props;

    return (
        <Grid className={className} sx={{height: height}}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} className="header" sx={{ pb: 2 }}>
                <Typography variant={titleVariant} sx={{ fontWeight: titleWeight }} className="flexAlign">
                    {title}
                    {
                        infoIcon && <Tooltip arrow title={infoText}><IconButton sx={{ p: 0, ml: 1 }}><InfoIcon /></IconButton></Tooltip>
                    }
                </Typography>
                <Grid className="flexAlign">
                    {actions}
                    {actionBtn && <Button variant="outlined" color="secondary" size="small" onClick={handleBtn}>{btnText}</Button>}
                    {moreBtn && <IconButton onClick={handleMore}><MoreIcon /></IconButton>}
                </Grid>
            </Grid>
            <Grid className="body">{children}</Grid>
        </Grid>
    )
}

CardComponent.defaultProps = {
    className: '',
    title: '',
    titleVariant: 'body2',
    titleWeight: '500',
    infoIcon: false,
    btnText: 'View All',
    actionBtn: false,
    moreBtn: false,
    height: '100%'
};

CardComponent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleVariant: PropTypes.string,
    titleWeight: PropTypes.string,
    children: PropTypes.node,
    actions: PropTypes.node,
    infoIcon: PropTypes.bool,
    infoText: PropTypes.string
};

export default styled(CardComponent)(Style);
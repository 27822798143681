import   instance1 from "../../utils/api1";
async function createAddons(data){
    return instance1.post('addons/create',data)
}

async function getAddons(data) {
    return instance1.post('addons/getAll', data)
}

async function deleteAddonsById(param) {
    return instance1.delete(`addons/${param}`)
}

async function getAddonsById(param) {
    if (param) return instance1.get(`addons/${param}`)
}
async function updateAddonsById(id, data) {
    return instance1.put(`addons/${id}`, data);
}
export const addonsService = {
    createAddons,
    getAddons,
    deleteAddonsById,
    getAddonsById,
    updateAddonsById
}
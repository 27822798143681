import React, { useEffect, useState } from 'react';
import { Grid, Typography, styled, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import CustomInput from '../../components/TextField';
import profileStyles from './style';
import LogoInput from '../../components/LogoInput';
import CustomSelect from '../../components/InputSelect';
import { useNavigate, useParams } from 'react-router-dom';
import FormHeader from '../Addons/components/CreateAddonHeader/formHeader';
import { updateUserProfileAction, userProfile, } from '../../store/actions/userProfileAction';

const UserProfile = (props, classes) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { className, } = props;
    const { id } = useParams()
    const { handleSubmit, control, setValue } = useForm();

    useEffect(() => {

        dispatch(userProfile())
    }, [])
    const selectedUser = useSelector((state) => state.profileUser.list.profile);
    const [isEditing, setIsEditing] = useState(false);

    const [imageType, setImageType] = useState(null)

    const [logo, setLogo] = useState(null)
    useEffect(() => {
        if (selectedUser) {
            setValue('name', selectedUser.name || '');
            setValue('email', selectedUser.email || '');
            setValue('department', selectedUser.department || '');
            setValue('phone', selectedUser.phone || '');
            setValue('employee_id', selectedUser.employee_id || '');
            setValue('personal_email', selectedUser.personal_email || '');
            setValue('secondary_phone_number', selectedUser.secondary_phone_number || '');
            setValue('secondary_phone_number', selectedUser.secondary_phone_number || '');
            setValue('secondary_phone_number', selectedUser.secondary_phone_number || '');
            setValue('secondary_phone_number', selectedUser.secondary_phone_number || '');
            setValue('secondary_phone_number', selectedUser.secondary_phone_number || '');

        }
    }, [selectedUser, setValue]);

    const handleImageChange = (e) => {

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result;
                const imageMimeType = file.type;
                setLogo(e.target.result);
                setImageType({
                    base64Image: base64String?.split(',')[1],
                    imageMimeType: imageMimeType
                })
            };
            reader.readAsDataURL(file);
        }

    };
      const toggleEdit = () => setIsEditing((prev) => !prev);

    const onSubmit = (data) => {
        const formattedData = {
            updateData: {
                name: data.name,
                email: data.email,
                department: data.department,
                phone: data.phone,
                employee_id: data.employee_id,
                personal_email: data.personal_email,
                secondary_phone_number: data.secondary_phone_number,
            },
        };
        dispatch(updateUserProfileAction(formattedData))
            .then(() => {
                setIsEditing(false); 
            })
            dispatch(userProfile())
            .catch((err) => {
                console.error("Error updating profile:", err);
            });
    };
    
       
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid className={`${className} createFeatureForm`}>
                <Grid>
                    <FormHeader title={'User Profile'} 
                       isEditing={isEditing}
                       toggleEdit={toggleEdit}
                       cancelAction={() => {
                         setIsEditing(false);
                       }}/>
                </Grid>
                <Grid className='profile_container'>
                    <Grid className='profile_add_details'>
                        <Grid style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                            <Typography variant='h6' className='user_profile pb5'>User Profile</Typography>
                            <Controller
                                name="productImage"
                                control={control}
                                render={({ field }) => <LogoInput {...field} onChange={handleImageChange} logo={logo} />}
                            />
                        </Grid>
                        <Grid style={{ width: "100%" }}>
                            <Grid className='plan-details'>
                                <Grid className='profile-detail' style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>First Name</Typography>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Name" disabled={!isEditing}/>}
                                    />

                                </Grid>
                                <Grid className='profile-detail' style={{ width: "100%", display: "flex", flexDirection: "column", }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Last Name</Typography>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Name" disabled={!isEditing}/>}
                                    />
                                </Grid>

                            </Grid>

                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Workspace Email ID </Typography>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Email" disabled={!isEditing}/>}
                                    />

                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Phone</Typography>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" disabled={!isEditing}/>}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Designation</Typography>
                                    <Controller
                                        name="department"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Designation" disabled={!isEditing}/>}
                                    />
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Employee ID</Typography>
                                    <Controller
                                        name="employee_id"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Employee ID" disabled={!isEditing}/>}
                                    />
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid className='profile-end'></Grid>

                    <Grid className='profile_description'>
                        <Grid className='licence-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>User Role</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>Can edit and select the user role group for the above user, also custom the role access. </Typography>

                        </Grid>
                        <Grid className='profile-detail' style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <Controller
                                name="employee_id"
                                control={control}
                                render={({ field }) => <CustomInput {...field} placeholder="Enter Employee ID" disabled={!isEditing}/>}
                            />
                        </Grid>
                        <Grid style={{ opacity: 0 }}>

                        </Grid>
                    </Grid>

                    <Grid className='profile-end'></Grid>
                    <Grid className='pricing_details'>
                        <Grid className='profile-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>User Personal Info</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>The above user was added by the following person into the sales dashboard. </Typography>

                        </Grid>
                        <Grid style={{ width: "100%" }}>
                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Personal Email</Typography>
                                    <Controller
                                        name="personal_email"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Personal Email" disabled={!isEditing}/>}
                                    />
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Secondary Phone</Typography>
                                    <Controller
                                        name="secondary_phone_number"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Secondary Phone" disabled={!isEditing}/>}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </form>
    );
};

export default styled(UserProfile)(profileStyles);

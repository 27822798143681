import React from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AlertSuccessIcon,AlertFailedIcon } from "../../assets/svg";
const Toast = ({ message, type, handleClose }) => {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return message !== null && (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right",borderRadius: '6px',}}
        open={message !== null}
         autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
        
      >
        <Alert
          onClose={handleClose}
          icon={type==='error'?<AlertFailedIcon/>:<AlertSuccessIcon/>}
          severity={type || "success"}
          sx={{
            width: "100%",
            background: type === 'error'
              ? "linear-gradient(90deg, red 5%, white 5%)"
              : "linear-gradient(90deg, green 5%, white 5%)",
            height: "51px",
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '6px',
          }}
                  >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Toast;

import React from "react";
import Home from "../containers/Home/index";
import { ProtectedRoute } from "../hoc/ProtectedRoute";

const homeRoutes = [
    {
        path: 'home',
        element: <ProtectedRoute />,
        children: [
            {
                path: '',
                element: <Home />
            }
        ]
    }
]

export default homeRoutes

const userFormStyles = (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    ".border_bottom": {
        border: "1px solid #E9E9E9"
    },
    ".form_container": {
        // height: "1652px",
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 0px 4px 0px #0000000F",
        padding: "25px"
    },
    ".container_1": {
        display: "flex",
        justifyContent: "space-between",
        flex: '1 2',
        gap: "30px",
        height: "190px",
        // position:"relative"
    },
    ".container_1_description": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        wordWrap: "break-word",
        flex: "1"
    },
    ".container_1_form_main": {
        flex: "2",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        gap: "20px"
    },
    ".container_1_form": {
        //  flex:"2",
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        alignItems: "center"
    },
    ".container_1_form_sub": {
        display: "flex",
        justifyContent: "space-between",
        gap: "25px",

    },
    ".form_div": {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    ".form_btn": {
    
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        alignItems:"end",
        paddingBottom:"25px"
        /* Center the buttons horizontally */
    },
    ".text": {
        color: "#73757A"
    },
    ".container_2": {
        flex: '1 2',
        display: "flex",
        justifyContent: "space-between",
        position:"relative",
        gap: "30px",
        padding:"20px 0px",

    },
    ".description_con_2": {
        flex:"1",
        flexDirection:"column",
        gap:"10px",
        wordWrap: "break-word",
    },
    ".access_details":{
        flex:"2",
        // display:"flex",
        // flexDirection:"column",
        gap: "10px",
    },
    ".butn_con_2":{
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        alignItems:"end",
        paddingBottom:"0px"
    },
    ".tiles_con":{
        paddingTop:"10px",
        flex:"2.5",
         display:"flex",
         flexWrap:"wrap",
         width:"600px",
         gap:"10px",
        //  maxHeight:"150px",
        //  height:"130px",
         overflowY:"scroll",

    },
    ".username":{
        width:"100%"
    },
    ".username_inp":{
        width:"400px"
    },
    ".module_con":{
        flex: '1 2',
        display: "flex",
        justifyContent: "space-between",
        // position:"relative",
        gap: "25px",
        padding:"20px 0px",
    },
    ".description_module_con":{
        flex:"0.68",
        flexDirection:"column",
        gap:"10px",
        wordWrap: "break-word",
    },
    ".module_table":{
        flex:"2",
    },
    ".button_action":{
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        // alignItems:"end",
        paddingTop:"20px"
    }
})
export default userFormStyles
// DialogStyles.jsx
import styled from 'styled-components';
import { Dialog, DialogTitle,Grid, Typography, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const StyledDialog = styled(Dialog)
`&& {
    .MuiPaper-root {
      width: 507px;
      height: 250px;
      border-radius: 10px;
    }
  }
`;

export const StyledCloseIconButton = styled(IconButton)`
  && {
    padding: 16px 20px;
  }
`;

export const StyledGrid = styled(Grid)
` && {
     display:flex;
     justify-content:space-between;
     height:50px;
}
`



export const StyledTitle = styled(DialogTitle)`
  && {
    font-family: 'Inter', sans-serif; 
    font-weight: 600; 
    font-size: 16px; 
    color: #0F172A; 
  }
`;

export const StyledTypograhy = styled(Typography)`
&& { 
      text-align:center;
      font-weight:300;
      font-size:14px;
      color:#778599;
      padding:5px 20px 8px 24px
}`



export const StyledTextfield = styled(TextField)`
  && {
    width: 459px;
    height: 40px;
    border-radius: 7px;
    
    .MuiOutlinedInput-root {
      height: 40px;
      border-radius: 7px;
      
      fieldset {
        border: 1px solid #64748B; 
      }

      &:hover fieldset {
        border: 1px solid #64748B;
      }

      &:focused fieldset {
        border: 1px solid #64748B; 
      }
    }
  }
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, styled } from '@mui/material';

import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useDispatch, useSelector } from 'react-redux';
import { getCommissionsAsync } from '../../../store/actions/commissionsAction';
import createFeatureSchema from '../../Feature/components/validation/createFeatureValidation';
import Table from '../../../components/Table';
import { setPage, setPageSize, setSearch } from '../../../store/reducers/commissionsReducer';
const Commission = (props) => {
    const { className } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { loading, list } = useSelector((state) => state.commissions);
    const { pagination, ordering} = list;
    const search = list.search || queryParams.get('search') || '';
    const handleSearch = (searchVal) => {
      dispatch(setSearch(searchVal));
    };
    
    const {
        control,
        formState: { errors }
    } = useForm({
        resolver: zodResolver(createFeatureSchema)
    });

    const featureTableHeader = [
        { field: "agency_name", label: "Affiliate" },  
        { field: "referral_code", label: "Link Name" },
        { field: "customer_name", label: "Customer Name" },
        { field: "No_of_Clicks", label: "link name" },
        { field: "plan_price", label: "Plan Price" },
        { field: "date", label: "Date" },
        { field: "status", label: "Status" },
        { field: "commission_amount", label: "Commission" },
        { field: "due_date", label: "Due Date" }
    ];

    const StatusCell = ({ item }) => {
        const backgroundColor = item === 1 ? "#D4F6D2" : "#FFDEDE";
        const textColor = item === 1 ? "#0E8E2A" : "#FF3300";
        return (
            <span style={{
                display: 'inline-block',
                width: '100px',
                textAlign: 'center',
                color: textColor,
                backgroundColor: backgroundColor,
                borderRadius: '6px',
                padding: '8px 10px'
            }}>
                {item === 1 ? "Active" : "Inactive"}
            </span>
        );
    };

    const cellMap = {
        status: StatusCell,
        No_of_Clicks: ({ item }) => <span style={{ textAlign: 'center' }}>{item}</span>,
        commission_amount: ({ item }) => <span style={{ textAlign: 'center' }}>{item} {list.data[0]?.currency_symbol}</span>, 
        date: ({ item }) => <span style={{ textAlign: 'center' }}>{new Date(item).toLocaleDateString()}</span>, 
        plan_price: ({ item }) => <span style={{ textAlign: 'center' }}>${item}</span> 
    };

    const handlePageSize = (e) => {
      dispatch(setPageSize(e.target.value));
    };
  
    const handlePagination = (e, pageValue) => {
      dispatch(setPage(pageValue));
    };
  
   
    useEffect(() => {
      let keysearch = "";
      let keyfilter = "";
      if (search) {
        keysearch = `&search=${search}`;
      }
      dispatch(
        getCommissionsAsync(
          { ...pagination, search,  ordering: list.ordering }
        )
      );
  
    }, [dispatch, pagination.count,
      pagination?.page_size,
      pagination?.page_number,
      search, ordering,
      pagination?.num_pages,
      list.ordering
    ]);


    return (
        <Grid className={`${className} createFeatureForm`}>
            <Table
                title={"Commission"}
                headers={featureTableHeader}
                createUrl={"/affiliate/commissions"}
                data={list?.results?.data || []}
                cellMap={cellMap} 
                isSearch={true}
                search={search}
                handleSearch={handleSearch}

        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
            />
            <div>{loading ? "Loading..." : ""}</div>
        </Grid>
    );
};

// default props
Commission.defaultProps = {
    classes: {}
};

// prop types
Commission.propTypes = {
    classes: PropTypes.object
};

export default styled(Commission)();





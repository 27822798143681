import {Grid,styled,}from "@mui/material";
import Style from './style';
import Table from '../../components/Table';
import { useNavigate } from 'react-router-dom';
import {ActionCell} from "../../components/MoreAction/MoreAction"
import { useEffect } from "react";
import { useDispatch,useSelector } from 'react-redux';
import {getAllUserGroup} from "../../store/actions/authUserGroupActions"
import SwitchComponent from '../../components/Switch';
import { setPage,setPageSize } from "../../store/reducers/authUserGroup";
import { Search } from "@mui/icons-material";
const UserManagement = (props) => {
const navigate = useNavigate()
const dispatch = useDispatch();
const {authUserGroup,pagination} = useSelector((s)=>s.authUserGroup);

  const userManagementHeader = [
    { field: "group_name", label: "Group Name" },
    { field: "description", label: "description" },
    // { field: "tags", label: "Tags" },
    { field: "action", label: "Action" },

  ];

 
    const {className} = props
    const group_name = ({ item }) => {
      return <span>{item}</span>;
    };
    
    const tags = ({ item }) => {
      return <SwitchComponent
      checked={item?.features_enabled}

    
  />;
    };
    const cellMap = {
      group_name: group_name,
      tags:tags,
  
      action: ActionCell,
    };
    const handlePagination = (e, pageValue) => {
      dispatch(setPage(pageValue));
    };
  
    const handlePageSize = (e) => {
      dispatch(setPageSize(e.target.value));
    };
    function onEdit(data){
      navigate(`/userManagement/${data?.id}/edit`)
    }
    useEffect(() => {
      dispatch(getAllUserGroup({...pagination,search:""})); // Ensure this is being called
    }, [
      dispatch,
      pagination.page_size,
      pagination.page_number
    ]);
  return (
    <Grid className={className}>
      <Table
      title = {"User Management"}
      headers = {userManagementHeader}
      pagination={pagination}
      handlePagination={handlePagination}
      handlePageSize={handlePageSize}
      buttonText={"+ Create New User"}
      createUrl={"/userManagement/create"}
      data={authUserGroup}
      cellMap={cellMap}
      onEdit={onEdit}
      />
    </Grid>
  )
}

export default styled(UserManagement)(Style)
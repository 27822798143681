import { createAsyncThunk } from "@reduxjs/toolkit";
import { flozySupportService } from "../services/flozySupportService"; 
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";


export const getpagessupport = createAsyncThunk(
    "support/page",
    async (data, { rejectWithValue, dispatch }) => {
      try {  
        dispatch(setLoading("Fetching datas..."));
        const response = await flozySupportService.getpagesSupport(data);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );


  export const createFlow = createAsyncThunk(
    "support/createflow",
    async (data, { rejectWithValue, dispatch }) => {
      try {  
        dispatch(setLoading("Fetching datas..."));
        const response = await flozySupportService.createflowSupport(data);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );
import theme from "../../theme";

const Style = () => ({
  ".agenciDetailCard": {
    padding: "32px",
    borderRadius: '12px',
    background: theme.palette.containers.card,
    "& .agenciName": {
      display: "flex",
      alignItems: "center",
      "& .MuiAvatar-root": {
        width: "60px",
        height: "60px",
      },
    },
    "& .chipItem": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "12px",
      marginBottom: '12px',
      "&.agenciStatusActive": {
        background: `${theme.palette.colors.darkGreen}20`,
        height: "24px",
        padding: "6px 16px",
      },
      "&.agenciInactive": {
        background: `${theme.palette.colors.organe}90`,
        height: "24px",
        padding: "6px 16px",
      },
      "&.agenciTrail": {
        background: `${theme.palette.colors.blue}20`,
        height: "24px",
        padding: "6px 16px",
      },
      "&.agenciPlan": {
        background: `${theme.palette.colors.blue}10`,
        height: "56px",
        padding: "16px 16px",
      },
      "&.agenciDate": {
        background: `${theme.palette.colors.red}10`,
      },
    },
    '& .changePlanBtn': {
      padding: '6px 24px',
      borderRadius: '50px',
      background: `${theme.palette.primary.main}10`,
      border: `1px solid ${theme.palette.primary.main}`,
      fontSize: '10px',
      color: `${theme.palette.primary.main}`,
      height: '28px'
    },
    "& .agenciTags": {
      marginTop: "24px",
      "& .tagesChip": {
        padding: "6px 16px",
        height: "40px",
        borderRadius: "40px",
        background: `${theme.palette.colors.blue}20`,
        display: "flex",
        alignItems: "center",
      },
    },
    "& .agenciContact": {
      marginTop: "0px",
      "& .leftItem": {
        height: "40px",
        width: "40px",
        borderRadius: "20px",                         
        border: "1px solid #EBEAED",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .email_contaner" :{
            display:"flex",
      justifyContent:"space-between"
      }
    },


    '& .usersCountChip': {
      marginTop: '24px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: '48px',
      borderRadius: '54px',
      padding: '8px 24px 8px 8px',
      background: theme.palette.containers.greyBg2,
      '& .UsersIcon': {
        height: '32px',
        width: '32px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.containers.greyBg1,
        borderRadius: '30px'
      }
    },
    '& .transactionCard': {
      background: theme.palette.containers.greyBg3,
      padding: '10px 16px',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: '24px',
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px'
      }
    }
  },
  ".paymentCardRoot": {
    marginTop: "0px",
    "& .MuiGrid-root:nth-child(1) .paymentCard": {
      background:
        `radial-gradient(50% 50.00% at 50% 50.00%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%),
        linear-gradient(135deg, #5012D2 0%, #7137ED 0.01%, #267AF6 100%)`,
    },
    "& .MuiGrid-root:nth-child(2) .paymentCard": {
      background:
        `radial-gradient(50% 50.00% at 50% 50.00%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%),
       linear-gradient(135deg, rgba(3, 222, 169, 0.97) 0%, #1299D2 72.40%, #097ACB 100%);`,
    },
    "& .MuiGrid-root:nth-child(3) .paymentCard": {
      background:
        `linear-gradient(113.28deg, rgba(38, 219, 246, 0.97) 1.99%, #3749ED 71.41%, #2824E8 100%),
        radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);`
    },
    "& .MuiGrid-root:nth-child(4) .paymentCard": {
      background:
        `linear-gradient(113.28deg, rgba(105, 38, 246, 0.97) 1.99%, #9F10E2 65.8%),
        radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        `
    },
  },
  '.agencyPayments': {
    borderRadius: '12px',
    background: theme.palette.containers.card,
    '& .top': {
      padding: '24px'
    }
  },
  '.breadcrumb': {
    marginBottom: '20px',
    '& .MuiButton-contanedPrimary': {
      background: theme.palette.primary.main,
      color: '#fff',
      fontWeight: '600'
    },
  },
  '.planCard': {
    borderRadius: '12px',
    border: '1px solid rgba(163, 163, 163, 0.32)',
    background: '#FFF',
    padding: '16px',
    position: 'relative',
    '& .PlanTag': {
      position: 'absolute',
      top: '0',
      right: '0'
    }
  },
 
  // '.dialog_box':{
  //   display:'none',
  //   '& .MuiDialog-container':{
  //     background:"red"
  //   }
  // },
  // '.MuiDialogTitle-root.dialog_header': {
  //   background: "blue"
  // },
  // '.MuiDialog-container':{
  //   display:'none'
  // }
//   '.closeIcon': {
//   padding: "16px 20px",
  
// }


});

export default Style;

import { createSlice } from "@reduxjs/toolkit";
import { login, permissions ,getAllAuthUsers} from "../actions/authAction";

const initialState = {
 list:{
  user: null,
  permissions:{},
  userId:null,
  accessToken: "" ,
  pagination: {
    count: 10,
    next: null,
    previous: null,
    page_size: 10,
    num_pages: 0,
    page_number: 1,
  },
},

 loading: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.user = {};
    },
    accessToken(state,action){
      state.accessToken = action.payload;
    },
    loginSuccess(state,action){
      state.user = action.payload
    },
    setPageSize(state, action) {
      state.list.pagination.page_size = action.payload;
    
    },
    setPage(state, action) {
      state.list.pagination.page_number = action.payload;
    },
    setSearch(state, action) {
      state.list.pagination.page_number = 1;
      state.list.search = action.payload;
    },

  },
  extraReducers: (builder) => {
    // add cases for login api calls

    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.userId=payload?.userId;
        state.accessToken=payload?.accessToken
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
      });
      builder
      .addCase(permissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(permissions.fulfilled, (state, { payload }) => {
        state.permissions = payload?.permissions;
        state.loading = false;
      })
      .addCase(permissions.rejected, (state) => {
        state.loading = false;
      });

      builder
      .addCase(getAllAuthUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAuthUsers.fulfilled, (state, { payload }) => {
        state.list.user=payload?.res
        state.list.pagination={...payload?.pagination}
        state.loading = false;
      })
      .addCase(getAllAuthUsers.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { 
  logout,
  accessToken,
  loginSuccess,
  setPage,
  setPageSize,
  setSearch,
} = authSlice.actions;

export default authSlice.reducer;

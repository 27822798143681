import instance1 from "../../utils/api1";
export const referralService = {
  getaffliate,
  getconversions
};


async function getaffliate(data) {

  return await instance1.post(`referrals/getReferralTable`, data);
}

async function getconversions(data) {
  return await instance1.post(`referrals/getconversions`, data);
}
import instance1 from "../../utils/api1";
async function createFeature(data) {
    return instance1.post('feature/create', data)
}
async function getFeature(data) {
 
    return instance1.post('feature/getAll/all', data)
}
async function getFeatureById(param) {
    if (param) return instance1.get(`feature/${param}`)
}
async function deleteFeatureById(param) {
 
    if (param) return instance1.delete(`feature/${param}`)
}
async function updateFeatureById(id, data) {
   return instance1.put(`feature/${id}`, data);
}

export {
    createFeature,
    getFeature,
    getFeatureById,
    deleteFeatureById,
    updateFeatureById
}
import { createAsyncThunk } from "@reduxjs/toolkit";

import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import {createSupportFaq, getAllFaqs, updateSupportFaq} from "../services/faqService"


export const getAllFaqsAsync = createAsyncThunk("faqs/getAll", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching FAQs..."));
        const response = await getAllFaqs(data);
        dispatch(setLoading(null));
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
});



export const createsupportFaqAsync = createAsyncThunk("applicationModule/create", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Creating Application Module..."));
        const response = await createSupportFaq(data);
        dispatch(setLoading(null));
        dispatch(getAllFaqsAsync())
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
});


export const updateSupportFaqAsync = createAsyncThunk(
    "faqs/update",
    async ({ id, data }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoading("Updating FAQ..."));
            const response = await updateSupportFaq(id, data);
            dispatch(setLoading(null));
            dispatch(getAllFaqsAsync()); 
            return response?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);
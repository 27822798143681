export const FlozyLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 49" fill="none">
    <path d="M25.9514 15.4137L42.4328 31.8951C43.9135 33.3759 46.4495 32.7405 47.0407 30.7293C47.7369 28.348 48.0739 25.8175 47.9855 23.1931C47.5711 10.6787 37.3829 0.457288 24.8685 0.0152793C11.216 -0.465405 0 10.4577 0 23.9998C0 26.3424 0.337031 28.6077 0.966893 30.7514C1.55255 32.7515 4.08858 33.3648 5.56378 31.8951L22.0452 15.4137C23.1226 14.3363 24.874 14.3363 25.9514 15.4137Z" fill="url(#paint0_linear_7083_44642)" />
    <path d="M20.7691 38.0233L22.1117 36.6807C23.1891 35.6033 24.9406 35.6033 26.018 36.6807L33.51 44.1838C34.2725 44.9463 35.4604 45.1175 36.3831 44.5595C37.1511 44.0954 37.8859 43.5981 38.5931 43.0567C39.9247 42.0345 40.0683 40.0786 38.8804 38.8907L25.9462 25.9565C24.8688 24.8791 23.1173 24.8791 22.0399 25.9565L9.10564 38.8907C7.92326 40.0731 8.05587 42.0235 9.38189 43.0456C13.4318 46.1563 18.4983 48.0072 23.9958 48.0072H24.0234C25.0621 48.0072 25.5815 46.753 24.8467 46.0181L20.7691 41.9296C19.6917 40.8522 19.6917 39.1007 20.7691 38.0233Z" fill="#2F63ED" />
    <defs>
      <linearGradient id="paint0_linear_7083_44642" x1="10.6452" y1="4.05308" x2="44.9" y2="24.9915" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C63ED" />
        <stop offset="0.994792" stopColor="#8360FD" />
      </linearGradient>
    </defs>
  </svg>
);

export const MailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path d="M17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H17C18.1046 15 19 14.1046 19 13V3C19 1.89543 18.1046 1 17 1Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 3L10 9L19 3" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const LockIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
    <path d="M13 9H3C1.89543 9 1 9.89543 1 11V17C1 18.1046 1.89543 19 3 19H13C14.1046 19 15 18.1046 15 17V11C15 9.89543 14.1046 9 13 9Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 15C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15Z" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 9V5C4 3.93913 4.42143 2.92172 5.17157 2.17157C5.92172 1.42143 6.93913 1 8 1C9.06087 1 10.0783 1.42143 10.8284 2.17157C11.5786 2.92172 12 3.93913 12 5V9" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const EyeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
    <path d="M2 1L20 19" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.58445 8.58691C9.20917 8.96193 8.99823 9.47067 8.99805 10.0012C8.99786 10.5317 9.20844 11.0406 9.58345 11.4159C9.95847 11.7912 10.4672 12.0021 10.9977 12.0023C11.5283 12.0025 12.0372 11.7919 12.4125 11.4169" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.363 3.36506C9.22042 3.11978 10.1082 2.9969 11 3.00006C15 3.00006 18.333 5.33306 21 10.0001C20.222 11.3611 19.388 12.5241 18.497 13.4881M16.357 15.3491C14.726 16.4491 12.942 17.0001 11 17.0001C7 17.0001 3.667 14.6671 1 10.0001C2.369 7.60506 3.913 5.82506 5.632 4.65906" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ShowEyeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
    <path d="M9.58445 6.58691C9.20917 6.96193 8.99823 7.47067 8.99805 8.00121C8.99786 8.53175 9.20844 9.04063 9.58345 9.41591C9.95847 9.7912 10.4672 10.0021 10.9977 10.0023C11.5283 10.0025 12.0372 9.79193 12.4125 9.41691" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.363 1.36506C9.22042 1.11978 10.1082 0.9969 11 1.00006C15 1.00006 18.333 3.33306 21 8.00006C20.222 9.36106 19.388 10.5241 18.497 11.4881M16.357 13.3491C14.726 14.4491 12.942 15.0001 11 15.0001C7 15.0001 3.667 12.6671 1 8.00006C2.369 5.60506 3.913 3.82506 5.632 2.65906" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuHome = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M9.33594 14.1807V11.6807" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.72766 1.53021L1.95266 6.15521C1.30266 6.67188 0.885996 7.76355 1.02766 8.58021L2.136 15.2135C2.336 16.3969 3.46933 17.3552 4.66933 17.3552H14.0027C15.1943 17.3552 16.336 16.3885 16.536 15.2135L17.6443 8.58021C17.7777 7.76355 17.361 6.67188 16.7193 6.15521L10.9443 1.53855C10.0527 0.82188 8.611 0.82188 7.72766 1.53021Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuAgency = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M1 17.666H17.6667" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.45801 2.66667V17.6667H11.208V2.66667C11.208 1.75 10.833 1 9.70801 1H8.95801C7.83301 1 7.45801 1.75 7.45801 2.66667Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.83301 7.66667V17.6667H5.16634V7.66667C5.16634 6.75 4.83301 6 3.83301 6H3.16634C2.16634 6 1.83301 6.75 1.83301 7.66667Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 11.8327V17.666H16.8333V11.8327C16.8333 10.916 16.5 10.166 15.5 10.166H14.8333C13.8333 10.166 13.5 10.916 13.5 11.8327Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuSales = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M1 1V15.1667C1 16.55 2.11667 17.6667 3.5 17.6667H17.6667" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.5 13.4974L7.325 9.03074C7.95833 8.29741 9.08333 8.24739 9.76667 8.93906L10.5583 9.73074C11.2417 10.4141 12.3667 10.3724 13 9.63906L16.8333 5.16406" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuEmail = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path d="M17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H17C18.1046 15 19 14.1046 19 13V3C19 1.89543 18.1046 1 17 1Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 3L10 9L19 3" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuSttings = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path d="M10 11.5C11.3807 11.5 12.5 10.3807 12.5 9C12.5 7.61929 11.3807 6.5 10 6.5C8.61929 6.5 7.5 7.61929 7.5 9C7.5 10.3807 8.61929 11.5 10 11.5Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.66699 9.73287V8.26621C1.66699 7.39954 2.37533 6.68287 3.25033 6.68287C4.75866 6.68287 5.37533 5.61621 4.61699 4.30787C4.18366 3.55787 4.44199 2.58287 5.20033 2.14954L6.64199 1.32454C7.30033 0.932873 8.15033 1.16621 8.54199 1.82454L8.63366 1.98287C9.38366 3.29121 10.617 3.29121 11.3753 1.98287L11.467 1.82454C11.8587 1.16621 12.7087 0.932873 13.367 1.32454L14.8087 2.14954C15.567 2.58287 15.8253 3.55787 15.392 4.30787C14.6337 5.61621 15.2503 6.68287 16.7587 6.68287C17.6253 6.68287 18.342 7.39121 18.342 8.26621V9.73287C18.342 10.5995 17.6337 11.3162 16.7587 11.3162C15.2503 11.3162 14.6337 12.3829 15.392 13.6912C15.8253 14.4495 15.567 15.4162 14.8087 15.8495L13.367 16.6745C12.7087 17.0662 11.8587 16.8329 11.467 16.1745L11.3753 16.0162C10.6253 14.7079 9.39199 14.7079 8.63366 16.0162L8.54199 16.1745C8.15033 16.8329 7.30033 17.0662 6.64199 16.6745L5.20033 15.8495C4.44199 15.4162 4.18366 14.4412 4.61699 13.6912C5.37533 12.3829 4.75866 11.3162 3.25033 11.3162C2.37533 11.3162 1.66699 10.5995 1.66699 9.73287Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuUser = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <path d="M8.15788 9.33333C10.4591 9.33333 12.3245 7.46785 12.3245 5.16667C12.3245 2.86548 10.4591 1 8.15788 1C5.85669 1 3.99121 2.86548 3.99121 5.16667C3.99121 7.46785 5.85669 9.33333 8.15788 9.33333Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1667 12.4492L11.2167 15.3992C11.1 15.5159 10.9917 15.7325 10.9667 15.8909L10.8084 17.0159C10.7501 17.4242 11.0334 17.7075 11.4417 17.6492L12.5667 17.4909C12.725 17.4659 12.9501 17.3575 13.0584 17.2409L16.0084 14.2909C16.5167 13.7826 16.7584 13.1909 16.0084 12.4409C15.2667 11.6992 14.6751 11.9409 14.1667 12.4492Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.7422 12.874C13.9922 13.774 14.6922 14.474 15.5922 14.724" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 17.6663C1 14.4413 4.20836 11.833 8.15836 11.833C9.02502 11.833 9.85833 11.958 10.6333 12.1913" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const BellIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
    <path d="M7 3C7 2.46957 7.21071 1.96086 7.58579 1.58579C7.96086 1.21071 8.46957 1 9 1C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3C12.1484 3.54303 13.1274 4.38833 13.8321 5.4453C14.5367 6.50227 14.9404 7.73107 15 9V12C15.0753 12.6217 15.2954 13.2171 15.6428 13.7381C15.9902 14.2592 16.4551 14.6914 17 15H1C1.54494 14.6914 2.00981 14.2592 2.35719 13.7381C2.70457 13.2171 2.92474 12.6217 3 12V9C3.05956 7.73107 3.4633 6.50227 4.16795 5.4453C4.8726 4.38833 5.85159 3.54303 7 3" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 15V16C6 16.7956 6.31607 17.5587 6.87868 18.1213C7.44129 18.6839 8.20435 19 9 19C9.79565 19 10.5587 18.6839 11.1213 18.1213C11.6839 17.5587 12 16.7956 12 16V15" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SearchIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M8.16667 14.8333C11.8486 14.8333 14.8333 11.8486 14.8333 8.16667C14.8333 4.48477 11.8486 1.5 8.16667 1.5C4.48477 1.5 1.5 4.48477 1.5 8.16667C1.5 11.8486 4.48477 14.8333 8.16667 14.8333Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5003 16.5003L13.167 13.167" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const TotalUsers = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M27.7301 23.0927L27.7302 23.0927L29.4183 23.0927C30.9408 23.0927 32.3994 23.7271 33.4737 24.8539C34.5478 25.9804 35.15 27.5069 35.15 29.0971V29.9824C35.15 30.5501 34.935 31.0959 34.5503 31.4994C34.1654 31.9032 33.6417 32.1314 33.0941 32.1314H25.9946C25.9541 32.6396 25.7416 33.1189 25.3915 33.4809C25.0074 33.878 24.4881 34.102 23.9455 34.102C23.9455 34.102 23.9455 34.102 23.9455 34.102H14.9059C14.3583 34.102 13.8346 33.8737 13.4497 33.47C13.065 33.0664 12.85 32.5206 12.85 31.9529V31.0961C12.85 29.5059 13.4522 27.9795 14.5263 26.853C15.0582 26.2951 15.69 25.8521 16.3859 25.5498C17.0818 25.2475 17.828 25.0917 18.5817 25.0917H20.2968H20.297V25.2417C21.2505 25.2388 22.1885 25.4946 23.0198 25.9843L27.7301 23.0927ZM27.7301 23.0927C26.7609 23.093 25.8081 23.352 24.9615 23.8448C24.1565 24.3133 23.4729 24.9783 22.9678 25.7818L27.7301 23.0927ZM27.7295 24.535H27.7302H29.4451C30.5897 24.5424 31.6866 25.0244 32.4958 25.8784C33.3054 26.7327 33.7609 27.8897 33.7609 29.0971V29.9538C33.7609 30.1435 33.689 30.3241 33.5631 30.4561C33.4374 30.5879 33.2685 30.6606 33.0941 30.6606H25.9932C25.9405 29.8919 25.7463 29.1405 25.4207 28.448C25.0929 27.7508 24.6384 27.1273 24.0827 26.6124C24.4651 25.9892 24.9872 25.4739 25.6033 25.1119C26.2531 24.73 26.9856 24.5315 27.7295 24.535ZM18.5817 26.5341H20.2966C21.4412 26.5415 22.5381 27.0235 23.3473 27.8774C24.1569 28.7317 24.6124 29.8887 24.6124 31.0961V31.9529C24.6124 32.1426 24.5405 32.3231 24.4146 32.4552C24.2889 32.587 24.12 32.6596 23.9455 32.6596H14.9059C14.7315 32.6596 14.5626 32.587 14.4369 32.4552C14.311 32.3231 14.2391 32.1426 14.2391 31.9529V31.0961C14.2391 29.884 14.6983 28.7228 15.5135 27.8677C16.3285 27.0129 17.4323 26.5341 18.5817 26.5341Z" fill="#38BDF8" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M16.7206 20.2514H16.7441L16.7441 20.1014C16.7441 19.5353 16.9046 18.9825 17.2043 18.5133C17.504 18.0441 17.9291 17.68 18.4248 17.4661C18.9204 17.2523 19.465 17.1978 19.9897 17.3094C20.5145 17.4209 20.9969 17.6937 21.3752 18.0943C21.7536 18.495 22.0107 19.0055 22.1128 19.5612C22.2149 20.1169 22.1572 20.6919 21.9473 21.2128C21.7374 21.7336 21.3852 22.1761 20.9364 22.485C20.4878 22.7938 19.9625 22.9553 19.4273 22.95L19.4273 22.95H19.4258C18.7097 22.95 18.0214 22.6517 17.5128 22.1182C17.0376 21.6198 16.7559 20.9543 16.7206 20.2514ZM23.5238 20.1014C23.5238 19.2543 23.2844 18.4256 22.835 17.7202C22.3855 17.0147 21.746 16.4637 20.9964 16.1381C20.2467 15.8124 19.4213 15.727 18.6249 15.8932C17.8286 16.0593 17.0982 16.4692 16.5256 17.0698C15.953 17.6703 15.5639 18.4346 15.4063 19.2656C15.2487 20.0966 15.3295 20.958 15.6388 21.7411C15.9481 22.5244 16.4724 23.1949 17.1463 23.6673C17.8204 24.1397 18.6137 24.3923 19.4258 24.3923C20.515 24.3923 21.5581 23.9384 22.326 23.133C23.0937 22.3278 23.5238 21.2371 23.5238 20.1014Z" fill="#38BDF8" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M25.869 18.2521H25.8924L25.8924 18.1021C25.8925 17.536 26.0529 16.9832 26.3526 16.514C26.6523 16.0448 27.0774 15.6807 27.5731 15.4669C28.0687 15.253 28.6133 15.1986 29.138 15.3101C29.6628 15.4217 30.1452 15.6945 30.5235 16.0951C30.9019 16.4958 31.159 17.0062 31.2611 17.5619C31.3632 18.1177 31.3055 18.6926 31.0956 19.2135C30.8857 19.7343 30.5335 20.1769 30.0848 20.4857C29.6361 20.7945 29.1108 20.9561 28.5756 20.9507L28.5756 20.9507H28.5741C27.858 20.9507 27.1698 20.6524 26.6611 20.1189C26.1859 19.6205 25.9042 18.955 25.869 18.2521ZM32.6721 18.1021C32.6721 17.255 32.4327 16.4264 31.9833 15.7209C31.5339 15.0155 30.8943 14.4645 30.1447 14.1388C29.395 13.8131 28.5696 13.7278 27.7733 13.8939C26.9769 14.06 26.2465 14.4699 25.6739 15.0705C25.1013 15.671 24.7122 16.4353 24.5546 17.2663C24.397 18.0973 24.4778 18.9587 24.7871 19.7419C25.0964 20.5251 25.6207 21.1957 26.2946 21.668C26.9687 22.1404 27.762 22.393 28.5741 22.393C29.6634 22.393 30.7064 21.9391 31.4744 21.1337C32.242 20.3285 32.6721 19.2379 32.6721 18.1021Z" fill="#38BDF8" stroke="#2563EB" strokeWidth="0.3" />
  </svg>
);

export const TrialAgencies = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M24.8642 28.8928C24.8642 26.6679 26.6679 24.8642 28.8928 24.8642C31.1177 24.8642 32.9214 26.6679 32.9214 28.8928C32.9214 31.1177 31.1177 32.9214 28.8928 32.9214C26.6679 32.9214 24.8642 31.1177 24.8642 28.8928ZM28.8928 23.6356C25.9893 23.6356 23.6356 25.9893 23.6356 28.8928C23.6356 31.7963 25.9893 34.1499 28.8928 34.1499C31.7963 34.1499 34.1499 31.7963 34.1499 28.8928C34.1499 25.9893 31.7963 23.6356 28.8928 23.6356Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
    <path d="M18.8286 19.1429C18.8286 17.1744 20.4244 15.5786 22.3929 15.5786C24.3613 15.5786 25.9571 17.1744 25.9571 19.1429C25.9571 21.1114 24.3613 22.7071 22.3929 22.7071C20.4244 22.7071 18.8286 21.1114 18.8286 19.1429ZM22.3929 14.35C19.7458 14.35 17.6 16.4958 17.6 19.1429C17.6 21.7899 19.7458 23.9357 22.3929 23.9357C25.0399 23.9357 27.1857 21.7899 27.1857 19.1429C27.1857 16.4958 25.0399 14.35 22.3929 14.35Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
    <path d="M22.3929 23.9357H22.5429V23.7857V22.8572V22.7072H22.3929C17.9509 22.7072 14.35 26.3081 14.35 30.75V30.9H14.5H15.4286H15.5786V30.75C15.5786 26.9866 18.6294 23.9357 22.3929 23.9357Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
    <path d="M28.4285 26.4215H28.2785V26.5715V28.743H27.0356H26.8856V28.893V29.8215V29.9715H27.0356H28.8928C29.232 29.9715 29.5071 29.6965 29.5071 29.3572V26.5715V26.4215H29.3571H28.4285Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
  </svg>
);

export const PaidAgencies = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#EFF6FF" />
    <path d="M19.9714 20.3571C19.9714 18.073 21.823 16.2214 24.1071 16.2214C26.3912 16.2214 28.2429 18.0731 28.2429 20.3571C28.2429 22.6412 26.3912 24.4929 24.1071 24.4929C21.823 24.4929 19.9714 22.6412 19.9714 20.3571ZM24.1071 14.85C21.0656 14.85 18.6 17.3156 18.6 20.3571C18.6 23.3987 21.0656 25.8643 24.1071 25.8643C27.1486 25.8643 29.6143 23.3987 29.6143 20.3571C29.6143 17.3156 27.1486 14.85 24.1071 14.85Z" fill="#2563EB" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M24.1071 25.8643H24.2571V25.7143V24.6428V24.4928H24.1071C18.9946 24.4928 14.85 28.6374 14.85 33.75V33.9H15H16.0714H16.2214V33.75C16.2214 29.3948 19.752 25.8643 24.1071 25.8643Z" fill="#2563EB" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M30 25C27.2425 25 25 27.2429 25 30C25 32.7571 27.2425 35 30 35C32.7568 35 35 32.7571 35 30C35 27.2429 32.7568 25 30 25ZM29.6211 32.915V32.2364C29.1425 32.215 28.6779 32.0864 28.4068 31.9293L28.6211 31.0936C28.9211 31.2579 29.3425 31.4079 29.8068 31.4079C30.2143 31.4079 30.4925 31.2507 30.4925 30.965C30.4925 30.6936 30.2643 30.5221 29.7357 30.3436C28.9711 30.0861 28.4493 29.7289 28.4493 29.0361C28.4493 28.4071 28.8921 27.9143 29.6568 27.7643V27.0857H30.3571V27.7143C30.8357 27.7357 31.1571 27.8357 31.3929 27.95L31.1857 28.7571C30.9996 28.6786 30.6711 28.5143 30.1568 28.5143C29.6921 28.5143 29.5421 28.7143 29.5421 28.9143C29.5421 29.15 29.7925 29.3 30.3993 29.5289C31.2496 29.8289 31.5929 30.2218 31.5929 30.8646C31.5929 31.5004 31.1421 32.0436 30.3204 32.1864V32.915H29.6211Z" fill="#2563EB" />
  </svg>
);

export const TotalAgencies = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#FFFBEB" />
    <path d="M23.5898 24C26.3513 24 28.5898 21.7614 28.5898 19C28.5898 16.2386 26.3513 14 23.5898 14C20.8284 14 18.5898 16.2386 18.5898 19C18.5898 21.7614 20.8284 24 23.5898 24Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32.18 34C32.18 30.13 28.33 27 23.59 27C18.85 27 15 30.13 15 34" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MarketingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.8 21.0002C26.6188 20.6859 26.3557 20.4269 26.0386 20.2507C25.7215 20.0746 25.3625 19.988 25 20.0002H23C22.4696 20.0002 21.9609 20.2109 21.5858 20.5859C21.2107 20.961 21 21.4697 21 22.0002C21 22.5306 21.2107 23.0393 21.5858 23.4144C21.9609 23.7894 22.4696 24.0002 23 24.0002H25C25.5304 24.0002 26.0391 24.2109 26.4142 24.5859C26.7893 24.961 27 25.4697 27 26.0002C27 26.5306 26.7893 27.0393 26.4142 27.4144C26.0391 27.7894 25.5304 28.0002 25 28.0002H23C22.6375 28.0124 22.2785 27.9258 21.9614 27.7496C21.6443 27.5735 21.3812 27.3144 21.2 27.0002" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 28V30M24 18V20V18Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ReturnIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#FDF2F8" />
    <path d="M24 20V24L26 26" stroke="#ED4F9D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.05 23.0001C15.2741 20.8001 16.3003 18.7597 17.9328 17.268C19.5653 15.7764 21.6897 14.938 23.9009 14.9127C26.1121 14.8874 28.2552 15.6771 29.9213 17.1311C31.5874 18.5851 32.6599 20.6015 32.9342 22.7958C33.2085 24.99 32.6654 27.2085 31.4084 29.0278C30.1515 30.8472 28.2687 32.1401 26.1193 32.6599C23.97 33.1797 21.7045 32.89 19.755 31.8461C17.8056 30.8022 15.05 27.0001 15.05 27.0001M15.05 32.0001V27.0001M15.05 27.0001H20.05" stroke="#ED4F9D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const TotalRevenuIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.8 21.0002C26.6188 20.6859 26.3557 20.4269 26.0386 20.2507C25.7215 20.0746 25.3625 19.988 25 20.0002H23C22.4696 20.0002 21.9609 20.2109 21.5858 20.5859C21.2107 20.961 21 21.4697 21 22.0002C21 22.5306 21.2107 23.0393 21.5858 23.4144C21.9609 23.7894 22.4696 24.0002 23 24.0002H25C25.5304 24.0002 26.0391 24.2109 26.4142 24.5859C26.7893 24.961 27 25.4697 27 26.0002C27 26.5306 26.7893 27.0393 26.4142 27.4144C26.0391 27.7894 25.5304 28.0002 25 28.0002H23C22.6375 28.0124 22.2785 27.9258 21.9614 27.7496C21.6443 27.5735 21.3812 27.3144 21.2 27.0002" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 28V30M24 18V20V18Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SalesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#FFFBEB" />
    <path d="M18 33C19.1046 33 20 32.1046 20 31C20 29.8954 19.1046 29 18 29C16.8954 29 16 29.8954 16 31C16 32.1046 16.8954 33 18 33Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M29 33C30.1046 33 31 32.1046 31 31C31 29.8954 30.1046 29 29 29C27.8954 29 27 29.8954 27 31C27 32.1046 27.8954 33 29 33Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M29 29H18V15H16" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32 18L31 25H18" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 22L28 16" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.5 17C22.7761 17 23 16.7761 23 16.5C23 16.2239 22.7761 16 22.5 16C22.2239 16 22 16.2239 22 16.5C22 16.7761 22.2239 17 22.5 17Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27.5 22C27.7761 22 28 21.7761 28 21.5C28 21.2239 27.7761 21 27.5 21C27.2239 21 27 21.2239 27 21.5C27 21.7761 27.2239 22 27.5 22Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const AgenciICon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M10.5 6.125V2.625H1.75V18.375H19.25V6.125H10.5ZM5.25 16.625H3.5V14.875H5.25V16.625ZM5.25 13.125H3.5V11.375H5.25V13.125ZM5.25 9.625H3.5V7.875H5.25V9.625ZM5.25 6.125H3.5V4.375H5.25V6.125ZM8.75 16.625H7V14.875H8.75V16.625ZM8.75 13.125H7V11.375H8.75V13.125ZM8.75 9.625H7V7.875H8.75V9.625ZM8.75 6.125H7V4.375H8.75V6.125ZM17.5 16.625H10.5V14.875H12.25V13.125H10.5V11.375H12.25V9.625H10.5V7.875H17.5V16.625ZM15.75 9.625H14V11.375H15.75V9.625ZM15.75 13.125H14V14.875H15.75V13.125Z" fill="#84818A" />
  </svg>
);

export const CheckFilled = (props) => (
  <svg className="CheckFilled" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none">
    <rect width="20" height="20" rx="10" fill="#0E8E2A" />
    <path d="M6.25 10L9.375 13.125L15 7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export const InactiveIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="12" height="12" rx="6" fill="#EF8D1A" />
      <path d="M8 5.33301V8.66634" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.99634 10.667H8.00233" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )

}

export const ProDimond = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 16 11" fill="none">
    <path d="M7.61048 10.6695L9.58138 3.71092H5.64006L7.61048 10.6695ZM1.34401 2.99283C1.54226 2.99283 1.70306 3.15363 1.70306 3.35187C1.70306 3.55012 1.54226 3.71092 1.34401 3.71092H0.359044C0.160802 3.71092 0 3.55012 0 3.35187C0 3.15363 0.160802 2.99283 0.359044 2.99283H1.34401ZM13.0086 5.86806C12.868 5.7279 12.868 5.50038 13.0086 5.36021C13.1488 5.22005 13.3763 5.22005 13.5165 5.36021L14.247 6.09078C14.3872 6.23142 14.3872 6.45846 14.247 6.59863C14.1069 6.73879 13.8793 6.73879 13.7392 6.59863L13.0086 5.86806ZM13.7392 0.105121C13.8798 -0.0350404 14.1069 -0.0350404 14.247 0.105121C14.3872 0.245762 14.3872 0.472805 14.247 0.612966L13.5165 1.34353C13.3763 1.48369 13.1488 1.48369 13.0086 1.34353C12.868 1.20337 12.868 0.97585 13.0086 0.835688L13.7392 0.105121ZM13.8769 3.7114C13.6787 3.7114 13.5179 3.5506 13.5179 3.35235C13.5179 3.15411 13.6787 2.99331 13.8769 2.99331H14.8619C15.0602 2.99331 15.221 3.15411 15.221 3.35235C15.221 3.5506 15.0602 3.7114 14.8619 3.7114H13.8769ZM1.7045 5.36021C1.84466 5.22005 2.07218 5.22005 2.21234 5.36021C2.35298 5.50086 2.35298 5.7279 2.21234 5.86806L1.48177 6.59863C1.34113 6.73879 1.11409 6.73879 0.97393 6.59863C0.833768 6.45846 0.833768 6.23094 0.97393 6.09078L1.7045 5.36021ZM0.97393 0.612966C0.833768 0.472805 0.833768 0.245282 0.97393 0.105121C1.11409 -0.0350404 1.34161 -0.0350404 1.48177 0.105121L2.21234 0.835688C2.35298 0.97585 2.35298 1.20337 2.21234 1.34353C2.07218 1.48369 1.84466 1.48369 1.7045 1.34353L0.97393 0.612966ZM9.61258 2.99283L9.23049 1.03729H5.98998L5.6079 2.99283H9.61258ZM8.83737 8.97369L12.6438 3.7114H10.3278L8.83737 8.97369ZM12.7244 2.99283L11.7491 1.03729H9.9625L10.3446 2.99283H12.7244ZM5.25845 1.03729H3.47187L2.49651 2.99283H4.87637L5.25845 1.03729ZM2.57763 3.7114L6.38406 8.97369L4.89365 3.7114H2.57763Z" fill="url(#paint0_diamond_1508_89)" />
    <defs>
      <radialGradient id="paint0_diamond_1508_89" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.61048 5.33477) rotate(90) scale(5.33477 7.61048)">
        <stop stopColor="#19A9FC" />
        <stop offset="1" stopColor="#2563EB" />
      </radialGradient>
    </defs>
  </svg>
);

export const AlertIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z" stroke="#FF3B3B" strokeOpacity="0.37" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 6V9.75" stroke="#FF3B3B" strokeOpacity="0.37" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.99609 12H9.00283" stroke="#FF3B3B" strokeOpacity="0.37" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FilledEmailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
    <path d="M15 0H1.66666C0.75 0 0.00832999 0.75 0.00832999 1.66666L0 11.6667C0 12.5833 0.75 13.3333 1.66666 13.3333H15C15.9167 13.3333 16.6667 12.5833 16.6667 11.6667V1.66666C16.6667 0.75 15.9167 0 15 0ZM15 3.33333L8.33335 7.5L1.66666 3.33333V1.66666L8.33335 5.83333L15 1.66666V3.33333Z" fill="#2E2C34" />
  </svg>
);

export const PhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
    <path d="M10.1669 0.841341L1.83365 0.833008C0.916992 0.833008 0.166992 1.58301 0.166992 2.49967V17.4997C0.166992 18.4163 0.916992 19.1663 1.83365 19.1663H10.1669C11.0836 19.1663 11.8336 18.4163 11.8336 17.4997V2.49967C11.8336 1.58301 11.0836 0.841341 10.1669 0.841341ZM10.1669 15.833H1.83365V4.16634H10.1669V15.833Z" fill="#2E2C34" />
  </svg>
);
export const PinIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.99984 1.6665C6.77484 1.6665 4.1665 4.27484 4.1665 7.49984C4.1665 11.8748 9.99984 18.3332 9.99984 18.3332C9.99984 18.3332 15.8332 11.8748 15.8332 7.49984C15.8332 4.27484 13.2248 1.6665 9.99984 1.6665ZM9.99984 9.58317C8.84984 9.58317 7.9165 8.64984 7.9165 7.49984C7.9165 6.34984 8.84984 5.4165 9.99984 5.4165C11.1498 5.4165 12.0832 6.34984 12.0832 7.49984C12.0832 8.64984 11.1498 9.58317 9.99984 9.58317Z" fill="#84818A" />
  </svg>
);

export const InfiniteIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
    <path d="M1.11564 7.83213C1.35284 9.62093 2.21094 10.1916 2.61044 10.2534C2.47454 10.0558 2.18294 9.58383 2.10394 9.27753C2.00504 8.89453 1.94334 7.83213 2.61044 6.78213C3.27744 5.73203 4.47574 5.12672 5.62464 4.76852C6.54374 4.48192 7.41594 4.53373 7.73714 4.59553C8.03354 4.58323 7.77684 4.30712 6.69944 3.91612C5.51344 3.48372 4.21634 3.37253 3.14164 3.97783C2.06684 4.58323 0.819143 5.59613 1.11564 7.83213Z" fill="url(#paint0_linear_1512_132)" />
    <path d="M1.11566 7.83285C1.35286 9.62165 2.21096 10.1924 2.61046 10.2542C2.47456 10.0566 2.18296 9.58455 2.10396 9.27825C2.00506 8.89525 1.94336 7.83285 2.61046 6.78285C2.90596 6.31765 3.30576 5.93975 3.75926 5.63195C3.95696 5.52275 4.03106 5.44865 4.03106 5.44865C3.72226 5.38685 3.24046 5.31275 2.61046 5.44865C1.98036 5.58455 1.37506 5.94275 1.13296 6.48635C1.06066 6.87825 1.04816 7.32385 1.11566 7.83285Z" fill="url(#paint1_linear_1512_132)" />
    <path d="M2.54737 5.34906C1.95447 6.11496 1.36947 7.39676 2.11067 9.27446C1.95007 8.73086 2.17867 7.70246 2.38867 7.18356C2.59867 6.66476 3.40656 5.70176 4.75266 5.06496C5.90156 4.52136 7.27467 4.52136 7.92117 4.57076C7.58767 4.41436 6.64387 4.09886 5.53697 4.08906C4.15337 4.07666 3.22687 4.66966 2.54737 5.34906Z" fill="url(#paint2_linear_1512_132)" />
    <path d="M19.081 1.08853C21.4279 1.51963 23.1576 2.64893 24.5302 5.45603C24.1789 5.14803 23.4151 4.41253 22.8339 4.04663C22.1075 3.58923 20.7603 3.06833 20.26 2.53833C19.3559 1.70323 17.9235 1.24183 17.3149 1.17763C16.7063 1.11333 17.0249 0.77883 19.081 1.08853Z" fill="url(#paint3_linear_1512_132)" />
    <path d="M2.93655 11.9721C1.20415 11.0634 0.74895 8.95067 0.86985 7.58487C0.99065 6.21917 1.34495 5.99937 1.27455 6.12027C1.04495 7.72767 1.34115 9.53077 2.63435 10.0021C3.92755 10.4735 5.18455 9.42197 6.28435 8.35847C7.38415 7.29487 9.51125 5.40947 10.3332 4.58757C11.155 3.76577 14.3053 1.11657 16.6984 0.983567C18.9222 0.860067 20.1428 2.03507 21.0655 3.18567C21.6201 3.87727 22.0564 5.56657 21.8389 5.36107C21.6213 5.15567 21.0291 4.59967 19.9172 4.29757C18.8053 3.99537 17.3551 4.14047 16.0981 4.52717C14.8412 4.91397 13.2096 6.03797 12.1823 6.98067C11.155 7.92337 8.66525 10.4251 7.51705 11.2832C6.36895 12.1413 4.60395 12.8468 2.93655 11.9721Z" fill="url(#paint4_linear_1512_132)" />
    <path d="M7.52875 11.3071C6.38055 12.1652 4.59185 12.9145 2.94815 11.996C1.75965 11.3071 1.44545 10.507 1.62675 10.7125C1.80805 10.9179 3.10125 12.6583 5.22835 11.5101C7.35545 10.362 9.55515 7.37679 11.3075 5.73309C13.06 4.08939 14.2646 3.12249 16.1097 2.57869C17.9548 2.03479 20.3035 2.39009 21.077 3.20959C21.6316 3.90119 22.0681 5.59049 21.8505 5.38499C21.633 5.17959 21.0408 4.62359 19.9289 4.32149C18.817 4.01929 17.3666 4.16429 16.1097 4.55109C14.8528 4.93779 13.2212 6.06179 12.1939 7.00449C11.1666 7.94719 8.67695 10.449 7.52875 11.3071Z" fill="url(#paint5_linear_1512_132)" />
    <path d="M8.1257 11.8651C6.9524 12.6574 5.1246 13.3492 3.4449 12.5012C2.2304 11.8651 1.9093 11.1265 2.0946 11.3162C2.2798 11.5058 3.6073 13.0252 5.775 12.0526C7.9814 11.0626 10.1964 8.2365 11.9872 6.719C13.778 5.2015 15.009 4.3088 16.8945 3.8067C18.7799 3.3046 21.18 3.6327 21.9704 4.3892C22.5372 5.0277 22.9832 6.5873 22.7609 6.3977C22.5386 6.208 21.9334 5.6947 20.7972 5.4157C19.6609 5.1368 18.1789 5.2707 16.8945 5.6277C15.61 5.9848 13.9427 7.0225 12.8929 7.8928C11.8432 8.7632 9.299 11.0729 8.1257 11.8651Z" fill="url(#paint6_linear_1512_132)" />
    <path d="M20.8565 3.00265C20.6873 2.82135 22.1153 3.37625 23.4931 4.45185C23.9124 4.77925 24.1076 4.90015 24.3856 5.21435C24.6454 5.50805 25.173 7.42505 25.173 8.33145C25.1247 10.1685 24.6408 11.3153 24.5324 11.5473C24.3856 11.8617 24.3372 11.9945 24.3856 11.0638C24.4339 10.1332 24.3131 8.87635 23.7934 7.78865C23.1286 6.70085 22.7177 6.41085 22.0046 5.66155C21.8475 5.49235 21.908 5.17805 21.775 4.77925C21.63 4.30785 21.0257 3.18385 20.8565 3.00265Z" fill="url(#paint7_linear_1512_132)" />
    <path d="M4.04404 5.45635C3.85064 5.39835 4.34624 5.06945 5.74814 4.63435C6.31624 4.51345 7.52484 4.17525 8.06864 4.56185C8.83004 4.92015 10.5704 6.56805 11.3077 7.31745C11.9281 7.90965 13.4493 9.25355 14.5709 9.89175C15.9728 10.6893 18.0637 11.62 19.659 11.1003C21.2544 10.5806 21.8828 8.57435 21.907 7.72835C21.9312 6.88235 21.907 5.79455 21.907 5.51665C21.903 5.44405 21.9819 5.40055 22.33 5.80665C22.7651 6.31425 23.2848 6.56805 23.9495 8.11505C24.2033 8.75165 24.6257 10.5201 24.4571 11.4991C24.3115 12.3451 23.9611 12.7923 23.2969 13.5416C22.6327 14.2909 21.1577 15.3666 19.3811 15.3787C17.6044 15.3908 16.2145 14.6414 15.4531 14.2789C14.172 13.6866 12.0812 12.1034 10.9572 10.9794C9.82114 9.94005 8.67294 8.77985 7.95994 8.06675C7.38944 7.49625 6.53774 6.66885 6.18324 6.32635C5.47024 5.77045 4.32204 5.53975 4.04404 5.45635Z" fill="url(#paint8_linear_1512_132)" />
    <path d="M8.08124 4.55373C7.58814 4.32173 6.32884 4.50543 5.76074 4.62623C5.98054 4.70693 6.70294 5.11243 7.83414 6.08883C9.24824 7.30933 11.2786 9.19503 13.1036 10.343C15.2911 11.6846 17.3807 12.3008 19.6716 11.0922C18.0763 11.6119 15.9854 10.6813 14.5835 9.88363C13.4619 9.24553 11.9407 7.90153 11.3203 7.30933C10.583 6.56003 8.84274 4.91203 8.08124 4.55373Z" fill="url(#paint9_linear_1512_132)" />
    <path d="M22.2942 13.5139C21.2983 14.4517 19.3654 14.4848 18.5234 14.384L23.6962 10.4199C23.6962 10.4199 23.5391 12.3415 22.2942 13.5139Z" fill="url(#paint10_linear_1512_132)" />
    <defs>
      <linearGradient id="paint0_linear_1512_132" x1="6.84034" y1="6.52273" x2="4.45864" y2="3.55763" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8D2EB0" />
        <stop offset="0.932292" stopColor="#6E11B8" />
      </linearGradient>
      <linearGradient id="paint1_linear_1512_132" x1="0.774757" y1="10.4519" x2="2.91196" y2="4.89275" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#BA6CD9" />
      </linearGradient>
      <linearGradient id="paint2_linear_1512_132" x1="0.441265" y1="6.85616" x2="5.51857" y2="5.20086" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9" stop-opacity="0.59" />
        <stop offset="1" stopColor="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_1512_132" x1="21.5633" y1="4.50193" x2="19.3474" y2="-0.0391699" gradientUnits="userSpaceOnUse">
        <stop stopColor="#615EFF" />
        <stop offset="1" stopColor="#FF5EB0" />
      </linearGradient>
      <linearGradient id="paint4_linear_1512_132" x1="25.1142" y1="8.21337" x2="0.857751" y2="7.64537" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8360FD" />
        <stop offset="1" stopColor="#A037D9" />
      </linearGradient>
      <linearGradient id="paint5_linear_1512_132" x1="18.8982" y1="5.98689" x2="4.09295" y2="7.06249" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CFB9FE" />
        <stop offset="1" stopColor="#0029FF" />
      </linearGradient>
      <linearGradient id="paint6_linear_1512_132" x1="19.7439" y1="6.9533" x2="4.6326" y2="8.1685" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A69EFF" />
        <stop offset="1" stopColor="#2B91FE" />
      </linearGradient>
      <linearGradient id="paint7_linear_1512_132" x1="25.3905" y1="11.6067" x2="20.254" y2="1.30955" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C92FF" />
        <stop offset="1" stopColor="#0001C5" />
      </linearGradient>
      <linearGradient id="paint8_linear_1512_132" x1="26.5601" y1="16.4906" x2="16.3354" y2="-0.0791513" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2563EB" />
        <stop offset="0.308321" stopColor="#4C60FF" />
        <stop offset="0.802083" stopColor="#925FFF" />
      </linearGradient>
      <linearGradient id="paint9_linear_1512_132" x1="16.8985" y1="13.5821" x2="11.4357" y2="4.63853" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint10_linear_1512_132" x1="23.0919" y1="15.2301" x2="20.2638" y2="11.8823" gradientUnits="userSpaceOnUse">
        <stop offset="0.295908" stopColor="#D9D9D9" />
        <stop offset="0.667869" stopColor="#D9D9D9" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const ShareCircleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.6875 3.125C8.6875 1.77881 9.77881 0.6875 11.125 0.6875C12.4712 0.6875 13.5625 1.77881 13.5625 3.125C13.5625 4.47119 12.4712 5.5625 11.125 5.5625C10.4053 5.5625 9.75842 5.25057 9.31223 4.75453L7.27425 5.86615L4.99386 7.16923C5.19664 7.5248 5.3125 7.93637 5.3125 8.375C5.3125 8.59726 5.28275 8.81258 5.22702 9.01718L9.31223 11.2455C9.75842 10.7494 10.4053 10.4375 11.125 10.4375C12.4712 10.4375 13.5625 11.5288 13.5625 12.875C13.5625 14.2212 12.4712 15.3125 11.125 15.3125C9.77881 15.3125 8.6875 14.2212 8.6875 12.875C8.6875 12.6527 8.71725 12.4374 8.77298 12.2328L4.68778 10.0045C4.24159 10.5006 3.59472 10.8125 2.875 10.8125C1.52881 10.8125 0.4375 9.72119 0.4375 8.375C0.4375 7.02881 1.52881 5.9375 2.875 5.9375C3.36301 5.9375 3.81752 6.08091 4.19869 6.32789L6.72576 4.88385L8.77298 3.76719C8.71725 3.56258 8.6875 3.34727 8.6875 3.125ZM11.125 1.8125C10.4001 1.8125 9.8125 2.40013 9.8125 3.125C9.8125 3.84987 10.4001 4.4375 11.125 4.4375C11.8499 4.4375 12.4375 3.84987 12.4375 3.125C12.4375 2.40013 11.8499 1.8125 11.125 1.8125ZM2.875 7.0625C2.15013 7.0625 1.5625 7.65013 1.5625 8.375C1.5625 9.09987 2.15013 9.6875 2.875 9.6875C3.59987 9.6875 4.1875 9.09987 4.1875 8.375C4.1875 7.65013 3.59987 7.0625 2.875 7.0625ZM9.8125 12.875C9.8125 12.1501 10.4001 11.5625 11.125 11.5625C11.8499 11.5625 12.4375 12.1501 12.4375 12.875C12.4375 13.5999 11.8499 14.1875 11.125 14.1875C10.4001 14.1875 9.8125 13.5999 9.8125 12.875Z" fill="#3563EF" />
  </svg>
);

export const DownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M7.5 9.1665V14.1665L9.16667 12.4998" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.49967 14.1667L5.83301 12.5" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3337 8.33317V12.4998C18.3337 16.6665 16.667 18.3332 12.5003 18.3332H7.50033C3.33366 18.3332 1.66699 16.6665 1.66699 12.4998V7.49984C1.66699 3.33317 3.33366 1.6665 7.50033 1.6665H11.667" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3337 8.33317H15.0003C12.5003 8.33317 11.667 7.49984 11.667 4.99984V1.6665L18.3337 8.33317Z" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FilterRefresh = (props) => (
  <svg className="FilterRefresh" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <circle cx="7" cy="7" r="6.5" stroke="black" stroke-opacity="0.34" />
    <path d="M4.32327 4.50505C4.21765 4.47666 4.12987 4.40193 4.08367 4.30107C4.03746 4.20022 4.0376 4.08366 4.08405 3.98292L4.89903 2.21554C4.95976 2.08384 5.08978 1.99982 5.23259 2C5.37539 2.00018 5.50521 2.08453 5.56562 2.21638L5.92514 3.00108C5.938 2.99594 5.95127 2.99147 5.96492 2.98774C8.09828 2.40525 10.2911 3.69534 10.8627 5.86924C11.4344 8.04314 10.1683 10.2776 8.03499 10.8601C5.90163 11.4426 3.7088 10.1525 3.13717 7.97864C2.95539 7.28735 2.95948 6.58883 3.11869 5.9394C3.16795 5.73845 3.36775 5.61624 3.56496 5.66644C3.76216 5.71663 3.88209 5.92023 3.83283 6.12119C3.70313 6.65024 3.69956 7.21933 3.84817 7.78451C4.31459 9.55826 6.10379 10.6109 7.84447 10.1356C9.58515 9.66034 10.6181 7.83713 10.1517 6.06337C9.69295 4.31862 7.95427 3.2716 6.24085 3.69017L6.60359 4.4819C6.66401 4.61376 6.64411 4.76933 6.55255 4.88101C6.46099 4.99269 6.3143 5.04031 6.17622 5.00319L4.32327 4.50505Z" fill="#64748B" />
  </svg>
);

export const UnCheckedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#F5F5F5" stroke="#CDCDCD" />
  </svg>
);

export const CheckedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="4" fill="#2563EB" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0199 5.31344C12.2151 5.5087 12.2151 5.82529 12.0199 6.02055L7.35323 10.6872C7.15796 10.8825 6.84138 10.8825 6.64612 10.6872L3.97945 8.02055C3.78419 7.82529 3.78419 7.5087 3.97945 7.31344C4.17471 7.11818 4.4913 7.11818 4.68656 7.31344L6.99967 9.62655L11.3128 5.31344C11.508 5.11818 11.8246 5.11818 12.0199 5.31344Z" fill="white" />
  </svg>
);

export const CreatePaymentIcon = (props) => (
  <svg className="CreatePaymentIcon" xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
    <path d="M5 6.83301H8.66667" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.83301 8.66667V5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.15999 12.8C3.70666 12.2133 4.54 12.26 5.02 12.9L5.69333 13.8C6.23333 14.5133 7.10667 14.5133 7.64667 13.8L8.32 12.9C8.8 12.26 9.63333 12.2133 10.18 12.8C11.3667 14.0667 12.3333 13.6467 12.3333 11.8733V4.36C12.3333 1.67333 11.7067 1 9.18667 1H4.14667C1.62667 1 1 1.67333 1 4.36V11.8667C1.00667 13.6467 1.97999 14.06 3.15999 12.8Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="0.383789" y="1" width="19.5317" height="18.0292" rx="3.00487" transform="rotate(-0.09 0.383789 1)" fill="#EDEDED" />
    <path d="M13.2649 7.44336L7.69616 13.0296" stroke="#64748B" strokeWidth="1.12683" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.68741 7.45215L13.2737 13.0209" stroke="#64748B" strokeWidth="1.12683" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const UsersIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
    <path d="M12.0006 11.944V10.8885C12.0006 10.3286 11.7296 9.79164 11.247 9.39574C10.7645 8.99984 10.1101 8.77734 9.42771 8.77734H4.2819C3.59952 8.77734 2.94509 8.99984 2.46257 9.39574C1.98006 9.79164 1.70898 10.3286 1.70898 10.8885V11.944" stroke="#9AA6B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.45898 5.61133C7.77066 5.61133 8.83398 4.54801 8.83398 3.23633C8.83398 1.92465 7.77066 0.861328 6.45898 0.861328C5.14731 0.861328 4.08398 1.92465 4.08398 3.23633C4.08398 4.54801 5.14731 5.61133 6.45898 5.61133Z" stroke="#9AA6B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.1683 11.944V10.8651C15.168 10.387 15.0123 9.92254 14.7257 9.54464C14.4391 9.16684 14.0379 8.89694 13.585 8.77734" fill="#F1F1F1" />
    <path d="M15.1683 11.944V10.8651C15.168 10.387 15.0123 9.92254 14.7257 9.54464C14.4391 9.16684 14.0379 8.89694 13.585 8.77734" stroke="#9AA6B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.21 0.861328C11.6629 0.996348 12.0643 1.30305 12.351 1.73307C12.6377 2.16309 12.7933 2.69197 12.7933 3.23633C12.7933 3.78069 12.6377 4.30957 12.351 4.73959C12.0643 5.16961 11.6629 5.47631 11.21 5.61133" stroke="#9AA6B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const UserIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M5.75 5.75C7.06168 5.75 8.125 4.68668 8.125 3.375C8.125 2.06332 7.06168 1 5.75 1C4.43832 1 3.375 2.06332 3.375 3.375C3.375 4.68668 4.43832 5.75 5.75 5.75Z" stroke="#9AA6B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.2917 12.0837V11.0281C11.2917 10.4682 11.0206 9.93123 10.5381 9.53532C10.0556 9.13941 9.40113 8.91699 8.71875 8.91699H3.57292C2.89054 8.91699 2.23611 9.13941 1.75359 9.53532C1.27107 9.93123 1 10.4682 1 11.0281V12.0837" stroke="#9AA6B7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);


export const PlanTag = (props) => (
  <svg className="PlanTag" xmlns="http://www.w3.org/2000/svg" width="32" height="41" viewBox="0 0 32 41" fill="none">
    <g filter="url(#filter0_d_1593_103)">
      <path d="M12 0H20V33L16 28.3013L12 33V0Z" fill="#92989F" />
    </g>
    <g filter="url(#filter1_d_1593_103)">
      <circle cx="16" cy="16" r="8" fill="#3463EE" />
    </g>
    <path d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.0049 17.255C14.0949 17.405 14.2049 17.545 14.3299 17.67C15.2499 18.59 16.7449 18.59 17.6699 17.67C18.0449 17.295 18.2599 16.82 18.3299 16.335" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.6699 15.6656C13.7399 15.1756 13.9549 14.7056 14.3299 14.3306C15.2499 13.4106 16.7449 13.4106 17.6699 14.3306C17.7999 14.4606 17.9049 14.6006 17.9949 14.7456" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.9102 18.5899V17.2549H15.2451" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.0899 13.4102V14.7451H16.7549" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <filter id="filter0_d_1593_103" x="8" y="0" width="16" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1593_103" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1593_103" result="shape" />
      </filter>
      <filter id="filter1_d_1593_103" x="0" y="1" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1593_103" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1593_103" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const EditPen = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M10.3211 1.90789C9.06655 0.646322 7.84003 0.618287 6.55043 1.90789L5.75844 2.69286C5.68836 2.76295 5.66733 2.86107 5.69536 2.95219C6.18597 4.66932 7.55968 6.04303 9.27681 6.53363C9.29784 6.54064 9.33288 6.54064 9.35391 6.54064C9.424 6.54064 9.49408 6.51261 9.54314 6.46355L10.3211 5.67857C10.9589 5.04078 11.2743 4.43102 11.2743 3.80725C11.2743 3.17646 10.9589 2.55269 10.3211 1.90789Z" fill="white" />
    <path d="M8.10613 7.1239C7.9169 7.03279 7.73467 6.94167 7.56646 6.83654C7.42629 6.75244 7.28611 6.66132 7.15295 6.5632C7.04081 6.49312 6.91465 6.38798 6.7885 6.28285C6.77448 6.27585 6.73243 6.2408 6.67636 6.18473C6.45909 6.00951 6.2278 5.77122 6.01053 5.5119C5.99651 5.49788 5.95446 5.45583 5.91942 5.39275C5.84933 5.31565 5.7442 5.18249 5.65309 5.0353C5.57599 4.93718 5.48488 4.79701 5.40077 4.64983C5.29564 4.47461 5.20453 4.29939 5.12043 4.11716C5.02931 3.92092 4.95922 3.73869 4.89615 3.56348L1.12546 7.33416C0.880158 7.57946 0.641862 8.04204 0.592801 8.38547L0.291427 10.4741C0.228348 10.9156 0.347496 11.3291 0.620836 11.6025C0.852123 11.8337 1.16752 11.9529 1.51795 11.9529C1.59505 11.9529 1.67214 11.9459 1.74924 11.9389L3.83082 11.6445C4.17425 11.5955 4.63682 11.3642 4.88213 11.1119L8.65281 7.34117C8.47759 7.2851 8.30238 7.208 8.10613 7.1239Z" fill="white" />
  </svg>
);

export const CardEditIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.33337 1.33301H6.00004C2.66671 1.33301 1.33337 2.66642 1.33337 5.99994V10.0002C1.33337 13.3337 2.66671 14.6671 6.00004 14.6671H10C13.3334 14.6671 14.6667 13.3337 14.6667 10.0002V8.66676" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.6934 2.01355L5.44004 7.26719C5.24004 7.4672 5.04004 7.86055 5.00004 8.14724L4.71337 10.154C4.60671 10.8807 5.12004 11.3874 5.84671 11.2874L7.85337 11.0007C8.13337 10.9607 8.52671 10.7607 8.73337 10.5607L13.9867 5.30707C14.8934 4.40035 15.32 3.34696 13.9867 2.01355C12.6534 0.680141 11.6 1.10683 10.6934 2.01355Z" stroke="#64748B" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.93994 2.7666C10.3866 4.36003 11.6333 5.60676 13.2333 6.06012" stroke="#64748B" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const TrashIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665" stroke="#5A697D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.66663 3.31301L5.81329 2.43967C5.91996 1.80634 5.99996 1.33301 7.12663 1.33301H8.87329C9.99996 1.33301 10.0866 1.83301 10.1866 2.44634L10.3333 3.31301" stroke="#5A697D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5667 6.09375L12.1334 12.8071C12.06 13.8537 12 14.6671 10.14 14.6671H5.86002C4.00002 14.6671 3.94002 13.8537 3.86668 12.8071L3.43335 6.09375" stroke="#5A697D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.88672 11H9.10672" stroke="#5A697D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.33337 8.33301H9.66671" stroke="#5A697D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const EmailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path d="M3.735 13.15H12.265C13.8761 13.15 15.15 11.6379 15.15 9.82721V4.17279C15.15 2.3622 13.8762 0.850127 12.265 0.85H3.735C2.12389 0.85 0.85 2.36221 0.85 4.17279V9.82721C0.85 11.6379 2.1239 13.15 3.735 13.15ZM1.74571 4.17276C1.74571 2.86554 2.65838 1.84116 3.735 1.84116H12.2651C13.3418 1.84116 14.2544 2.86554 14.2544 4.17276L14.2543 9.82718C14.2543 11.1344 13.3416 12.1588 12.265 12.1588H3.735C2.65828 12.1588 1.74571 11.1344 1.74571 9.82718V4.17276Z" fill="#64748B" stroke="#64748B" strokeWidth="0.3" />
    <path d="M4.00425 3.77701L4.00427 3.77703L6.85268 6.13733C6.85268 6.13734 6.85268 6.13734 6.85269 6.13734C7.54094 6.70754 8.45991 6.70771 9.14811 6.13735L9.14812 6.13734L11.9965 3.77703C12.2069 3.60271 12.4965 3.6684 12.6355 3.89413L12.6355 3.89414C12.7673 4.10827 12.7299 4.40818 12.5343 4.57029L12.4386 4.45479L12.5343 4.57029L9.68585 6.9306C9.68585 6.9306 9.68585 6.9306 9.68585 6.9306C9.18447 7.34611 8.59372 7.5561 8.0004 7.5561C7.40708 7.5561 6.81633 7.34611 6.31495 6.9306L4.00425 3.77701ZM4.00425 3.77701C3.79407 3.60291 3.50442 3.66832 3.36537 3.89408L4.00425 3.77701ZM3.46653 4.57029L6.31495 6.9306L3.36533 3.89414C3.23333 4.10836 3.27098 4.40825 3.46653 4.57029C3.46653 4.57029 3.46653 4.57029 3.46653 4.57029Z" fill="#64748B" stroke="#64748B" strokeWidth="0.3" />
  </svg>
);

export const PhoneIcon2 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.80324 5.43964C3.23186 8.55227 5.77405 11.0269 8.93432 12.3678L8.9443 12.372L9.55483 12.6443C10.3323 12.9909 11.246 12.7447 11.7445 12.0545L12.7631 10.6439C12.8253 10.5577 12.8093 10.4379 12.7266 10.3712L10.9484 8.93573C10.86 8.86437 10.73 8.881 10.6623 8.9723L9.97025 9.90675C9.80217 10.1337 9.49863 10.2125 9.24144 10.0961C6.87837 9.02604 4.97944 7.12601 3.91002 4.76156C3.79363 4.50422 3.87244 4.20051 4.09925 4.03233L5.03314 3.33984C5.12439 3.27217 5.141 3.14211 5.06969 3.05365L3.63489 1.27412C3.56821 1.19142 3.44851 1.17535 3.36241 1.23755L1.945 2.26139C1.25057 2.763 1.00601 3.68501 1.36047 4.46515L1.80276 5.4386C1.80292 5.43895 1.80308 5.4393 1.80324 5.43964ZM8.46121 13.4701C5.02736 12.0117 2.26546 9.32201 0.713162 5.93917L0.712227 5.93714L0.269009 4.96164C-0.321761 3.66141 0.0858294 2.12472 1.24321 1.28871L2.66062 0.264869C3.2634 -0.170533 4.10126 -0.0580812 4.56805 0.520869L6.00284 2.3004C6.50206 2.91956 6.38578 3.83002 5.74701 4.30368L5.21187 4.70049C6.11582 6.4555 7.54848 7.889 9.30248 8.79348L9.69907 8.25802C10.1725 7.61887 11.0824 7.50253 11.7012 8.00204L13.4794 9.43746C14.0582 9.90467 14.1705 10.7433 13.7349 11.3465L12.7163 12.7571C11.8856 13.9075 10.3626 14.3178 9.06688 13.7401L8.46121 13.4701Z" fill="#64748B" />
  </svg>
);

export const AdorementEmail = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path d="M21.334 10.6665H10.6673C9.93398 10.6665 9.34065 11.2665 9.34065 11.9998L9.33398 19.9998C9.33398 20.7332 9.93398 21.3332 10.6673 21.3332H21.334C22.0673 21.3332 22.6673 20.7332 22.6673 19.9998V11.9998C22.6673 11.2665 22.0673 10.6665 21.334 10.6665ZM21.334 13.3332L16.0007 16.6665L10.6673 13.3332V11.9998L16.0007 15.3332L21.334 11.9998V13.3332Z" fill="#2E2C34" />
  </svg>
);

export const AdorementLock = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8707 13.1725L13.0994 15.2309L12.6366 15.2678C11.9912 15.3194 11.4642 15.8045 11.3596 16.4435C11.1184 17.9161 11.1184 19.4181 11.3596 20.8907C11.4642 21.5296 11.9912 22.0148 12.6366 22.0663L13.634 22.1459C15.2088 22.2716 16.791 22.2716 18.3658 22.1459L19.3632 22.0663C20.0086 22.0148 20.5356 21.5296 20.6402 20.8907C20.8814 19.4181 20.8814 17.9161 20.6402 16.4435C20.5356 15.8045 20.0086 15.3194 19.3632 15.2678L18.9003 15.2309L19.129 13.1725C19.156 12.9299 19.156 12.685 19.129 12.4423L19.1139 12.3057C18.9529 10.8568 17.8138 9.71035 16.3659 9.54001C16.1227 9.5114 15.877 9.5114 15.6338 9.54001C14.1859 9.71035 13.0469 10.8568 12.8859 12.3057L12.8707 12.4423C12.8437 12.685 12.8437 12.9299 12.8707 13.1725ZM16.2491 10.5332C16.0835 10.5137 15.9162 10.5137 15.7506 10.5332C14.7649 10.6491 13.9894 11.4297 13.8797 12.4161L13.8646 12.5527C13.8458 12.722 13.8458 12.8928 13.8646 13.0621L14.0971 15.1549C15.3643 15.0736 16.6354 15.0736 17.9026 15.1549L18.1352 13.0621C18.154 12.8928 18.154 12.722 18.1352 12.5527L18.12 12.4161C18.0104 11.4297 17.2349 10.6491 16.2491 10.5332ZM15.9999 17.6671C15.4476 17.6671 14.9999 18.1148 14.9999 18.6671C14.9999 19.2193 15.4476 19.6671 15.9999 19.6671C16.5522 19.6671 16.9999 19.2193 16.9999 18.6671C16.9999 18.1148 16.5522 17.6671 15.9999 17.6671Z" fill="black" />
  </svg>
);

export const AdorementUser = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path d="M16.0007 10.5C14.6199 10.5 13.5007 11.6193 13.5007 13C13.5007 14.3807 14.6199 15.5 16.0007 15.5C17.3814 15.5 18.5007 14.3807 18.5007 13C18.5007 11.6193 17.3814 10.5 16.0007 10.5Z" fill="#2E2C34" />
    <path d="M13.334 16.8333C11.9533 16.8333 10.834 17.9526 10.834 19.3333V20.1255C10.834 20.6277 11.1979 21.0558 11.6935 21.1367C14.5461 21.6025 17.4553 21.6025 20.3078 21.1367C20.8034 21.0558 21.1673 20.6277 21.1673 20.1255V19.3333C21.1673 17.9526 20.048 16.8333 18.6673 16.8333H18.4401C18.3171 16.8333 18.1949 16.8528 18.0779 16.891L17.5009 17.0794C16.5261 17.3977 15.4752 17.3977 14.5004 17.0794L13.9234 16.891C13.8064 16.8528 13.6842 16.8333 13.5612 16.8333H13.334Z" fill="#2E2C34" />
  </svg>
);

export const BuildingIcon = (props) => (
  <svg className="BuildingIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path d="M8 3.25V0.25H0.5V13.75H15.5V3.25H8ZM3.5 12.25H2V10.75H3.5V12.25ZM3.5 9.25H2V7.75H3.5V9.25ZM3.5 6.25H2V4.75H3.5V6.25ZM3.5 3.25H2V1.75H3.5V3.25ZM6.5 12.25H5V10.75H6.5V12.25ZM6.5 9.25H5V7.75H6.5V9.25ZM6.5 6.25H5V4.75H6.5V6.25ZM6.5 3.25H5V1.75H6.5V3.25ZM14 12.25H8V10.75H9.5V9.25H8V7.75H9.5V6.25H8V4.75H14V12.25ZM12.5 6.25H11V7.75H12.5V6.25ZM12.5 9.25H11V10.75H12.5V9.25Z" fill={props.color ? props.color : '#84818A'} />
  </svg>
);

export const AdorementPhone = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path d="M20.985 19.165C20.985 19.345 20.945 19.53 20.86 19.71C20.775 19.89 20.665 20.06 20.52 20.22C20.275 20.49 20.005 20.685 19.7 20.81C19.4 20.935 19.075 21 18.725 21C18.215 21 17.67 20.88 17.095 20.635C16.52 20.39 15.945 20.06 15.375 19.645C14.8 19.225 14.255 18.76 13.735 18.245C13.22 17.725 12.755 17.18 12.34 16.61C11.93 16.04 11.6 15.47 11.36 14.905C11.12 14.335 11 13.79 11 13.27C11 12.93 11.06 12.605 11.18 12.305C11.3 12 11.49 11.72 11.755 11.47C12.075 11.155 12.425 11 12.795 11C12.935 11 13.075 11.03 13.2 11.09C13.33 11.15 13.445 11.24 13.535 11.37L14.695 13.005C14.785 13.13 14.85 13.245 14.895 13.355C14.94 13.46 14.965 13.565 14.965 13.66C14.965 13.78 14.93 13.9 14.86 14.015C14.795 14.13 14.7 14.25 14.58 14.37L14.2 14.765C14.145 14.82 14.12 14.885 14.12 14.965C14.12 15.005 14.125 15.04 14.135 15.08C14.15 15.12 14.165 15.15 14.175 15.18C14.265 15.345 14.42 15.56 14.64 15.82C14.865 16.08 15.105 16.345 15.365 16.61C15.635 16.875 15.895 17.12 16.16 17.345C16.42 17.565 16.635 17.715 16.805 17.805C16.83 17.815 16.86 17.83 16.895 17.845C16.935 17.86 16.975 17.865 17.02 17.865C17.105 17.865 17.17 17.835 17.225 17.78L17.605 17.405C17.73 17.28 17.85 17.185 17.965 17.125C18.08 17.055 18.195 17.02 18.32 17.02C18.415 17.02 18.515 17.04 18.625 17.085C18.735 17.13 18.85 17.195 18.975 17.28L20.63 18.455C20.76 18.545 20.85 18.65 20.905 18.775C20.955 18.9 20.985 19.025 20.985 19.165Z" fill="#2E2C34" stroke="#2E2C34" strokeMiterlimit={10} />
  </svg>
);

export const PDFDownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
    <path d="M0 9.59473V13.503C0 15.9496 2.00179 17.9514 4.44843 17.9514H15.6966C18.1432 17.9514 20.145 15.9496 20.145 13.503V9.59473H18.874V13.503C18.874 15.2506 17.4442 16.6804 15.6966 16.6804H4.44843C2.70083 16.6804 1.27098 15.2506 1.27098 13.503V9.59473H0Z" fill="#A4A4A4" />
    <path d="M9.43729 0V11.4706L5.37015 7.37168L4.48047 8.26137L9.65971 13.4406C9.78681 13.5677 9.94568 13.6313 10.1046 13.6313C10.2634 13.6313 10.4223 13.5677 10.5494 13.4406L15.7286 8.26137L14.7754 7.37168L10.7083 11.4706V0H9.43729Z" fill="#A4A4A4" />
  </svg>
);
export const SearchIconUpdate = ({ width = "16", height = "16" }) => {
  // const theme = useTheme();
  return (
    <svg
      className="SearchIcon"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
    // fill={theme.palette.primary.light}
    >
      <g>
        <g>
          <path d="M370.068,63.494C329.123,22.549,274.686,0,216.781,0S104.438,22.549,63.494,63.494C22.549,104.438,0,158.876,0,216.78    c0,57.905,22.549,112.343,63.494,153.287c40.944,40.944,95.383,63.494,153.287,63.494s112.342-22.55,153.287-63.494    c40.944-40.944,63.494-95.382,63.494-153.287C433.561,158.876,411.012,104.438,370.068,63.494z M216.78,392.196    c-96.725,0-175.416-78.691-175.416-175.416S120.056,41.364,216.781,41.364s175.415,78.691,175.415,175.416    S313.505,392.196,216.78,392.196z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M505.943,476.693L369.981,340.732c-8.077-8.077-21.172-8.077-29.249,0c-8.076,8.077-8.076,21.172,0,29.249    l135.961,135.961c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.625-6.058    C514.019,497.865,514.019,484.77,505.943,476.693z" />
        </g>
      </g>
    </svg>
  );
};
export const CSFilterIcon = () => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 9.47286C21 9.86261 20.671 10.1789 20.2641 10.1789H10.671C10.671 10.1789 10.7273 9.73344 10.7273 9.45771C10.7273 9.18197 10.671 8.7668 10.671 8.7668H20.2641C20.671 8.7668 21 9.08311 21 9.47286ZM0.735931 2.77589H10.5022C10.5022 2.77589 10.4242 2.32435 10.4242 2.04862C10.4242 1.77288 10.5022 1.36377 10.5022 1.36377H0.735931C0.328961 1.36377 0 1.68008 0 2.06983C0 2.45958 0.328961 2.77589 0.735931 2.77589ZM21 2.06983C21 1.68008 20.671 1.36377 20.2641 1.36377H16.9048C16.9048 1.36377 16.9697 1.80319 16.9697 2.07892C16.9697 2.35465 16.9048 2.77589 16.9048 2.77589H20.2641C20.671 2.77589 21 2.45958 21 2.06983ZM0.735931 10.1789H4.2684C4.2684 10.1789 4.19697 9.73344 4.19697 9.45771C4.19697 9.18197 4.2684 8.7668 4.2684 8.7668H0.735931C0.328961 8.7668 0 9.08311 0 9.47286C0 9.86261 0.328961 10.1789 0.735931 10.1789ZM20.2641 16.2001H16.9264C16.9264 16.2001 16.9848 16.6335 16.9848 16.9092C16.9848 17.185 16.9264 17.6123 16.9264 17.6123H20.2641C20.671 17.6123 21 17.2959 21 16.9062C21 16.5164 20.671 16.2001 20.2641 16.2001ZM0 16.9062C0 17.2959 0.328961 17.6123 0.735931 17.6123H10.4632C10.3636 16.7123 10.4632 16.2001 10.4632 16.2001H0.735931C0.328961 16.2001 0 16.5164 0 16.9062Z" fill="#868789" />
      <circle cx="13.6516" cy="2.10606" r="2.10606" fill="#868789" />
      <circle cx="7.31822" cy="9.43931" r="2.10606" fill="#868789" />
      <circle cx="13.7121" cy="16.8031" r="2.10606" fill="#868789" />
    </svg>
  );
};
export const ButtonPlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 1V13"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7H13"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CTSortIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
    >
      <rect width="17.5997" height="1.84416" rx="0.922081" fill="#868789" />
      <rect
        y="7.45679"
        width="10.2632"
        height="1.84416"
        rx="0.922081"
        fill="#868789"
      />
      <rect
        x="15.4746"
        y="17.615"
        width="8.40056"
        height="1.84416"
        rx="0.922081"
        transform="rotate(-45.1153 15.4746 17.615)"
        fill="#868789"
      />
      <rect
        width="8.40056"
        height="1.84416"
        rx="0.922081"
        transform="matrix(-0.705682 -0.708529 -0.708529 0.705682 17.9785 17.6985)"
        fill="#868789"
      />
      <rect
        x="15.7559"
        y="18.6021"
        width="13.0294"
        height="1.84416"
        rx="0.922081"
        transform="rotate(-90 15.7559 18.6021)"
        fill="#868789"
      />
      <rect
        y="14.9138"
        width="8.33882"
        height="1.84416"
        rx="0.922081"
        fill="#868789"
      />
    </svg>
  );
};
export const CTSortChevron = () => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8L4.5 11L8 8"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4L4.5 1L1 4"
        stroke="white"
        strokeOpacity="0.52"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SummaryFilterIcon = ({ height = 19, width = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 19"
      fill="none"
    >
      <path
        d="M21 9.47286C21 9.86261 20.671 10.1789 20.2641 10.1789H10.671C10.671 10.1789 10.7273 9.73344 10.7273 9.45771C10.7273 9.18197 10.671 8.7668 10.671 8.7668H20.2641C20.671 8.7668 21 9.08311 21 9.47286ZM0.735931 2.77589H10.5022C10.5022 2.77589 10.4242 2.32435 10.4242 2.04862C10.4242 1.77288 10.5022 1.36377 10.5022 1.36377H0.735931C0.328961 1.36377 0 1.68008 0 2.06983C0 2.45958 0.328961 2.77589 0.735931 2.77589ZM21 2.06983C21 1.68008 20.671 1.36377 20.2641 1.36377H16.9048C16.9048 1.36377 16.9697 1.80319 16.9697 2.07892C16.9697 2.35465 16.9048 2.77589 16.9048 2.77589H20.2641C20.671 2.77589 21 2.45958 21 2.06983ZM0.735931 10.1789H4.2684C4.2684 10.1789 4.19697 9.73344 4.19697 9.45771C4.19697 9.18197 4.2684 8.7668 4.2684 8.7668H0.735931C0.328961 8.7668 0 9.08311 0 9.47286C0 9.86261 0.328961 10.1789 0.735931 10.1789ZM20.2641 16.2001H16.9264C16.9264 16.2001 16.9848 16.6335 16.9848 16.9092C16.9848 17.185 16.9264 17.6123 16.9264 17.6123H20.2641C20.671 17.6123 21 17.2959 21 16.9062C21 16.5164 20.671 16.2001 20.2641 16.2001ZM0 16.9062C0 17.2959 0.328961 17.6123 0.735931 17.6123H10.4632C10.3636 16.7123 10.4632 16.2001 10.4632 16.2001H0.735931C0.328961 16.2001 0 16.5164 0 16.9062Z"
        fill="#868789"
      />
      <circle cx="13.651" cy="2.10606" r="2.10606" fill="#868789" />
      <circle cx="7.31895" cy="9.43931" r="2.10606" fill="#868789" />
      <circle cx="13.7115" cy="16.8031" r="2.10606" fill="#868789" />
    </svg>
  );
};
export const ProfileEditIcon = (props) => (
  <svg
    className="ProfileEditIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M10.3214 1.90984C9.0668 0.648275 7.84027 0.62024 6.55067 1.90984L5.75869 2.69482C5.6886 2.7649 5.66757 2.86303 5.69561 2.95414C6.18622 4.67127 7.55992 6.04498 9.27706 6.53559C9.29808 6.5426 9.33313 6.5426 9.35415 6.5426C9.42424 6.5426 9.49433 6.51456 9.54339 6.4655L10.3214 5.68053C10.9591 5.04273 11.2745 4.43298 11.2745 3.8092C11.2745 3.17842 10.9591 2.55464 10.3214 1.90984Z"
      fill="white"
    />
    <path
      d="M8.10662 7.12683C7.91739 7.03572 7.73516 6.9446 7.56695 6.83947C7.42678 6.75537 7.2866 6.66425 7.15344 6.56613C7.0413 6.49605 6.91514 6.39091 6.78898 6.28578C6.77497 6.27878 6.73292 6.24373 6.67685 6.18766C6.45958 6.01244 6.22829 5.77415 6.01102 5.51483C5.997 5.50081 5.95495 5.45876 5.91991 5.39568C5.84982 5.31858 5.74469 5.18542 5.65357 5.03823C5.57648 4.94011 5.48537 4.79994 5.40126 4.65276C5.29613 4.47754 5.20502 4.30232 5.12091 4.12009C5.0298 3.92385 4.95971 3.74162 4.89663 3.56641L1.12595 7.33709C0.880646 7.58239 0.64235 8.04497 0.593289 8.3884L0.291915 10.477C0.228837 10.9185 0.347985 11.3321 0.621324 11.6054C0.852611 11.8367 1.168 11.9558 1.51844 11.9558C1.59553 11.9558 1.67263 11.9488 1.74973 11.9418L3.83131 11.6474C4.17474 11.5984 4.63731 11.3671 4.88262 11.1148L8.6533 7.3441C8.47808 7.28803 8.30287 7.21093 8.10662 7.12683Z"
      fill="white"
    />
  </svg>
);
export const UploadIcon2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
  >
    <path
      d="M7.31402 0L14.0793 6.76531L12.3881 8.45657L8.44211 4.5106V11.3267H6.05024V4.64652L2.2402 8.45657L0.548828 6.76531L7.31402 0Z"
      fill="white"
    />
    <path
      d="M0.548828 13.8477H14.1826V16.2395H0.548828V13.8477Z"
      fill="white"
    />
  </svg>
);

export const ExportIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M14.2516 0.582754C14.5735 0.260908 15.0953 0.260908 15.4171 0.582754C15.739 0.9046 15.739 1.42642 15.4171 1.74826L7.99989 9.16551C7.67804 9.48735 7.15623 9.48735 6.83438 9.16551C6.51254 8.84366 6.51253 8.32185 6.83438 8L14.2516 0.582754Z" fill="#64748B" />
    <path d="M14.0103 1.99203H10.7138C10.2586 1.99203 9.88965 1.62305 9.88965 1.16789C9.88965 0.712729 10.2586 0.34375 10.7138 0.34375H14.8345C15.2896 0.34375 15.6586 0.712729 15.6586 1.16789V5.28858C15.6586 5.74374 15.2896 6.11272 14.8345 6.11272C14.3793 6.11272 14.0103 5.74374 14.0103 5.28858V1.99203Z" fill="#64748B" />
    <path d="M12.3621 8.99346C12.3621 8.5383 12.7311 8.16932 13.1862 8.16932C13.6414 8.16932 14.0104 8.5383 14.0104 8.99346V13.6292C14.0104 14.9378 12.9495 15.9986 11.641 15.9986H2.3694C1.06082 15.9986 0 14.9378 0 13.6292L1.9649e-06 4.35767C1.9649e-06 3.04909 1.06082 1.98828 2.3694 1.98828C5.69228 1.98828 5.69228 1.98828 7.3915 1.98828C7.84666 1.98828 8.21563 2.35726 8.21563 2.81242C8.21563 3.26758 7.84666 3.63656 7.3915 3.63656C5.69228 3.63656 5.69228 3.63656 2.3694 3.63656C1.97113 3.63656 1.64828 3.95941 1.64828 4.35767L1.64828 13.6292C1.64828 14.0275 1.97113 14.3504 2.3694 14.3504H11.641C12.0392 14.3504 12.3621 14.0275 12.3621 13.6292V8.99346Z" fill="#64748B" />
  </svg>
);

export const EditIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M10.3401 1.66301C9.14251 0.459078 7.97165 0.432324 6.74057 1.66301L5.98453 2.41212C5.91762 2.479 5.89755 2.57264 5.92431 2.65959C6.39266 4.29827 7.70402 5.60921 9.34323 6.07741C9.3633 6.0841 9.39676 6.0841 9.41683 6.0841C9.48373 6.0841 9.55064 6.05734 9.59748 6.01052L10.3401 5.26141C10.949 4.65276 11.2501 4.07086 11.2501 3.47559C11.2501 2.87362 10.949 2.27835 10.3401 1.66301Z" fill="white" />
    <path d="M8.22585 6.63994C8.0452 6.55299 7.87124 6.46604 7.71067 6.36571C7.57685 6.28545 7.44304 6.1985 7.31592 6.10486C7.20887 6.03797 7.08844 5.93765 6.968 5.83732C6.95462 5.83063 6.91448 5.79719 6.86095 5.74368C6.65354 5.57647 6.43275 5.34906 6.22534 5.10159C6.21196 5.08821 6.17182 5.04808 6.13836 4.98788C6.07146 4.91431 5.9711 4.78723 5.88412 4.64677C5.81052 4.55313 5.72354 4.41936 5.64326 4.2789C5.5429 4.11169 5.45592 3.94448 5.37563 3.77058C5.28865 3.5833 5.22175 3.4094 5.16153 3.24219L1.56196 6.84059C1.32779 7.07469 1.10031 7.51613 1.05347 7.84387L0.765775 9.83703C0.705559 10.2584 0.8193 10.653 1.08023 10.9139C1.30103 11.1346 1.60211 11.2483 1.93664 11.2483C2.01023 11.2483 2.08383 11.2416 2.15743 11.2349L4.14455 10.954C4.47239 10.9072 4.91398 10.6865 5.14815 10.4457L8.74772 6.84728C8.58045 6.79377 8.41318 6.7202 8.22585 6.63994Z" fill="white" />
  </svg>
);

export const AgencyDashboard = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66675 18.3333H18.3334" stroke="#64748B" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.125 3.33341V18.3334H11.875V3.33341C11.875 2.41675 11.5 1.66675 10.375 1.66675H9.625C8.5 1.66675 8.125 2.41675 8.125 3.33341Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 8.33341V18.3334H5.83333V8.33341C5.83333 7.41675 5.5 6.66675 4.5 6.66675H3.83333C2.83333 6.66675 2.5 7.41675 2.5 8.33341Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1667 12.4999V18.3333H17.5001V12.4999C17.5001 11.5833 17.1667 10.8333 16.1667 10.8333H15.5001C14.5001 10.8333 14.1667 11.5833 14.1667 12.4999Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const SalesDashboard = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66699 1.66846V15.8351C1.66699 17.2185 2.78366 18.3351 4.16699 18.3351H18.3337" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.16699 14.1649L7.99199 9.69822C8.62533 8.96489 9.75033 8.91488 10.4337 9.60654L11.2253 10.3982C11.9087 11.0816 13.0337 11.0399 13.667 10.3065L17.5003 5.83154" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const LicenseDashboard = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.875 18.375H6.125C2.625 18.375 1.75 17.5 1.75 14V7C1.75 3.5 2.625 2.625 6.125 2.625H14.875C18.375 2.625 19.25 3.5 19.25 7V14C19.25 17.5 18.375 18.375 14.875 18.375Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.25 7H16.625" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.125 10.5H16.625" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.875 14H16.625" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.43727 9.87844C8.31195 9.87844 9.02102 9.16937 9.02102 8.29469C9.02102 7.42001 8.31195 6.71094 7.43727 6.71094C6.56258 6.71094 5.85352 7.42001 5.85352 8.29469C5.85352 9.16937 6.56258 9.87844 7.43727 9.87844Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 14.2878C10.3775 13.0191 9.37125 12.0216 8.1025 11.9078C7.665 11.8641 7.21875 11.8641 6.7725 11.9078C5.50375 12.0303 4.4975 13.0191 4.375 14.2878" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const FeatureDashboard = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.9035 1.83203H16.0877C19.351 1.83203 20.1668 2.64786 20.1668 5.90203V11.7045C20.1668 14.9679 19.351 15.7745 16.0968 15.7745H5.9035C2.64933 15.7837 1.8335 14.9679 1.8335 11.7137V5.90203C1.8335 2.64786 2.64933 1.83203 5.9035 1.83203Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 15.7852V20.1668" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.8335 11.918H20.1668" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.875 20.168H15.125" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const Filter = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.33323 6.66675H24.6666C24.8586 6.73409 25.0326 6.8445 25.1754 6.9895C25.3182 7.1345 25.4259 7.31026 25.4902 7.50331C25.5546 7.69636 25.5739 7.90157 25.5467 8.10324C25.5195 8.3049 25.4464 8.49766 25.3332 8.66675L18.6666 16.0001V25.3334L13.3332 21.3334V16.0001L6.66657 8.66675C6.55335 8.49766 6.48035 8.3049 6.45314 8.10324C6.42593 7.90157 6.44523 7.69636 6.50958 7.50331C6.57393 7.31026 6.68162 7.1345 6.82438 6.9895C6.96715 6.8445 7.14121 6.73409 7.33323 6.66675Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const FilterActive = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.33323 6.66675H24.6666C24.8586 6.73409 25.0326 6.8445 25.1754 6.9895C25.3182 7.1345 25.4259 7.31026 25.4902 7.50331C25.5546 7.69636 25.5739 7.90157 25.5467 8.10324C25.5195 8.3049 25.4464 8.49766 25.3332 8.66675L18.6666 16.0001V25.3334L13.3332 21.3334V16.0001L6.66657 8.66675C6.55335 8.49766 6.48035 8.3049 6.45314 8.10324C6.42593 7.90157 6.44523 7.69636 6.50958 7.50331C6.57393 7.31026 6.68162 7.1345 6.82438 6.9895C6.96715 6.8445 7.14121 6.73409 7.33323 6.66675Z" stroke="#2563EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)


export const ClearAction = () => (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.49044 1.15924C5.66985 0.979831 5.66985 0.688958 5.49044 0.509553C5.31104 0.330149 5.02016 0.330149 4.84076 0.509553L3 2.35032L1.15924 0.509554C0.979836 0.330149 0.688964 0.330149 0.509559 0.509554C0.330155 0.688958 0.330155 0.979831 0.509559 1.15924L2.35032 3L0.509553 4.84076C0.330149 5.02017 0.330149 5.31104 0.509553 5.49045C0.688958 5.66985 0.97983 5.66985 1.15923 5.49045L3 3.64968L4.84077 5.49045C5.02017 5.66985 5.31104 5.66985 5.49045 5.49045C5.66985 5.31104 5.66985 5.02017 5.49045 4.84076L3.64968 3L5.49044 1.15924Z" fill="#64748B" />
  </svg>
)



export const DownArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.28033 0.21967C9.57322 0.512563 9.57322 0.987437 9.28033 1.28033L5.28033 5.28033C4.98744 5.57322 4.51256 5.57322 4.21967 5.28033L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512564 0.21967 0.21967C0.512563 -0.073223 0.987437 -0.073223 1.28033 0.21967L4.75 3.68934L8.21967 0.21967C8.51256 -0.0732233 8.98744 -0.0732233 9.28033 0.21967Z" fill="#64748B" />
    </svg>
  );
};

export const TrialIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="12" rx="6" fill="#2563EB" />
      <path d="M5.44118 3C5.19753 3 5 3.19753 5 3.44118V6.38239C5 6.54554 5.08891 6.6875 5.22059 6.76382L7.51382 8.08736C7.7248 8.20919 7.994 8.13753 8.11584 7.92652C8.23766 7.71551 8.16598 7.44631 7.955 7.32449L5.88236 6.12962V3.44118C5.88236 3.19753 5.68484 3 5.44118 3Z" fill="white" />
    </svg>
  )

}

export const CopyIcon = (props) => (
  <svg class="CopyIcon" fill={props.fill || '#7e84a3'} width={props.width || "10"} height={props.height || "10"} focusable="false" aria-hidden="true" viewBox="0 0 24 24"{...props}>
    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>
);
export const AgencyListIcon = (props) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5665 7.15011V12.8501C16.5665 13.7834 16.0665 14.6501 15.2581 15.1251L10.3081 17.9834C9.4998 18.4501 8.4998 18.4501 7.68314 17.9834L2.73314 15.1251C1.9248 14.6584 1.4248 13.7917 1.4248 12.8501V7.15011C1.4248 6.21678 1.9248 5.35008 2.73314 4.87508L7.68314 2.01675C8.49147 1.55008 9.49147 1.55008 10.3081 2.01675L15.2581 4.87508C16.0665 5.35008 16.5665 6.20844 16.5665 7.15011Z" stroke="#2563EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.99977 9.16676C10.0721 9.16676 10.9414 8.29743 10.9414 7.22508C10.9414 6.15272 10.0721 5.28345 8.99977 5.28345C7.92742 5.28345 7.05811 6.15272 7.05811 7.22508C7.05811 8.29743 7.92742 9.16676 8.99977 9.16676Z" stroke="#2563EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.3332 13.8834C12.3332 12.3834 10.8415 11.1667 8.99984 11.1667C7.15817 11.1667 5.6665 12.3834 5.6665 13.8834" stroke="#2563EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export const Userslogo = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9999 5.96675C14.9499 5.95842 14.8916 5.95842 14.8416 5.96675C13.6916 5.92508 12.7749 4.98342 12.7749 3.81675C12.7749 2.62508 13.7332 1.66675 14.9249 1.66675C16.1166 1.66675 17.0749 2.63342 17.0749 3.81675C17.0666 4.98342 16.1499 5.92508 14.9999 5.96675Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1417 12.0333C15.2834 12.225 16.5417 12.025 17.425 11.4333C18.6 10.65 18.6 9.36664 17.425 8.58331C16.5334 7.99164 15.2584 7.79163 14.1167 7.99163" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.97539 5.96675C5.02539 5.95842 5.08372 5.95842 5.13372 5.96675C6.28372 5.92508 7.20039 4.98342 7.20039 3.81675C7.20039 2.62508 6.24206 1.66675 5.05039 1.66675C3.85873 1.66675 2.90039 2.63342 2.90039 3.81675C2.90872 4.98342 3.82539 5.92508 4.97539 5.96675Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.83353 12.0333C4.69186 12.225 3.43353 12.025 2.5502 11.4333C1.3752 10.65 1.3752 9.36664 2.5502 8.58331C3.44186 7.99164 4.71686 7.79163 5.85853 7.99163" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.9999 12.1916C9.9499 12.1833 9.89157 12.1833 9.84157 12.1916C8.69157 12.1499 7.7749 11.2083 7.7749 10.0416C7.7749 8.84994 8.73323 7.8916 9.9249 7.8916C11.1166 7.8916 12.0749 8.85827 12.0749 10.0416C12.0666 11.2083 11.1499 12.1583 9.9999 12.1916Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5751 14.8166C6.4001 15.5999 6.4001 16.8832 7.5751 17.6666C8.90843 18.5582 11.0918 18.5582 12.4251 17.6666C13.6001 16.8832 13.6001 15.5999 12.4251 14.8166C11.1001 13.9332 8.90843 13.9332 7.5751 14.8166Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export const ArrowIcon = (props) => {
  return (
    <svg width="9" height="12" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.71252 1.56043L6.60253 6.45043C7.18003 7.02793 7.18003 7.97293 6.60253 8.55043L1.71252 13.4404" stroke="#A2B0B9" strokeWidth="2" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  )
}
export const UpdateIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9602 1.04344C9.59144 -0.332482 8.25331 -0.363058 6.84637 1.04344L5.98232 1.89956C5.90585 1.976 5.88291 2.08302 5.9135 2.18239C6.44875 4.05516 7.94746 5.55339 9.82084 6.08846C9.84378 6.09611 9.88201 6.09611 9.90495 6.09611C9.98141 6.09611 10.0579 6.06553 10.1114 6.01202L10.9602 5.1559C11.656 4.4603 12.0001 3.79527 12.0001 3.11495C12.0001 2.427 11.656 1.74668 10.9602 1.04344Z" fill="#64748B" />
      <path d="M8.54382 6.73324C8.33737 6.63387 8.13856 6.5345 7.95505 6.41984C7.80212 6.32811 7.64919 6.22874 7.50391 6.12172C7.38156 6.04528 7.24393 5.93062 7.10629 5.81596C7.091 5.80832 7.04512 5.7701 6.98395 5.70895C6.74691 5.51785 6.49457 5.25795 6.25753 4.97513C6.24224 4.95984 6.19636 4.91397 6.15813 4.84518C6.08167 4.76109 5.96697 4.61586 5.86756 4.45533C5.78345 4.34832 5.68405 4.19544 5.59229 4.03491C5.4776 3.84382 5.37819 3.65272 5.28643 3.45397C5.18703 3.23994 5.11057 3.0412 5.04175 2.8501L0.927956 6.96256C0.66033 7.2301 0.400351 7.7346 0.346825 8.10916L0.0180281 10.3871C-0.05079 10.8686 0.0791995 11.3196 0.377411 11.6177C0.629744 11.87 0.973835 11.9999 1.35616 11.9999C1.44027 11.9999 1.52438 11.9923 1.60849 11.9847L3.87949 11.6636C4.25416 11.6101 4.75883 11.3579 5.02646 11.0827L9.14025 6.9702C8.94909 6.90905 8.75792 6.82497 8.54382 6.73324Z" fill="#64748B" />
    </svg>
  )
}

export const DeleteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.33 16.5H13.66" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 12.5H14.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const FeatureFlag = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66663 7.08334H11.25" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 13.75H6.66667" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.75 13.75H12.0833" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.3333 10.025V13.425C18.3333 16.35 17.5916 17.0833 14.6333 17.0833H5.36663C2.40829 17.0833 1.66663 16.35 1.66663 13.425V6.57499C1.66663 3.64999 2.40829 2.91666 5.36663 2.91666H11.25" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.75 5.20834H18.3333" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M16.0416 7.49999V2.91666" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}

export const FeatureIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8848 13.3317H7.71814" stroke="#64748B" strokeWidth="1.2" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.80139 11.2484V15.415" stroke="#64748B" strokeWidth="1.2" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.55 2.09844L10.525 2.15677L8.1083 7.7651H5.7333C5.16663 7.7651 4.62497 7.88177 4.1333 8.0901L5.59163 4.60677L5.62497 4.52344L5.6833 4.3901C5.69997 4.3401 5.71663 4.2901 5.74163 4.24844C6.8333 1.72344 8.06663 1.14844 10.55 2.09844Z" stroke="#64748B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.0416 7.93178C14.6666 7.81512 14.2666 7.76512 13.8666 7.76512H8.10828L10.5249 2.15678L10.5499 2.09845C10.6749 2.14012 10.7916 2.19845 10.9166 2.24845L12.7583 3.02345C13.7833 3.44845 14.4999 3.89012 14.9333 4.42345C15.0166 4.52345 15.0833 4.61512 15.1416 4.72345C15.2166 4.84012 15.2749 4.95678 15.3083 5.08178C15.3416 5.15678 15.3666 5.23178 15.3833 5.29845C15.6083 5.99845 15.4749 6.85678 15.0416 7.93178Z" stroke="#64748B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.9347 11.832V13.457C17.9347 13.6236 17.9264 13.7903 17.9181 13.957C17.7597 16.8653 16.1347 18.332 13.0514 18.332H6.55139C6.35139 18.332 6.15139 18.3153 5.95972 18.2903C3.30972 18.1153 1.89306 16.6986 1.71806 14.0486C1.69306 13.857 1.67639 13.657 1.67639 13.457V11.832C1.67639 10.157 2.69306 8.71529 4.14306 8.09029C4.64306 7.88196 5.17639 7.76529 5.74306 7.76529H13.8764C14.2847 7.76529 14.6847 7.82362 15.0514 7.93196C16.7097 8.44029 17.9347 9.99029 17.9347 11.832Z" stroke="#64748B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.59163 4.60678L4.13329 8.09011C2.68329 8.71511 1.66663 10.1568 1.66663 11.8318V9.39011C1.66663 7.02345 3.34996 5.04845 5.59163 4.60678Z" stroke="#64748B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.9322 9.38972V11.8314C17.9322 9.99806 16.7155 8.43972 15.0488 7.93972C15.4822 6.85639 15.6072 6.00639 15.3988 5.29806C15.3822 5.22306 15.3572 5.14806 15.3238 5.08139C16.8738 5.88139 17.9322 7.52306 17.9322 9.38972Z" stroke="#64748B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const ArrowUp = () => {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.23414 4.69659C7.31554 4.78613 7.42155 4.83016 7.52897 4.83016C7.63376 4.83016 7.74566 4.78793 7.82494 4.69533C7.97931 4.52417 7.97893 4.24686 7.8238 4.07622L5.29463 1.29414C5.13412 1.11758 4.86549 1.11758 4.70498 1.29414L2.17581 4.07622C2.0203 4.24728 2.0203 4.52553 2.17581 4.69659C2.33632 4.87315 2.60496 4.87315 2.76547 4.69659L4.9998 2.23882L7.23414 4.69659Z" fill="#292D32" stroke="#292D32" strokeWidth="0.2" />
      <path d="M4.5875 9.39539C4.5875 9.62935 4.76518 9.83914 5 9.83914C5.23482 9.83914 5.4125 9.62935 5.4125 9.39539V1.68164C5.4125 1.44768 5.23482 1.23789 5 1.23789C4.76518 1.23789 4.5875 1.44768 4.5875 1.68164V9.39539Z" fill="#292D32" stroke="#292D32" strokeWidth="0.2" />
    </svg>
  )
}


export const UserMangment = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10.0002 10.0003C12.3013 10.0003 14.1668 8.13485 14.1668 5.83366C14.1668 3.53247 12.3013 1.66699 10.0002 1.66699C7.69898 1.66699 5.8335 3.53247 5.8335 5.83366C5.8335 8.13485 7.69898 10.0003 10.0002 10.0003Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.0085 13.1162L13.0585 16.0662C12.9418 16.1829 12.8335 16.3995 12.8085 16.5579L12.6502 17.6828C12.5919 18.0912 12.8752 18.3745 13.2835 18.3162L14.4085 18.1579C14.5668 18.1329 14.7919 18.0245 14.9002 17.9079L17.8502 14.9579C18.3585 14.4495 18.6002 13.8579 17.8502 13.1079C17.1085 12.3662 16.5169 12.6079 16.0085 13.1162Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5835 13.541C15.8335 14.441 16.5335 15.141 17.4335 15.391" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.8418 18.3333C2.8418 15.1083 6.05015 12.5 10.0002 12.5C10.8668 12.5 11.7001 12.625 12.4751 12.8583" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const InvoiceIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.0475 14.775C4.6625 14.115 5.6 14.1675 6.14 14.8875L6.8975 15.9C7.505 16.7025 8.4875 16.7025 9.095 15.9L9.8525 14.8875C10.3925 14.1675 11.33 14.115 11.945 14.775C13.28 16.2 14.3675 15.7275 14.3675 13.7325V5.28C14.375 2.2575 13.67 1.5 10.835 1.5H5.165C2.33 1.5 1.625 2.2575 1.625 5.28V13.725C1.625 15.7275 2.72 16.1925 4.0475 14.775Z" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.07205 8.25H5.07879" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.17383 8.25H11.2988" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.07205 5.25H5.07879" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.17383 5.25H11.2988" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  )
}

export const BackIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699" stroke="#2A2A2A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20.4999 12H3.66992" stroke="#2A2A2A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export const CrossArrow = () => {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33353 6.53415L8.53714 1.53079L3.53379 1.32718" stroke="#2563EB" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.24556 8.25191L8.46411 1.5979" stroke="#2563EB" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export const Avatar = ({ src, alt, className }) => {
  return (
    <div className={className} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px' }}>
      {src ? (
        <img
          src={src}
          alt={alt}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            objectFit: 'cover' // Ensures the image covers the container while maintaining aspect ratio
          }}
        />
      ) : (
        <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#E4E4E4" stroke="#EBEAED" />
          <path d="M10.0002 6.5625C9.13722 6.5625 8.43766 7.26206 8.43766 8.125C8.43766 8.98794 9.13722 9.6875 10.0002 9.6875C10.8631 9.6875 11.5627 8.98794 11.5627 8.125C11.5627 7.26206 10.8631 6.5625 10.0002 6.5625Z" fill="#2E2C34" fill-opacity="0.46" />
          <path d="M8.3335 10.5208C7.47055 10.5208 6.771 11.2204 6.771 12.0833V12.5785C6.771 12.8923 6.99845 13.1599 7.30819 13.2105C9.09104 13.5015 10.9093 13.5015 12.6921 13.2105C13.0019 13.1599 13.2293 12.8923 13.2293 12.5785V12.0833C13.2293 11.2204 12.5298 10.5208 11.6668 10.5208H11.5248C11.4479 10.5208 11.3715 10.533 11.2985 10.5568L10.9378 10.6746C10.3285 10.8736 9.67178 10.8736 9.06249 10.6746L8.70186 10.5568C8.62878 10.533 8.5524 10.5208 8.47552 10.5208H8.3335Z" fill="#2E2C34" fill-opacity="0.46" />
        </svg>
      )}
    </div>
  );
};

export const AffiliateDashboard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
    <path d="M10.5448 16.9315C10.5231 16.9285 10.4953 16.9285 10.4706 16.9315C9.92664 16.913 9.49396 16.468 9.49396 15.9209C9.49396 15.3615 9.94518 14.9072 10.5077 14.9072C11.0671 14.9072 11.5214 15.3615 11.5214 15.9209C11.5183 16.468 11.0887 16.913 10.5448 16.9315Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.5905 18.9712C12.0403 19.4749 11.311 19.7809 10.5074 19.7809C9.70387 19.7809 8.9745 19.4749 8.42438 18.9712C8.45528 18.6807 8.64072 18.3963 8.97141 18.1738C9.81822 17.6113 11.2028 17.6113 12.0434 18.1738C12.3741 18.3963 12.5596 18.6807 12.5905 18.9712Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5076 19.7807C12.2144 19.7807 13.5981 18.397 13.5981 16.6902C13.5981 14.9833 12.2144 13.5996 10.5076 13.5996C8.80069 13.5996 7.41699 14.9833 7.41699 16.6902C7.41699 18.397 8.80069 19.7807 10.5076 19.7807Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17.3439 10.1308C17.3223 10.1277 17.2945 10.1277 17.2698 10.1308C16.7258 10.1122 16.2932 9.66718 16.2932 9.12015C16.2932 8.56076 16.7444 8.10645 17.3069 8.10645C17.8663 8.10645 18.3206 8.56076 18.3206 9.12015C18.3175 9.66718 17.8879 10.1122 17.3439 10.1308Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M19.3901 12.1704C18.84 12.6742 18.1106 12.9801 17.3071 12.9801C16.5036 12.9801 15.7742 12.6742 15.2241 12.1704C15.255 11.8799 15.4404 11.5956 15.7711 11.373C16.6179 10.8106 18.0025 10.8106 18.8431 11.373C19.1738 11.5956 19.3592 11.8799 19.3901 12.1704Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17.3068 12.9809C19.0137 12.9809 20.3974 11.5972 20.3974 9.89037C20.3974 8.1835 19.0137 6.7998 17.3068 6.7998C15.5999 6.7998 14.2162 8.1835 14.2162 9.89037C14.2162 11.5972 15.5999 12.9809 17.3068 12.9809Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5444 3.9501C10.5228 3.94701 10.495 3.94701 10.4703 3.9501C9.92633 3.93156 9.49365 3.48652 9.49365 2.93949C9.49365 2.38009 9.94488 1.92578 10.5074 1.92578C11.0668 1.92578 11.5211 2.38009 11.5211 2.93949C11.518 3.48652 11.0884 3.93156 10.5444 3.9501Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.5904 5.98974C12.0403 6.4935 11.3109 6.79947 10.5074 6.79947C9.70381 6.79947 8.97444 6.4935 8.42432 5.98974C8.45522 5.69922 8.64066 5.41489 8.97135 5.19237C9.81816 4.62989 11.2027 4.62989 12.0434 5.19237C12.3741 5.41489 12.5595 5.69922 12.5904 5.98974Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5076 6.80027C12.2144 6.80027 13.5981 5.41658 13.5981 3.70971C13.5981 2.00283 12.2144 0.619141 10.5076 0.619141C8.80069 0.619141 7.41699 2.00283 7.41699 3.70971C7.41699 5.41658 8.80069 6.80027 10.5076 6.80027Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.74556 10.1308C3.72392 10.1277 3.69611 10.1277 3.67138 10.1308C3.12744 10.1122 2.69476 9.66718 2.69476 9.12015C2.69476 8.56076 3.14599 8.10645 3.70847 8.10645C4.26786 8.10645 4.72218 8.56076 4.72218 9.12015C4.71908 9.66718 4.2895 10.1122 3.74556 10.1308Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.79182 12.1704C5.2417 12.6742 4.51232 12.9801 3.70877 12.9801C2.90523 12.9801 2.17585 12.6742 1.62573 12.1704C1.65664 11.8799 1.84207 11.5956 2.17276 11.373C3.01958 10.8106 4.40415 10.8106 5.24479 11.373C5.57548 11.5956 5.76091 11.8799 5.79182 12.1704Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.70867 12.9809C5.41554 12.9809 6.79924 11.5972 6.79924 9.89037C6.79924 8.1835 5.41554 6.7998 3.70867 6.7998C2.0018 6.7998 0.618103 8.1835 0.618103 9.89037C0.618103 11.5972 2.0018 12.9809 3.70867 12.9809Z" stroke="#64748B" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.598 4.01855C14.4221 4.63667 16.1322 6.12014 16.3795 7.10912" stroke="#64748B" stroke-width="0.8" />
    <path d="M16.3794 12.6719C15.9673 13.496 14.7723 15.268 13.2888 15.7624" stroke="#64748B" stroke-width="0.8" />
    <path d="M4.63556 12.6719C5.04763 13.496 6.24265 15.268 7.72613 15.7624" stroke="#64748B" stroke-width="0.8" />
    <path d="M4.63556 7.10912C4.8416 6.49101 5.68635 5.00754 7.41707 4.01855" stroke="#64748B" stroke-width="0.8" />
  </svg>
)

export const Affiliates = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M17.5667 7.15035V12.8503C17.5667 13.7837 17.0667 14.6504 16.2584 15.1254L11.3084 17.9837C10.5 18.4504 9.50005 18.4504 8.68338 17.9837L3.73338 15.1254C2.92505 14.6587 2.42505 13.792 2.42505 12.8503V7.15035C2.42505 6.21702 2.92505 5.35032 3.73338 4.87532L8.68338 2.01699C9.49172 1.55033 10.4917 1.55033 11.3084 2.01699L16.2584 4.87532C17.0667 5.35032 17.5667 6.20869 17.5667 7.15035Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 9.16651C11.0724 9.16651 11.9417 8.29719 11.9417 7.22483C11.9417 6.15248 11.0724 5.2832 10 5.2832C8.92766 5.2832 8.05835 6.15248 8.05835 7.22483C8.05835 8.29719 8.92766 9.16651 10 9.16651Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.3334 13.8836C13.3334 12.3836 11.8417 11.167 10.0001 11.167C8.15841 11.167 6.66675 12.3836 6.66675 13.8836" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const Conversions = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
      <path d="M15 7.5875C14.95 7.57813 14.8917 7.57813 14.8417 7.5875C13.6917 7.54063 12.775 6.48125 12.775 5.16875C12.775 3.82813 13.7334 2.75 14.925 2.75C16.1167 2.75 17.075 3.8375 17.075 5.16875C17.0667 6.48125 16.15 7.54063 15 7.5875Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.1417 14.4127C15.2834 14.6283 16.5417 14.4033 17.425 13.7376C18.6 12.8564 18.6 11.4127 17.425 10.5314C16.5334 9.86578 15.2584 9.64077 14.1167 9.86577" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.97503 7.5875C5.02503 7.57813 5.08336 7.57813 5.13336 7.5875C6.28336 7.54063 7.20002 6.48125 7.20002 5.16875C7.20002 3.82813 6.24169 2.75 5.05003 2.75C3.85836 2.75 2.90002 3.8375 2.90002 5.16875C2.90836 6.48125 3.82503 7.54063 4.97503 7.5875Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.83334 14.4127C4.69168 14.6283 3.43335 14.4033 2.55001 13.7376C1.37501 12.8564 1.37501 11.4127 2.55001 10.5314C3.44168 9.86578 4.71668 9.64077 5.85834 9.86577" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.99996 14.5904C9.94996 14.5811 9.89163 14.5811 9.84163 14.5904C8.69163 14.5436 7.77496 13.4842 7.77496 12.1717C7.77496 10.8311 8.73329 9.75293 9.92496 9.75293C11.1166 9.75293 12.075 10.8404 12.075 12.1717C12.0666 13.4842 11.15 14.5529 9.99996 14.5904Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.57498 17.5432C6.39998 18.4244 6.39998 19.8682 7.57498 20.7494C8.90831 21.7525 11.0916 21.7525 12.425 20.7494C13.6 19.8682 13.6 18.4244 12.425 17.5432C11.1 16.5494 8.90831 16.5494 7.57498 17.5432Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const Commissions = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.22656 11.9412C7.22656 13.0162 8.05156 13.8829 9.07656 13.8829H11.1682C12.0599 13.8829 12.7849 13.1245 12.7849 12.1912C12.7849 11.1745 12.3432 10.8162 11.6849 10.5829L8.32656 9.41621C7.66823 9.18288 7.22656 8.82454 7.22656 7.80788C7.22656 6.87454 7.95156 6.11621 8.84323 6.11621H10.9349C11.9599 6.11621 12.7849 6.98288 12.7849 8.05788" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 5V15" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 18.3337C14.6024 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6024 1.66699 10 1.66699C5.39765 1.66699 1.66669 5.39795 1.66669 10.0003C1.66669 14.6027 5.39765 18.3337 10 18.3337Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const Payouts = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.49998 15.8333C7.49998 16.4583 7.32498 17.05 7.01664 17.55C6.44164 18.5167 5.38331 19.1667 4.16665 19.1667C2.94998 19.1667 1.89165 18.5167 1.31665 17.55C1.00831 17.05 0.833313 16.4583 0.833313 15.8333C0.833313 13.9917 2.32498 12.5 4.16665 12.5C6.00831 12.5 7.49998 13.9917 7.49998 15.8333Z" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.8678 15.8333L3.6928 16.6582L5.4678 15.0166" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.7928 5.8753C14.5928 5.84197 14.3845 5.83364 14.1678 5.83364H5.83449C5.60115 5.83364 5.37615 5.85031 5.15948 5.88364C5.27615 5.65031 5.44282 5.43367 5.64282 5.23367L8.35115 2.51699C9.49282 1.38366 11.3428 1.38366 12.4845 2.51699L13.9428 3.99196C14.4762 4.51696 14.7595 5.18363 14.7928 5.8753Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.3334 9.99967V14.1663C18.3334 16.6663 16.6667 18.333 14.1667 18.333H6.35835C6.61669 18.1163 6.84169 17.8497 7.01669 17.5497C7.32502 17.0497 7.50002 16.458 7.50002 15.833C7.50002 13.9913 6.00835 12.4997 4.16669 12.4997C3.16669 12.4997 2.27502 12.9413 1.66669 13.633V9.99967C1.66669 7.73301 3.03335 6.14967 5.15835 5.88301C5.37502 5.84967 5.60002 5.83301 5.83335 5.83301H14.1667C14.3834 5.83301 14.5917 5.84133 14.7917 5.87466C16.9417 6.12466 18.3334 7.71634 18.3334 9.99967Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.3334 10.417H15.8334C14.9167 10.417 14.1667 11.167 14.1667 12.0837C14.1667 13.0003 14.9167 13.7503 15.8334 13.7503H18.3334" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const EditIcon2 = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9602 1.04344C9.59144 -0.332482 8.25331 -0.363058 6.84637 1.04344L5.98232 1.89956C5.90585 1.976 5.88291 2.08302 5.9135 2.18239C6.44875 4.05516 7.94746 5.55339 9.82084 6.08846C9.84378 6.09611 9.88201 6.09611 9.90495 6.09611C9.98141 6.09611 10.0579 6.06553 10.1114 6.01202L10.9602 5.1559C11.656 4.4603 12.0001 3.79527 12.0001 3.11495C12.0001 2.427 11.656 1.74668 10.9602 1.04344Z" fill="#64748B" />
      <path d="M8.54382 6.73373C8.33737 6.63436 8.13856 6.53499 7.95505 6.42033C7.80212 6.3286 7.64919 6.22923 7.50391 6.12221C7.38156 6.04577 7.24393 5.93111 7.10629 5.81645C7.091 5.80881 7.04512 5.77059 6.98395 5.70944C6.74691 5.51834 6.49457 5.25844 6.25753 4.97561C6.24224 4.96033 6.19636 4.91446 6.15813 4.84567C6.08167 4.76158 5.96697 4.61635 5.86756 4.45582C5.78345 4.34881 5.68405 4.19593 5.59229 4.0354C5.4776 3.8443 5.37819 3.6532 5.28643 3.45446C5.18703 3.24043 5.11057 3.04169 5.04175 2.85059L0.927956 6.96305C0.66033 7.23059 0.400351 7.73509 0.346825 8.10965L0.0180281 10.3876C-0.05079 10.8691 0.0791995 11.3201 0.377411 11.6182C0.629744 11.8705 0.973835 12.0004 1.35616 12.0004C1.44027 12.0004 1.52438 11.9928 1.60849 11.9851L3.87949 11.6641C4.25416 11.6106 4.75883 11.3583 5.02646 11.0832L9.14025 6.97069C8.94909 6.90954 8.75792 6.82546 8.54382 6.73373Z" fill="#64748B" />
    </svg>
  )
}
export const TickIcon = () => {
  return (
    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" width="34.1484" height="32" rx="7" fill="#2563EB" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0178 11.9697C25.3404 12.2626 25.3404 12.7374 25.0178 13.0303L17.3068 20.0303C16.9842 20.3232 16.4611 20.3232 16.1385 20.0303L11.7322 16.0303C11.4096 15.7374 11.4096 15.2626 11.7322 14.9697C12.0549 14.6768 12.578 14.6768 12.9006 14.9697L16.7227 18.4393L23.8494 11.9697C24.172 11.6768 24.6951 11.6768 25.0178 11.9697Z" fill="white" />
    </svg>
  )
}

export const EditIcon3 = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33301 1.33337H5.99967C2.66634 1.33337 1.33301 2.66678 1.33301 6.00031V10.0005C1.33301 13.3341 2.66634 14.6675 5.99967 14.6675H9.99967C13.333 14.6675 14.6663 13.3341 14.6663 10.0005V8.66713" stroke="#2563EB" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6933 2.01343L5.43992 7.26706C5.23992 7.46707 5.03992 7.86043 4.99992 8.14711L4.71325 10.1539C4.60659 10.8806 5.11992 11.3873 5.84659 11.2873L7.85325 11.0006C8.13325 10.9606 8.52659 10.7606 8.73325 10.5606L13.9866 5.30695C14.8933 4.40023 15.3199 3.34684 13.9866 2.01343C12.6533 0.680018 11.5999 1.10671 10.6933 2.01343Z" stroke="#2563EB" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
export const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="#94A3B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7 4.33337V7.00004" stroke="#94A3B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7 9.66663H7.00667" stroke="#94A3B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const MoreIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4" fill="none">
    <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="#94A3B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z" stroke="#94A3B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z" stroke="#94A3B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const FaqIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M17.5667 7.14938V12.8493C17.5667 13.7827 17.0667 14.6494 16.2584 15.1244L11.3084 17.9827C10.5 18.4494 9.50005 18.4494 8.68338 17.9827L3.73338 15.1244C2.92505 14.6577 2.42505 13.791 2.42505 12.8493V7.14938C2.42505 6.21604 2.92505 5.34934 3.73338 4.87434L8.68338 2.01602C9.49172 1.54935 10.4917 1.54935 11.3084 2.01602L16.2584 4.87434C17.0667 5.34934 17.5667 6.20771 17.5667 7.14938Z" stroke="#2563EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 9.16651C11.0724 9.16651 11.9417 8.29719 11.9417 7.22483C11.9417 6.15248 11.0724 5.2832 10 5.2832C8.92766 5.2832 8.05835 6.15248 8.05835 7.22483C8.05835 8.29719 8.92766 9.16651 10 9.16651Z" stroke="#2563EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.3334 13.8826C13.3334 12.3826 11.8417 11.166 10.0001 11.166C8.15841 11.166 6.66675 12.3826 6.66675 13.8826" stroke="#2563EB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const FaqCopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const AlertSuccessIcon = () => {
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="11" fill="#0CAF2F" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.90234 11.368L9.22289 14.6871L15.8612 8.04883" fill="#0CAF2F" />
    <path d="M5.90234 11.368L9.22289 14.6871L15.8612 8.04883" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

}

export const AlertFailedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0007 19.1663C15.0423 19.1663 19.1673 15.0413 19.1673 9.99967C19.1673 4.95801 15.0423 0.833008 10.0007 0.833008C4.95898 0.833008 0.833984 4.95801 0.833984 9.99967C0.833984 15.0413 4.95898 19.1663 10.0007 19.1663Z" fill="#FF3B3B" />
      <path d="M10 6.33301V10.9163" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.99414 13.667H10.0031" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
};

export const FlozySupportIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H12" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.2 4.62008C14.87 3.63008 15.26 2.41008 16.34 2.07008C16.9 1.90008 17.6 2.04008 18 2.57008C18.38 2.02008 19.1 1.90008 19.66 2.07008C20.74 2.40008 21.13 3.63008 20.8 4.62008C20.29 6.19008 18.5 7.00008 18 7.00008C17.5 7.00008 15.73 6.20008 15.2 4.62008Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.9965 11H16.0054" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.9955 11H12.0045" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.99451 11H8.00349" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export const UserCreationIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19.49 17.98H16.51" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18 16.52V19.51" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export const InvoiceDownloadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 11V17L11 15" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 17L7 15" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
export const ReportsCalenderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none" style={{ marginLeft: "6px" }}>
      <path d="M6.6665 1.66675V4.16675" stroke="#64748B" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.3335 1.66675V4.16675" stroke="#64748B" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.9165 7.57495H17.0832" stroke="#64748B" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.5 7.08341V14.1667C17.5 16.6667 16.25 18.3334 13.3333 18.3334H6.66667C3.75 18.3334 2.5 16.6667 2.5 14.1667V7.08341C2.5 4.58341 3.75 2.91675 6.66667 2.91675H13.3333C16.25 2.91675 17.5 4.58341 17.5 7.08341Z" stroke="#64748B" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.0791 11.4167H13.0866" stroke="#64748B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.0791 13.9167H13.0866" stroke="#64748B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.99607 11.4167H10.0036" stroke="#64748B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.99607 13.9167H10.0036" stroke="#64748B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.91209 11.4167H6.91957" stroke="#64748B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.91209 13.9167H6.91957" stroke="#64748B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const PasswordSettingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM17.25 12C17.25 12.23 17.23 12.46 17.2 12.68L18.68 13.84C18.81 13.95 18.85 14.14 18.76 14.29L17.36 16.71C17.27 16.86 17.09 16.92 16.93 16.86L15.19 16.16C14.83 16.44 14.43 16.67 14.01 16.85L13.75 18.7C13.72 18.87 13.57 19 13.4 19H10.6C10.43 19 10.28 18.87 10.25 18.71L9.99 16.86C9.56 16.68 9.17 16.45 8.81 16.17L7.07 16.87C6.91 16.93 6.73 16.87 6.64 16.72L5.24 14.3C5.15 14.15 5.19 13.96 5.32 13.85L6.8 12.69C6.77 12.46 6.75 12.23 6.75 12C6.75 11.77 6.77 11.54 6.8 11.32L5.32 10.16C5.19 10.05 5.15 9.86 5.24 9.71L6.64 7.29C6.73 7.14 6.91 7.08 7.07 7.14L8.81 7.84C9.17 7.56 9.57 7.33 9.99 7.15L10.25 5.3C10.28 5.13 10.43 5 10.6 5H13.4C13.57 5 13.72 5.13 13.75 5.29L14.01 7.14C14.44 7.32 14.83 7.55 15.19 7.83L16.93 7.13C17.09 7.07 17.27 7.13 17.36 7.28L18.76 9.7C18.85 9.85 18.81 10.04 18.68 10.15L17.2 11.31C17.23 11.54 17.25 11.77 17.25 12Z" fill="#84818A" />
    </svg>

  )
}
export const LogOutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path opacity="0.4" d="M9 7.2V16.79C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2H14.2C11 2 9 4 9 7.2Z" fill="#292D32" />
      <path d="M5.56994 8.11953L2.21994 11.4695C1.92994 11.7595 1.92994 12.2395 2.21994 12.5295L5.56994 15.8795C5.85994 16.1695 6.33994 16.1695 6.62994 15.8795C6.91994 15.5895 6.91994 15.1095 6.62994 14.8195L4.55994 12.7495H15.2499C15.6599 12.7495 15.9999 12.4095 15.9999 11.9995C15.9999 11.5895 15.6599 11.2495 15.2499 11.2495H4.55994L6.62994 9.17953C6.77994 9.02953 6.84994 8.83953 6.84994 8.64953C6.84994 8.45953 6.77994 8.25953 6.62994 8.11953C6.33994 7.81953 5.86994 7.81953 5.56994 8.11953Z" fill="#292D32" />
    </svg>
  )
}
export const ProfileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM15 9C15 10.6575 13.6575 12 12 12C10.3425 12 9 10.6575 9 9C9 7.3425 10.3425 6 12 6C13.6575 6 15 7.3425 15 9ZM6 16.6667C6 14.8933 9.9975 14 12 14C14.0025 14 18 14.8933 18 16.6667V18H6V16.6667Z" fill="#84818A" />
    </svg>
  )
}

export const PasswordEyeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.42004 13.9799 8.42004 11.9999C8.42004 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M12 20.2697C15.53 20.2697 18.82 18.1897 21.11 14.5897C22.01 13.1797 22.01 10.8097 21.11 9.39973C18.82 5.79973 15.53 3.71973 12 3.71973C8.46997 3.71973 5.17997 5.79973 2.88997 9.39973C1.98997 10.8097 1.98997 13.1797 2.88997 14.5897C5.17997 18.1897 8.46997 20.2697 12 20.2697Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  )
}
export const PasswordCloseEyeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.53 9.46992L9.47001 14.5299C8.82001 13.8799 8.42001 12.9899 8.42001 11.9999C8.42001 10.0199 10.02 8.41992 12 8.41992C12.99 8.41992 13.88 8.81992 14.53 9.46992Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M17.82 5.77047C16.07 4.45047 14.07 3.73047 12 3.73047C8.47 3.73047 5.18 5.81047 2.89 9.41047C1.99 10.8205 1.99 13.1905 2.89 14.6005C3.68 15.8405 4.6 16.9105 5.6 17.7705" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M8.42001 19.5297C9.56001 20.0097 10.77 20.2697 12 20.2697C15.53 20.2697 18.82 18.1897 21.11 14.5897C22.01 13.1797 22.01 10.8097 21.11 9.39969C20.78 8.87969 20.42 8.38969 20.05 7.92969" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M15.51 12.7002C15.25 14.1102 14.1 15.2602 12.69 15.5202" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M9.47 14.5303L2 22.0003" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22 2L14.53 9.47" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  )
}

export const ThumbsDownIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.6" d="M2.35002 13.96C2.74002 14.52 3.43002 14.84 4.22002 14.84H8.32002C8.59002 14.84 8.84002 14.95 9.01002 15.15C9.19002 15.35 9.26002 15.63 9.22002 15.92L8.71002 19.2C8.49002 20.18 9.14002 21.28 10.12 21.61C11.03 21.95 12.1 21.49 12.53 20.84L16.74 14.57L16.86 14.37V5.53003L16.71 5.38003L13.54 2.93003C13.12 2.51003 12.17 2.28003 11.5 2.28003H7.60002C6.26002 2.28003 4.91002 3.29003 4.61002 4.52003L2.15002 12.01C1.90002 12.72 1.97002 13.42 2.35002 13.96Z" fill="#EA4B99" />
      <path d="M18.7904 17.61H19.8204C21.3704 17.61 22.0004 17.01 22.0004 15.53V5.48002C22.0004 4.00002 21.3704 3.40002 19.8204 3.40002H18.7904C17.2404 3.40002 16.6104 4.00002 16.6104 5.48002V15.54C16.6104 17.01 17.2404 17.61 18.7904 17.61Z" fill="#EA4B99" />
    </svg>

  )
}
export const ThumbsupIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.6" d="M21.6499 10.03C21.2599 9.46997 20.5699 9.14997 19.7799 9.14997H15.6799C15.4099 9.14997 15.1599 9.03998 14.9899 8.83998C14.8099 8.63998 14.7399 8.35997 14.7799 8.06997L15.2899 4.78997C15.5099 3.80997 14.8599 2.70998 13.8799 2.37998C12.9699 2.03998 11.8999 2.49998 11.4699 3.14998L7.24988 9.41998L7.12988 9.61998V18.46L7.27988 18.61L10.4499 21.06C10.8699 21.48 11.8199 21.71 12.4899 21.71H16.3899C17.7299 21.71 19.0799 20.7 19.3799 19.47L21.8399 11.98C22.0999 11.27 22.0299 10.58 21.6499 10.03Z" fill="#0E8E2A" />
      <path d="M5.21 6.38H4.18C2.63 6.38 2 6.98 2 8.46V18.52C2 20 2.63 20.6 4.18 20.6H5.21C6.76 20.6 7.39 20 7.39 18.52V8.46C7.39 6.98 6.76 6.38 5.21 6.38Z" fill="#0E8E2A" />
    </svg>

  )
}
export const TotalagenciesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#FFFBEB"/>
    <path d="M24 24C26.7614 24 29 21.7614 29 19C29 16.2386 26.7614 14 24 14C21.2386 14 19 16.2386 19 19C19 21.7614 21.2386 24 24 24Z" stroke="#F6A723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.5901 34C32.5901 30.13 28.7402 27 24.0002 27C19.2602 27 15.4102 30.13 15.4102 34" stroke="#F6A723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}
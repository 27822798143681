import React from 'react'
// import PropTypes from "prop-types";
import { Grid, Typography, styled } from '@mui/material'
import FormSubHeader from "./formSubHeaderStyles";

const FormSubHeaderComp = (props) => {
    const { className } = props
    return (
        <Grid className={`${className} formSubHeaderComp`}>
            <Typography>Please confirm here the feature created by you will reflect in the Sales Dashboard</Typography>
        </Grid>
    )
}

FormSubHeaderComp.defaultProps = {

}
FormSubHeaderComp.protoTypes = {

}
export default styled(FormSubHeaderComp)(FormSubHeader)




import { createAsyncThunk } from "@reduxjs/toolkit";
import { createFeature, getFeature, getFeatureById, deleteFeatureById ,updateFeatureById} from "../services/featureService"
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const createfeatureAsync = createAsyncThunk("feature/create", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Feature creating..."))
        const response = await createFeature(data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})
export const getfeatureAsync = createAsyncThunk("feature/getAll", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching Features..."))
        const response = await getFeature(data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})
export const getFeatureByIdAsync = createAsyncThunk("feature/getById", async (param, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching Feature..."))
        const response = await getFeatureById(param);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})

export const deleteFeatureByIdAsync = createAsyncThunk("feature/deleteById", async (param, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Deleteing Feature..."))
        const response = await deleteFeatureById(param);
   
        dispatch(setLoading(null))
        dispatch(getfeatureAsync())
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})

export const updateFeatureByIdAsync = createAsyncThunk("feature/updateById", async ( {id, data} , { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Updating Feature..."));
        const response = await updateFeatureById(id, data);
        dispatch(setLoading(null));
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
});


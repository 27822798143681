

import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import CombinedLayout from "../layouts/AgencyLayout"; 
import FaqSupport from "../containers/Faq";

const FaqRoutes = [
    {
        path: '/faq',
        element: <ProtectedRoute/>,
        children: [
            {
                element: <CombinedLayout />, 
                children: [
                    {
                        path: '',
                        children: [
                            {
                                path: '',
                                element: < FaqSupport/>
                            },
                            {
                                path: 'create',
                                // element: 
                            },
                            {
                                path: 'edit/:id',
                                // element: 
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default FaqRoutes;

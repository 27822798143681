import  instance1 from "../../utils/api1";

async function getAllAddons(data) {
    return instance1.post('featureFlag/getAll', data)
}
async function createAgencyAddons(data) {
    return instance1.post('featureFlag/create', data)
}
async function updateAgencyAddons(param,data) {
    return instance1.put(`featureFlag/${param}`, data)
}
async function deleteAgencyAddons(param,data) {
    return instance1.delete(`featureFlag/${param}`)
}
export const agencyAddonsService = {
    getAllAddons,
    createAgencyAddons,
    updateAgencyAddons,
    deleteAgencyAddons
}
import { createAsyncThunk } from "@reduxjs/toolkit";

import { setAlert, setLoading } from "../reducers/uiReducer";
import { updateUserPassword } from "../services/passwordUpdateService";

export const updatePasswordAction = createAsyncThunk(
  "auth/updateUserProfile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading("Updating user profile...")); 

      const response = await updateUserPassword(data);
      dispatch(setLoading(null)); 
      return response;  

    } catch (err) {
      dispatch(setLoading(null)); 
      const errorMessage = err?.message || "Failed to update user profile.";
      
      dispatch(setAlert({ message: errorMessage, type: "error" })); 
      return rejectWithValue(errorMessage); 
    }
     
  }
);

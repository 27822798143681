import instance2 from "../../utils/api"
async function getAllFaqs(data) {
    return instance2.post('faqs/getAll',data);
}


async function createSupportFaq(data) {
    return instance2.post('faqs/create', data);
}

async function updateSupportFaq(id, data) {
    return instance2.put(`faqs/update/${id}`, data);
}


export {
    getAllFaqs,
    createSupportFaq,
    updateSupportFaq
};


import { createSlice } from "@reduxjs/toolkit";
import { createApplicationModuleAsync, getAllApplicationModulesAsync } from "../actions/applicationModuleAction";

const initialState = {
    list: {
        results: [],
        pagination: {
            count: 10,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
    },
    moduleById: {},
    loading: null,
};

const applicationModuleSlice = createSlice({
    name: "applicationModule",
    initialState,
    reducers: {
        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createApplicationModuleAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(createApplicationModuleAsync.fulfilled, (state, { payload }) => {
                state.list.results.push(payload); // Assuming you want to add the new module to the list
                state.loading = false;
            })
            .addCase(createApplicationModuleAsync.rejected, (state) => {
                state.loading = false;
            })
            builder
            .addCase(getAllApplicationModulesAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllApplicationModulesAsync.fulfilled, (state, { payload }) => {
                state.list.results = payload.modules; // Assuming the API returns a "modules" array
                state.loading = false;
            })
            .addCase(getAllApplicationModulesAsync.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { setPageSize, setPage } = applicationModuleSlice.actions;
export default applicationModuleSlice.reducer;

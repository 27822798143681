import React from 'react'
import {Grid, Typography} from '@mui/material';
const FormHeader = () => {
  return (
    <Grid>
    <Typography variant='h4'>New User Group</Typography>
    </Grid>
  )
}

export default FormHeader
const profileStyles = () => ({

    ".profile_container": {
        width: "100%",
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 0px 4px 0px #0000000F",
        padding: "20px",
        paddingRight: "20px"
    },
    ".profile_name": {
        display: "flex",
        paddingTop: "20px",
        justifyContent: "space-between",
        gap: "10px",
    },
    ".profile_description": {
        paddingTop: "20px",
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
    },
    ".pricing_details": {
        paddingTop: "25px",
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
    },
    ".profile_addons": {
        paddingTop: "25px",
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
    },
    ".profile_add_details": {
        paddingTop: "25px",
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
    },
    ".plan_code_type": {
        display: "flex",
        justifyContent: "center"
    },
    ".ActiveStatus": {
        padding: "10px 30px",
        backgroundColor: "#D4F6D2",
        color: "#0E8E2A",
        borderRadius: "7px",
        fontSize: "14px",
        fontWeight: "500",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "10px"
    },
    ".profile-end": {
        borderBottom: "1px solid #F2F0F0",
        paddingBottom: "10px"
    },
    ".profile-detail": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width:"40%"
    },
    ".plan-details": {
        display: "flex",
        gap: "50px",
        paddingBottom: "10px"
    },

    ".error_text": {
        color: 'red'
    },
    ".activeCell-Price": {
        color: "#2563EB",
        fontSize: "18px",
        fontWeight: "600",

    },
    disableStatus: {
        padding: "10px 30px",
        backgroundColor: "#D4F6D2",
        color: "red",
        borderRadius: "7px",
        fontSize: "14px",
        fontWeight: "500",
    },
    active_status: {
        backgroundColor: "#dcfff1",
        color: "#206e4e"
    },
   ".user_profile":{
    color: "#73757A" 
   }
})
export default profileStyles

import { createAsyncThunk } from "@reduxjs/toolkit";

import { setAlert, setLoading } from "../reducers/uiReducer";
import { fetchUserProfile, updateUserProfile } from "../services/userProfileService";

export const userProfile = createAsyncThunk(
  "auth/userProfile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading("Fetching user profile...")); 

      const response = await fetchUserProfile(data); 
      dispatch(setLoading(null)); 

      return response; 
    } catch (err) {
      dispatch(setLoading(null)); 
      const errorMessage = err?.message || "Failed to fetch user profile.";
      
      dispatch(setAlert({ message: errorMessage, type: "error" })); 
      return rejectWithValue(errorMessage); 
    }
  }
);

export const updateUserProfileAction = createAsyncThunk(
  "auth/updateUserProfile",
  async (data, { dispatch, rejectWithValue }) => {
    console.log("🚀 ~ datahbhjbhj:", data)
    try {
      dispatch(setLoading("Updating user profile...")); 

      const response = await updateUserProfile(data);
      dispatch(setLoading(null)); 
      return response;  

    } catch (err) {
      dispatch(setLoading(null)); 
      const errorMessage = err?.message || "Failed to update user profile.";
      
      dispatch(setAlert({ message: errorMessage, type: "error" })); 
      return rejectWithValue(errorMessage); 
    }
     
  }
);

import React, { useEffect, useState } from "react";
import { styled} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getconversions } from "../../../store/actions/referralAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  clearFilter,
} from "../../../store/reducers/referralReducer";
import Table from "../../../components/Table";
import { rulesToParams } from "../../../components/Filter/filterUtil";
import moment from 'moment';
import { ActionCell } from "../../../components/MoreAction/MoreAction";
import { useLocation } from "react-router-dom"

const styles = {
  ".avatar-cell": {
    display: "flex",
    alignItems: "center",
    ".avatar-title": {
      marginLeft: "8px",
    },
  },
  ".copy-icon": {
    cursor: "pointer",
    transition: "color 0.3s",
  },
  ".copy-icon:hover": {
    color: "#007bff",
  },
  ".copied-icon": {
    color: "green",
    transition: "color 0.3s",
  },
};




const CreatedOnCell = ({ item }) => {
  const formatDate = (isoDate) => {
    return moment(isoDate).format('DD MMMM YYYY');
  };
  return <span>{formatDate(item)}</span>;
};

const StatusCell = (props) => {
  const {rowData } = props;
  let renderText = ""; 
  let color = "#FFFFFF"; 
  let textColor = "#000000"; 
  
  if (rowData.status === "Cancelled") {
    renderText = "Cancelled";
    color = "#FFDFDD";
    textColor = "#FF3B3B";
  } else if (rowData.status === "Active") {
    renderText = "Active";
    color = "#D4F6D2";
    textColor = "#038420";
  } else if (rowData.status === "TRIAL") {
    renderText = "TRIAL";
    color = "#BDD3FF";
    textColor = "#2563EB";
  }

  const width = "100px";

  return (
    <span
      style={{
        display: "inline-block",
        width: width,
        textAlign: "center",
        color: textColor,
        backgroundColor: color,
        borderRadius: "6px",
        padding: "8px 10px",
      }}
    >
      {renderText}
    </span>
  );
};



const cellMap = {
  trial: StatusCell,
  customerModifiedOn: CreatedOnCell,
  action: ActionCell,

};

const Conversion = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.referral);
  const { pagination, ordering, orderBy, filter} = list;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = list.search || queryParams.get('search') || '';
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    paymentMode: []
  })

  const filters = [
    { key: "status", label: "Status", options: [{ value: '0', label: "Active" }, { value: '1', label: "Trial" }, { value: '2', label: "Cancelled" }], },

  ];

  useEffect(() => {
    let keysearch = "";
    let keyfilter = "";
    if (search) {
      keysearch = `&search=${search}`;
    }
    if (filter.params) {
      keyfilter = `&filter=${filter.params}`;
    }
    dispatch(
      getconversions(
        { ...pagination, search, ...selectedFilters, ordering: list.ordering }
      )
    );

  }, [dispatch, pagination.count,
    pagination?.page_size,
    pagination?.page_number,
    search, ordering,
    filter.params,
    pagination?.num_pages,
    list.ordering
  ]);

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  const onFilter = (reason, data) => {
    switch (reason) {
      case "add":
        dispatch(addFilterRule());
        break;
      case "remove":
        dispatch(removeFilterRule(data));
        break;
      case "update":
        dispatch(updateFilterRule(data));
        break;
      case "apply":
        dispatch(applyFilter(rulesToParams(filter.rules)));
        break;
      case "clear":
        dispatch(clearFilter());
        break;
      default:
        return;
    }
  };
  const applyFilter = () => {
    dispatch(
      getconversions(
        { ...pagination, search, ...selectedFilters, ordering: list.ordering }
      )
    );
  }
  return (
    <div className={className}>
      <Table
        title={"Conversion"}
        headers={list?.headers}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        filter={filters}
        onFilter={onFilter}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filterOptions={filters}
        applyFilter={applyFilter}
        isSort
        isFilter
        isSearch={true}
      />
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

export default styled(Conversion)(styles);

import {Grid,styled,}from "@mui/material";
import Style from '../style';
import Table from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import {ActionCell} from "../../../components/MoreAction/MoreAction"
import { useEffect } from "react";
import { useDispatch,useSelector } from 'react-redux';
// import {getAllUserGroup} from ".././../../store/actions/authUserGroupActions"
import { getAllAuthUsers } from "../../../store/actions/authAction";
import SwitchComponent from '../../../components/Switch';
import { Search } from "@mui/icons-material";
import { setPageSize ,setPage} from "../../../store/reducers/authReducer";
const UserCreation = (props) => {
const navigate = useNavigate()
const dispatch = useDispatch();
const {list} = useSelector((s)=>s.auth);
const{user,pagination}=list
  const userManagementHeader = [
    { field: "name", label: "Name" },
    { field: "email", label: "Email" },
    { field: "created_at", label: "Date" },
   

  ];

 
    const {className} = props
    const group_name = ({ item }) => {
      return <span>{item}</span>;
    };
    
    const tags = ({ item }) => {
      return <SwitchComponent
      checked={item?.features_enabled}

    
  />;
    };
    const cellMap = {
      group_name: group_name,
      tags:tags,
  
      action: ActionCell,
    };
    function onEdit(data){
      navigate(`/userManagement/${data?.id}/edit`)
    }
    useEffect(() => {
      dispatch(getAllAuthUsers({...pagination,search:""})); // Ensure this is being called
    }, [
      dispatch,
      pagination.page_size,
      pagination.page_number,
    ]);
    const handlePagination = (e, pageValue) => {
      dispatch(setPage(pageValue));
    };
  
    const handlePageSize = (e) => {
      dispatch(setPageSize(e.target.value));
    };
  

    
  return (
    <Grid className={className}>
      <Table
      title = {"User Creation"}
      headers = {userManagementHeader}
      pagination={pagination}
      handlePagination={handlePagination}
      handlePageSize={handlePageSize}
      buttonText={"+ Create New User"}
      createUrl={"/userManagement/create/user"}
      data={user}
      cellMap={cellMap}
      onEdit={onEdit}
      />
    </Grid>
  )
}

export default styled(UserCreation)(Style)
import instance2 from "../../utils/api"

async function createAuthUserGroup(data){
    return instance2.post('authUserGroup/create',data)
}

async function getAllUserGroup(data){
    return instance2.post('authUserGroup/getAll',data)
}
async function getAllUserGroupById(data){
    return instance2.get(`authUserGroup/${data}`)
}
// for creating user grouo
async function createAuthUserGroupDetail(data){
    return instance2.post('userGroupDetail/create',data)
}
async function updateAuthUserGroupDetails(data){
    return instance2.patch('userGroupDetail/update/permissions',data)
}
async function updateAuthUserGroupUser(data){
    return instance2.patch('userGroupDetail/update/users',data)
}
async function updateAuthuserGroupService(data) {
    return instance2.put('userGroupDetail/update/groupdetails',data)
}
export {
    createAuthUserGroup,
    createAuthUserGroupDetail,
    getAllUserGroup,
    getAllUserGroupById,
    updateAuthUserGroupDetails,
    updateAuthUserGroupUser,
    updateAuthuserGroupService
}
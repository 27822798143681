import theme from "../../theme";

const Style = () => ({
  "autocomplete": {
    height: '40px',
    width: '1150px',
    paddingLeft: '150px',
  },
  "textField": {
    width: '100%',
  },
  "option": {
    display: 'flex',
    alignItems: 'center',
    paddingTop:"10px !important",
    paddingBottom:"5px !important",
    paddingLeft:"0px !important" ,
    paddingRight:"0px !important", 
    cursor: 'pointer',
    listStyle: 'none',
    outline: 'none',
    borderBottom: '1px solid #E6E6E6',
    backgroundColor: 'white',
  //   "& .css-1a5cv8g-MuiListItem-root" :{
  //       paddingTop:"0px",
  //       paddingBottom:"0px",
  //       paddingLeft:"0px" ,
  //       paddingRight:"0px"       
  // },
},
  "optionText": {
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 'auto',
    display: 'block',
  },
  "optionName": {
    fontWeight: 500,
  },
  "optionEmail": {
    fontSize: '0.875rem',
  },
  "optionDate": {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
  },
  "logo": {
    marginRight: theme.spacing(1),  
  },
  "invoiceIcon": {
    marginRight: theme.spacing(1), 
  },
  "groupItems": {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    "heading":{
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-between",
      alignItems:"center",
     padding:"10px 0px",
      gap:"4px",
    }
  },
  
  "groupHeader": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#D6E6FF',
    padding: '2px', 
    paddingLeft:'8px',
    paddingRight:'8px',
    borderRadius:'8px',
    marginLeft:'8px',
    color: '#2563EB',
    fontSize: '16px',
  },
  "viewAllButton": {
    width: '89px', 
    height: '28px !important', 
    padding: '0 8px !important', 
    fontSize: '14px !important', 
    marginRight:'10px !important',
    gap:"4px"
  },
  "icon": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1), 
  },
  "Date": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end", 
    textAlign: 'center',
  },
  "agencytext": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "min-content",
    textAlign: 'center', 
  }, 
  "invoicetext":{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "min-content",
    textAlign: 'center',
  },
  "leftAlign": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection:"row",
    gap:"10px", 
    paddingLeft: theme.spacing(2), 
    paddingRight: theme.spacing(1), 
  },
  "centerAlign": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
    paddingLeft: theme.spacing(2), 
    paddingRight: theme.spacing(2), 
  },
  "rightAlign": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', 
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(2), 
  },
  "showAllButton": {     
    height: '36px',   
    width:"126px",        
    backgroundColor: '#2563EB !important', 
    color: '#FFFFFF !important',          
    fontSize: '12px !important',         
    '&:hover': {
      backgroundColor: '#0056b3', 
    },
    marginRight:"12px !important"
  },
  "searchTextContainer":{
    padding: "10px 0px",
  },
  "searchText":{
  marginLeft:"10px !important",
  paddingTop:"3px"
  },
  "divider": {
    height: '1px', 
    backgroundColor: '#E6E6E6', 
    opacity: 1, 
    margin: `${theme.spacing(2)}px 0`, 
  },
});

export default Style;

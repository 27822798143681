
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createApplicationModule, getAllApplicationModules} from "../services/applicationModuleService";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const createApplicationModuleAsync = createAsyncThunk("applicationModule/create", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Creating Application Module..."));
        const response = await createApplicationModule(data);
        dispatch(setLoading(null));
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
});


export const getAllApplicationModulesAsync = createAsyncThunk(
    "applicationModule/getAll",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoading("Fetching Application Modules..."));
            const response = await getAllApplicationModules();
            dispatch(setLoading(null));
            return response?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);
import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCollabAgenciesAsync,
} from "../actions/collabAction";

const initialState = {
  list: {
    data: [],
    headers: [
      { field: "id", label: "S.No" },
      { field: "customer_name", label: "Name" },
      { field: "customer_email", label: "Email" },
      {field:"collab_plan",label:"Collab Plan"},
      {field:"payment_method",label:"Payment"},
      { field: "created_on", label: "Date" },
      { field: "agency_id", label: "Agency" },
      { field: "is_converted", label: "Cloned" },
      { field: "action", label: "ACTION" }
    ],

    pagination: {
      count: 10,
      next: null,
      previous: null,
      page_size: 10,
      num_pages: 0,
      page_number: 1,
    },
    search: "",
    filter: {
      params: "",
      rules: [{ index: 0, field: "", operator: "", value: "" }],
    },
  },
  collabAgencyById: {},
  loading: null,
};


const collabSlice = createSlice({
  name: "collab",
  initialState,
  reducers: {
    setPageSize(state, action) {
      state.list.pagination.page_size = action.payload;
    },
    setPage(state, action) {
      state.list.pagination.page_number = action.payload;
    },
    setSearch(state, action) {
      state.list.pagination.page_number = 1;
      state.list.search = action.payload;
    },
    setCollabAgencyById(state, action) {
      state.collabAgencyById = {};
    },
    setOrdering(state, action) {
      state.list.ordering = action.payload;
    },
    addFilterRule(state, action) {
      state.list.filter.rules.push({
        index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
        field: "",
        operator: "",
        value: "",
      });
    },
    removeFilterRule(state, action) {
      state.list.filter.rules = state.list.filter.rules.filter(
        (f) => f.index !== action.payload
      );
    },
    updateFilterRule(state, action) {
      state.list.filter.rules = state.list.filter.rules.map((m) => {
        if (m.index === action.payload.index) {
          return action.payload;
        }
        return m;
      });
    },
    applyFilter(state, action) {
      state.list.pagination.page_number = 1;
      state.list.filter.params = action.payload;
    },
    clearFilter(state) {
      state.list.pagination.page_number = 1;
      state.list.filter.params = "";
      state.list.filter.rules = [{ index: 0, field: "", value: "" }];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCollabAgenciesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCollabAgenciesAsync.fulfilled, (state, { payload }) => {
      
        state.list.results = payload?.data?.results;
        state.list.pagination = {
         
          ...payload?.data?.pagination,
        };
        state.loading = false;
      })
      .addCase(getAllCollabAgenciesAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setPageSize, 
  setPage, 
  setSearch, 
  setCollabAgencyById,
  setOrdering,
  addFilterRule,
  removeFilterRule,
  updateFilterRule,
  applyFilter,
  clearFilter,
} = collabSlice.actions;

export default collabSlice.reducer;

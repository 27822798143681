import { createAsyncThunk } from "@reduxjs/toolkit";
import {  setAlert, setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import {authService} from "../services/authService"; 
import {setAuthUsers} from "../reducers/authUserGroup"
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password, callback }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading("Logging In..."));

      const response = await authService.action.login(email, password);
    
      dispatch(setLoading(null));

      if (callback && typeof callback === "function") {
        callback();  
      }

      return response.data;  
    } catch (err) {
      dispatch(setLoading(null));

      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();

      return rejectWithValue(message);  // Handle rejection
    }
  }
); 
export const permissions = createAsyncThunk(
  "auth/permissions",
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
    
      const response = await authService.action.permissions();

      // Call the callback with success data
      return response.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      // Call the callback with error data if needed
      if (callback) {
        callback(null, err);
      }
      return rejectWithValue(err);
    }
  }
);
export const getAllAuthUsers = createAsyncThunk(
  "authUserGroup/getAllAuthUsers",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await authService.action.getAllAuthUsers(data); // Call API
      dispatch(setAuthUsers(response?.data));
              
      return response.data;
    } catch (err) {
      parseErrorResponses(err?.response || err, dispatch); // Error handling
      return rejectWithValue(err);
    }
  }
);
export const createRefreshToken = (accessToken) => (dispatch) => {
  dispatch(accessToken(accessToken))
}

export const signup = createAsyncThunk(
  "authUserGroup/signup",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading("Creeating user...."))
      const response = await authService.action.register(
        {
          name:data?.name,
          email:data?.email,
          password:data?.password,
          group_id:data?.group_id
        }
      )
      dispatch(setLoading(null))
      dispatch(setAuthUsers(response?.data)); 
      dispatch(setAlert({message:response?.data?.message,type:"success"}))
      return response.data;
    } catch (err) {
      dispatch(setAlert({message:err?.response?.data?.error,type:"error"}))
      dispatch(setLoading(null))
      // parseErrorResponses(err?.response?.data?.message || err, dispatch);
      return rejectWithValue(err);
    }
  }
);




export const getLocalStorage = (key) => {
  try {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  } catch {
    return null;
  }
};

export const getAccessToken = () => {
  const user = getLocalStorage("user");
  return user
};

export const setToken = (data) => {
  localStorage.setItem("key", data);
};



export const highlightText = (text, search) => {
  if (typeof text !== 'string') text = String(text); 
  if (typeof search !== 'string') search = String(search); 

  if (!search.trim()) return <span style={{ color: '#64748BE5' }}>{text}</span>; 

  const parts = text.split(new RegExp(`(${search})`, 'gi'));
  return (
    <span style={{ color: '#64748BE5' }}>
      {parts.map((part, index) =>
        part.toLowerCase() === search.toLowerCase() ? (
          <span key={index} style={{ color: '#0F172A', fontWeight: 'bold' }}>{part}</span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};




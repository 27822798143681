import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteConfirmation from '../DeleteConfirmation/deleteConfirmation';


export const ActionCell = ({ rowData, onEdit, onDelete, onView }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {

    handleClose();
    if (action === 'edit') {
      onEdit(rowData);
    } else if (action === 'delete') {
      // onDelete(rowData);
      setOpenDialog(true);
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleConfirmDelete = () => {
    onDelete(rowData);
    setOpenDialog(false);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => handleAction('edit')}>Edit</MenuItem>
        <MenuItem onClick={() => handleAction('delete')} >Delete</MenuItem> */}
        <MenuItem onClick={() => handleAction('edit')}>View</MenuItem>
      </Menu>

      <DeleteConfirmation
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleConfirmDelete}
        content="Are you sure you want to delete the list?"
        contentStyle={{ fontSize: '14px', fontWeight: '500', color: '#0F172A' }}
      />
    </div>
  );
};



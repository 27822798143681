import React from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Chip,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import { CardEditIcon, EmailIcon, PhoneIcon2, TrashIcon } from "../../../assets/svg";

const UserCard = (props) => {
  const { className, data, handleEdit, handleDelete } = props;

  //topCard


  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
      spacing={3}
    >
      {
        data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Grid className="cardContainer">
              <Grid container justifyContent={"space-between"} sx={{ mb: 2 }}>
                <Avatar className="userImg" />
                <Grid>
                  <IconButton onClick={() => handleEdit()}><CardEditIcon /></IconButton>
                  <IconButton onClick={() => handleDelete()}><TrashIcon /></IconButton>
                </Grid>
              </Grid>
              <Typography variant="body1" color="text.primaryText">{item.name}</Typography>
              <Typography variant="body1" color="text.greyText1" className="fs-11">{item.designation}</Typography>
              <Grid container className="tags mt-2" sx={{ pb:2 }}>
                <Chip label={item.groupname} />
              </Grid>

              <Typography variant="body1" color="text.greyText2" className="mb-1 dflex aCenter"><span className="pr-1 dflex"><EmailIcon /></span>{item.email}</Typography>
              <Typography variant="body1" color="text.greyText2" className="dflex aCenter"><span className="pr-1 dflex"><PhoneIcon2 /></span>{item.phone}</Typography>
             
            </Grid>
          </Grid>
        ))
      }

    </Grid>
  );
};

// de  fault props
UserCard.defaultProps = {
  classes: {},
  data: []
};

// prop types
UserCard.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array
};


export default styled(UserCard)(Style);
